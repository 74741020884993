import AddItem16 from './16/AddItem';
import AddUser16 from './16/AddUser';
import Apple16 from './16/Apple';
import Atendimento16 from './16/Atendimento';
// import Archive16 from "./16/Archive";
import Available16 from './16/Available';
import Barcode16 from './16/Barcode';
import Bell16 from './16/Bell';
import BookOpened16 from './16/BookOpened';
import Calendar16 from './16/Calendar';
import CalendarDay16 from './16/CalendarDay';
import Camera16 from './16/Camera';
import Cart16 from './16/Cart';
import Cash16 from './16/Cash';
import Chair16 from './16/Chair';
import Chart16 from './16/Chart';
import Check16 from './16/Check';
import Checkbox16 from './16/Checkbox';
import CheckboxFilled16 from './16/CheckboxFilled';
import Checklist16 from './16/Checklist';
import Clip16 from './16/Clip';
import Close16 from './16/Close';
import Color16 from './16/Color';
import Comment16 from './16/Comment';
import Copy16 from './16/Copy';
import Cost16 from './16/Cost';
import Creditcard16 from './16/Creditcard';
import Down16 from './16/Down';
import Edit16 from './16/Edit';
import Eye16 from './16/Eye';
import EyeClosed16 from './16/EyeClosed';
import Facebook16 from './16/Facebook';
import Filter16 from './16/Filter';
import Fit16 from './16/Fit';
import Flashlight16 from './16/Flashlight';
import Google16 from './16/Google';
import Hammer16 from './16/Hammer';
import Heart16 from './16/Heart';
import Help16 from './16/Help';
import Helpball16 from './16/Helpball';
import History16 from './16/History';
import Home16 from './16/Home';
import Info16 from './16/Info';
import Item16 from './16/Item';
import Left16 from './16/Left';
import Live16 from './16/Live';
import LockClosed16 from './16/LockClosed';
import LockOpen16 from './16/LockOpen';
import Login16 from './16/Login';
import Logout16 from './16/Logout';
import Mail16 from './16/Mail';
import Menu16 from './16/Menu';
import Moon16 from './16/Moon';
import More16 from './16/More';
import Organize16 from './16/Organize';
import PaymentReceipt16 from './16/PaymentReceipt';
import People16 from './16/People';
import Phone16 from './16/Phone';
import Pin16 from './16/Pin';
import Plus16 from './16/Plus';
import Print16 from './16/Print';
import QrCode16 from './16/QrCode';
import Radio16 from './16/Radio';
import RadioFilled16 from './16/RadioFilled';
import Receipt16 from './16/Receipt';
import Right16 from './16/Right';
// import Sale16 from "./16/Sale";
import Save16 from './16/Save';
import Scale16 from './16/Scale';
import Search16 from './16/Search';
import Settings16 from './16/Settings';
// import ShareSocial16 from "./16/ShareSocial";
import Shuffle16 from './16/Shuffle';
import Sort16 from './16/Sort';
import Star16 from './16/Star';
import Storage16 from './16/Storage';
import StorageAdd16 from './16/StorageAdd';
import StorageAudit16 from './16/StorageAudit';
import StorageRemove16 from './16/StorageRemove';
import Store16 from './16/Store';
import Sun16 from './16/Sun';
import Table16 from './16/Table';
import Telephone16 from './16/Telephone';
import Time16 from './16/Time';
import Transaction16 from './16/Transaction';
import Trash16 from './16/Trash';
import Truck16 from './16/Truck';
import Unavailable16 from './16/Unavailable';
import Up16 from './16/Up';
import User16 from './16/User';
import Username16 from './16/Username';
import Whatsapp16 from './16/Whatsapp';
import AddItem20 from './20/AddItem';
import AddUser20 from './20/AddUser';
import Apple20 from './20/Apple';
import Atendimento20 from './20/Atendimento';
// import Archive20 from "./20/Archive";
import Available20 from './20/Available';
import Barcode20 from './20/Barcode';
import Bell20 from './20/Bell';
import BookOpened20 from './20/BookOpened';
import Calendar20 from './20/Calendar';
import CalendarDay20 from './20/CalendarDay';
import Camera20 from './20/Camera';
import Cart20 from './20/Cart';
import Cash20 from './20/Cash';
import Chair20 from './20/Chair';
import Chart20 from './20/Chart';
import Check20 from './20/Check';
import Checkbox20 from './20/Checkbox';
import CheckboxFilled20 from './20/CheckboxFilled';
import Checklist20 from './20/Checklist';
import Clip20 from './20/Clip';
import Close20 from './20/Close';
import Color20 from './20/Color';
import Comment20 from './20/Comment';
import Copy20 from './20/Copy';
import Cost20 from './20/Cost';
import Creditcard20 from './20/Creditcard';
import Down20 from './20/Down';
import Edit20 from './20/Edit';
import Eye20 from './20/Eye';
import EyeClosed20 from './20/EyeClosed';
import Facebook20 from './20/Facebook';
import Filter20 from './20/Filter';
import Fit20 from './20/Fit';
import Flashlight20 from './20/Flashlight';
import Google20 from './20/Google';
import Hammer20 from './20/Hammer';
import Heart20 from './20/Heart';
import Help20 from './20/Help';
import Helpball20 from './20/Helpball';
import History20 from './20/History';
import Home20 from './20/Home';
import Info20 from './20/Info';
import Item20 from './20/Item';
import Left20 from './20/Left';
import Live20 from './20/Live';
import LockClosed20 from './20/LockClosed';
import LockOpen20 from './20/LockOpen';
import Login20 from './20/Login';
import Logout20 from './20/Logout';
import Mail20 from './20/Mail';
import Menu20 from './20/Menu';
import Moon20 from './20/Moon';
import More20 from './20/More';
import Organize20 from './20/Organize';
import PaymentReceipt20 from './20/PaymentReceipt';
import People20 from './20/People';
import Phone20 from './20/Phone';
import Pin20 from './20/Pin';
import Plus20 from './20/Plus';
import Print20 from './20/Print';
import QrCode20 from './20/QrCode';
import Radio20 from './20/Radio';
import RadioFilled20 from './20/RadioFilled';
import Receipt20 from './20/Receipt';
import Right20 from './20/Right';
// import Sale20 from "./20/Sale";
import Save20 from './20/Save';
import Scale20 from './20/Scale';
import Search20 from './20/Search';
import Settings20 from './20/Settings';
// import ShareSocial20 from "./20/ShareSocial";
import Shuffle20 from './20/Shuffle';
import Sort20 from './20/Sort';
import Star20 from './20/Star';
import Storage20 from './20/Storage';
import StorageAdd20 from './20/StorageAdd';
import StorageAudit20 from './20/StorageAudit';
import StorageRemove20 from './20/StorageRemove';
import Store20 from './20/Store';
import Sun20 from './20/Sun';
import Table20 from './20/Table';
import Telephone20 from './20/Telephone';
import Time20 from './20/Time';
import Transaction20 from './20/Transaction';
import Trash20 from './20/Trash';
import Truck20 from './20/Truck';
import Unavailable20 from './20/Unavailable';
import Up20 from './20/Up';
import User20 from './20/User';
import Username20 from './20/Username';
import Whatsapp20 from './20/Whatsapp';
import AddItem24 from './24/AddItem';
import AddUser24 from './24/AddUser';
import Apple24 from './24/Apple';
import Atendimento24 from './24/Atendimento';
// import Archive24 from "./24/Archive";
import Available24 from './24/Available';
import Barcode24 from './24/Barcode';
import Bell24 from './24/Bell';
import BookOpened24 from './24/BookOpened';
import Calendar24 from './24/Calendar';
import CalendarDay24 from './24/CalendarDay';
import Camera24 from './24/Camera';
import Cart24 from './24/Cart';
import Cash24 from './24/Cash';
import Chair24 from './24/Chair';
import Chart24 from './24/Chart';
import Check24 from './24/Check';
import Checkbox24 from './24/Checkbox';
import CheckboxFilled24 from './24/CheckboxFilled';
import Checklist24 from './24/Checklist';
import Clip24 from './24/Clip';
import Close24 from './24/Close';
import Color24 from './24/Color';
import Comment24 from './24/Comment';
import Copy24 from './24/Copy';
import Cost24 from './24/Cost';
import Creditcard24 from './24/Creditcard';
import Down24 from './24/Down';
import Edit24 from './24/Edit';
import Eye24 from './24/Eye';
import EyeClosed24 from './24/EyeClosed';
import Facebook24 from './24/Facebook';
import Filter24 from './24/Filter';
import Fit24 from './24/Fit';
import Flashlight24 from './24/Flashlight';
import Google24 from './24/Google';
import Hammer24 from './24/Hammer';
import Heart24 from './24/Heart';
import Help24 from './24/Help';
import Helpball24 from './24/Helpball';
import History24 from './24/History';
import Home24 from './24/Home';
import Info24 from './24/Info';
import Instagram24 from './24/Instagram';
import Item24 from './24/Item';
import Left24 from './24/Left';
import Linkedin24 from './24/Linkedin';
import Live24 from './24/Live';
import LockClosed24 from './24/LockClosed';
import LockOpen24 from './24/LockOpen';
import Login24 from './24/Login';
import Logout24 from './24/Logout';
import Mail24 from './24/Mail';
import Menu24 from './24/Menu';
import Moon24 from './24/Moon';
import More24 from './24/More';
import Organize24 from './24/Organize';
import PaymentReceipt24 from './24/PaymentReceipt';
import People24 from './24/People';
import Phone24 from './24/Phone';
import Pin24 from './24/Pin';
import Plus24 from './24/Plus';
import Print24 from './24/Print';
import QrCode24 from './24/QrCode';
import Radio24 from './24/Radio';
import RadioFilled24 from './24/RadioFilled';
import Receipt24 from './24/Receipt';
import Right24 from './24/Right';
// import Sale24 from "./24/Sale";
import Save24 from './24/Save';
import Scale24 from './24/Scale';
import Search24 from './24/Search';
import Settings24 from './24/Settings';
// import ShareSocial24 from "./24/ShareSocial";
import Shuffle24 from './24/Shuffle';
import Sort24 from './24/Sort';
import Star24 from './24/Star';
import Storage24 from './24/Storage';
import StorageAdd24 from './24/StorageAdd';
import StorageAudit24 from './24/StorageAudit';
import StorageRemove24 from './24/StorageRemove';
import Store24 from './24/Store';
import Sun24 from './24/Sun';
import Table24 from './24/Table';
import Telephone24 from './24/Telephone';
import Tiktok24 from './24/Tiktok';
import Time24 from './24/Time';
import Transaction24 from './24/Transaction';
import Trash24 from './24/Trash';
import Truck24 from './24/Truck';
import Twitter24 from './24/Twitter';
import Unavailable24 from './24/Unavailable';
import Up24 from './24/Up';
import User24 from './24/User';
import Username24 from './24/Username';
import Whatsapp24 from './24/Whatsapp';
import Youtube24 from './24/Youtube';
import Yub24 from './24/Yub';
import AddItem32 from './32/AddItem';
import AddUser32 from './32/AddUser';
import Apple32 from './32/Apple';
import Atendimento32 from './32/Atendimento';
// import Archive32 from "./32/Archive";
import Available32 from './32/Available';
import Barcode32 from './32/Barcode';
import Bell32 from './32/Bell';
import BookOpened32 from './32/BookOpened';
import Calendar32 from './32/Calendar';
import CalendarDay32 from './32/CalendarDay';
import Camera32 from './32/Camera';
import Cart32 from './32/Cart';
import Cash32 from './32/Cash';
import Chair32 from './32/Chair';
import Chart32 from './32/Chart';
import Check32 from './32/Check';
import Checkbox32 from './32/Checkbox';
import CheckboxFilled32 from './32/CheckboxFilled';
import Checklist32 from './32/Checklist';
import Clip32 from './32/Clip';
import Close32 from './32/Close';
import Color32 from './32/Color';
import Comment32 from './32/Comment';
import Copy32 from './32/Copy';
import Cost32 from './32/Cost';
import Creditcard32 from './32/Creditcard';
import Down32 from './32/Down';
import Edit32 from './32/Edit';
import Eye32 from './32/Eye';
import EyeClosed32 from './32/EyeClosed';
import Facebook32 from './32/Facebook';
import Filter32 from './32/Filter';
import Fit32 from './32/Fit';
import Flashlight32 from './32/Flashlight';
import Google32 from './32/Google';
import Hammer32 from './32/Hammer';
import Heart32 from './32/Heart';
import Help32 from './32/Help';
import Helpball32 from './32/Helpball';
import History32 from './32/History';
import Home32 from './32/Home';
import Info32 from './32/Info';
import Item32 from './32/Item';
import Left32 from './32/Left';
import Live32 from './32/Live';
import LockClosed32 from './32/LockClosed';
import LockOpen32 from './32/LockOpen';
import Login32 from './32/Login';
import Logout32 from './32/Logout';
import Mail32 from './32/Mail';
import Menu32 from './32/Menu';
import Moon32 from './32/Moon';
import More32 from './32/More';
import Organize32 from './32/Organize';
import PaymentReceipt32 from './32/PaymentReceipt';
import People32 from './32/People';
import Phone32 from './32/Phone';
import Pin32 from './32/Pin';
import Plus32 from './32/Plus';
import Print32 from './32/Print';
import QrCode32 from './32/QrCode';
import Radio32 from './32/Radio';
import RadioFilled32 from './32/RadioFilled';
import Receipt32 from './32/Receipt';
import Right32 from './32/Right';
// import Sale32 from "./32/Sale";
import Save32 from './32/Save';
import Scale32 from './32/Scale';
import Search32 from './32/Search';
import Settings32 from './32/Settings';
// import ShareSocial32 from "./32/ShareSocial";
import Shuffle32 from './32/Shuffle';
import Sort32 from './32/Sort';
import Star32 from './32/Star';
import Storage32 from './32/Storage';
import StorageAdd32 from './32/StorageAdd';
import StorageAudit32 from './32/StorageAudit';
import StorageRemove32 from './32/StorageRemove';
import Store32 from './32/Store';
import Sun32 from './32/Sun';
import Table32 from './32/Table';
import Telephone32 from './32/Telephone';
import Time32 from './32/Time';
import Transaction32 from './32/Transaction';
import Trash32 from './32/Trash';
import Truck32 from './32/Truck';
import Unavailable32 from './32/Unavailable';
import Up32 from './32/Up';
import User32 from './32/User';
import Username32 from './32/Username';
import Whatsapp32 from './32/Whatsapp';
// import DownAnimated24 from './animated/24/down';
// import PlusAnimated24 from './animated/24/plus';
// import Plus from "./medium/Plus";
// import Yub from "./medium/Yub";

// export const iconsAnimated24 = {
//   down: DownAnimated24,
//   plus: PlusAnimated24,
// };

export const icons16 = {
  addItem: AddItem16,
  addUser: AddUser16,
  apple: Apple16,
  atendimento: Atendimento16,
  // archive: Archive16,
  available: Available16,
  barcode: Barcode16,
  bell: Bell16,
  bookOpened: BookOpened16,
  calendar: Calendar16,
  calendarDay: CalendarDay16,
  camera: Camera16,
  cart: Cart16,
  cash: Cash16,
  chair: Chair16,
  chart: Chart16,
  check: Check16,
  checkbox: Checkbox16,
  checkboxFilled: CheckboxFilled16,
  checklist: Checklist16,
  clip: Clip16,
  close: Close16,
  color: Color16,
  comment: Comment16,
  copy: Copy16,
  cost: Cost16,
  creditCard: Creditcard16,
  down: Down16,
  edit: Edit16,
  eye: Eye16,
  eyeClosed: EyeClosed16,
  facebook: Facebook16,
  filter: Filter16,
  fit: Fit16,
  flashlight: Flashlight16,
  google: Google16,
  hammer: Hammer16,
  heart: Heart16,
  help: Help16,
  helpball: Helpball16,
  history: History16,
  info: Info16,
  home: Home16,
  item: Item16,
  left: Left16,
  live: Live16,
  lockClosed: LockClosed16,
  lockOpen: LockOpen16,
  login: Login16,
  logout: Logout16,
  mail: Mail16,
  menu: Menu16,
  moon: Moon16,
  more: More16,
  organize: Organize16,
  paymentReceipt: PaymentReceipt16,
  people: People16,
  phone: Phone16,
  pin: Pin16,
  plus: Plus16,
  print: Print16,
  qrCode: QrCode16,
  radio: Radio16,
  radioFilled: RadioFilled16,
  receipt: Receipt16,
  right: Right16,
  // sale: Sale16,
  save: Save16,
  scale: Scale16,
  search: Search16,
  settings: Settings16,
  // shareSocial: ShareSocial16,
  shuffle: Shuffle16,
  sort: Sort16,
  star: Star16,
  storage: Storage16,
  storageAdd: StorageAdd16,
  storageAudit: StorageAudit16,
  storageRemove: StorageRemove16,
  store: Store16,
  sun: Sun16,
  table: Table16,
  telephone: Telephone16,
  time: Time16,
  transaction: Transaction16,
  trash: Trash16,
  truck: Truck16,
  unavailable: Unavailable16,
  up: Up16,
  user: User16,
  username: Username16,
  whatsapp: Whatsapp16,
};

export const icons20 = {
  addItem: AddItem20,
  addUser: AddUser20,
  apple: Apple20,
  atendimento: Atendimento20,
  // archive: Archive20,
  available: Available20,
  barcode: Barcode20,
  bell: Bell20,
  bookOpened: BookOpened20,
  calendar: Calendar20,
  calendarDay: CalendarDay20,
  camera: Camera20,
  cart: Cart20,
  cash: Cash20,
  chair: Chair20,
  chart: Chart20,
  check: Check20,
  checkbox: Checkbox20,
  checkboxFilled: CheckboxFilled20,
  checklist: Checklist20,
  clip: Clip20,
  close: Close20,
  color: Color20,
  comment: Comment20,
  copy: Copy20,
  cost: Cost20,
  creditCard: Creditcard20,
  down: Down20,
  edit: Edit20,
  eye: Eye20,
  eyeClosed: EyeClosed20,
  facebook: Facebook20,
  filter: Filter20,
  fit: Fit20,
  flashlight: Flashlight20,
  google: Google20,
  hammer: Hammer20,
  heart: Heart20,
  help: Help20,
  helpball: Helpball20,
  history: History20,
  home: Home20,
  info: Info20,
  item: Item20,
  left: Left20,
  live: Live20,
  lockClosed: LockClosed20,
  lockOpen: LockOpen20,
  login: Login20,
  logout: Logout20,
  mail: Mail20,
  menu: Menu20,
  moon: Moon20,
  more: More20,
  organize: Organize20,
  paymentReceipt: PaymentReceipt20,
  people: People20,
  phone: Phone20,
  pin: Pin20,
  plus: Plus20,
  print: Print20,
  qrCode: QrCode20,
  radio: Radio20,
  radioFilled: RadioFilled20,
  receipt: Receipt20,
  right: Right20,
  // sale: Sale20,
  save: Save20,
  scale: Scale20,
  search: Search20,
  settings: Settings20,
  // shareSocial: ShareSocial20,
  shuffle: Shuffle20,
  sort: Sort20,
  star: Star20,
  storage: Storage20,
  storageAdd: StorageAdd20,
  storageAudit: StorageAudit20,
  storageRemove: StorageRemove20,
  store: Store20,
  sun: Sun20,
  table: Table20,
  telephone: Telephone20,
  time: Time20,
  transaction: Transaction20,
  trash: Trash20,
  truck: Truck20,
  unavailable: Unavailable20,
  up: Up20,
  user: User20,
  username: Username20,
  whatsapp: Whatsapp20,
};

export const icons24 = {
  addItem: AddItem24,
  adduser: AddUser24,
  apple: Apple24,
  atendimento: Atendimento24,
  // archive: Archive24,
  available: Available24,
  barcode: Barcode24,
  bell: Bell24,
  bookOpened: BookOpened24,
  calendar: Calendar24,
  calendarDay: CalendarDay24,
  camera: Camera24,
  cart: Cart24,
  cash: Cash24,
  chair: Chair24,
  chart: Chart24,
  check: Check24,
  checkbox: Checkbox24,
  checkboxFilled: CheckboxFilled24,
  checklist: Checklist24,
  clip: Clip24,
  close: Close24,
  color: Color24,
  comment: Comment24,
  copy: Copy24,
  cost: Cost24,
  creditCard: Creditcard24,
  down: Down24,
  edit: Edit24,
  eye: Eye24,
  eyeClosed: EyeClosed24,
  facebook: Facebook24,
  filter: Filter24,
  fit: Fit24,
  flashlight: Flashlight24,
  google: Google24,
  hammer: Hammer24,
  heart: Heart24,
  help: Help24,
  helpball: Helpball24,
  history: History24,
  home: Home24,
  info: Info24,
  item: Item24,
  left: Left24,
  live: Live24,
  lockClosed: LockClosed24,
  lockOpen: LockOpen24,
  login: Login24,
  logout: Logout24,
  linkedin: Linkedin24,
  mail: Mail24,
  menu: Menu24,
  moon: Moon24,
  more: More24,
  organize: Organize24,
  paymentReceipt: PaymentReceipt24,
  people: People24,
  phone: Phone24,
  pin: Pin24,
  plus: Plus24,
  instagram: Instagram24,
  print: Print24,
  qrCode: QrCode24,
  radio: Radio24,
  radioFilled: RadioFilled24,
  receipt: Receipt24,
  right: Right24,
  // sale: Sale24,
  save: Save24,
  scale: Scale24,
  search: Search24,
  settings: Settings24,
  // shareSocial: ShareSocial24,
  shuffle: Shuffle24,
  sort: Sort24,
  star: Star24,
  storage: Storage24,
  storageAdd: StorageAdd24,
  storageAudit: StorageAudit24,
  storageRemove: StorageRemove24,
  store: Store24,
  sun: Sun24,
  table: Table24,
  telephone: Telephone24,
  tiktok: Tiktok24,
  time: Time24,
  transaction: Transaction24,
  trash: Trash24,
  truck: Truck24,
  twitter: Twitter24,
  unavailable: Unavailable24,
  up: Up24,
  user: User24,
  username: Username24,
  whatsapp: Whatsapp24,
  youtube: Youtube24,
  yub: Yub24,
};

export const icons32 = {
  addItem: AddItem32,
  addUser: AddUser32,
  apple: Apple32,
  atendimento: Atendimento32,
  // archive: Archive32,
  available: Available32,
  barcode: Barcode32,
  bell: Bell32,
  bookOpened: BookOpened32,
  calendar: Calendar32,
  calendarDay: CalendarDay32,
  camera: Camera32,
  cart: Cart32,
  cash: Cash32,
  chair: Chair32,
  chart: Chart32,
  check: Check32,
  checkbox: Checkbox32,
  checkboxFilled: CheckboxFilled32,
  checklist: Checklist32,
  clip: Clip32,
  close: Close32,
  color: Color32,
  comment: Comment32,
  copy: Copy32,
  cost: Cost32,
  creditCard: Creditcard32,
  down: Down32,
  edit: Edit32,
  eye: Eye32,
  eyeClosed: EyeClosed32,
  facebook: Facebook32,
  filter: Filter32,
  fit: Fit32,
  flashlight: Flashlight32,
  google: Google32,
  hammer: Hammer32,
  heart: Heart32,
  help: Help32,
  helpball: Helpball32,
  history: History32,
  home: Home32,
  info: Info32,
  item: Item32,
  left: Left32,
  live: Live32,
  lockClosed: LockClosed32,
  lockOpen: LockOpen32,
  login: Login32,
  logout: Logout32,
  mail: Mail32,
  menu: Menu32,
  moon: Moon32,
  more: More32,
  organize: Organize32,
  paymentReceipt: PaymentReceipt32,
  people: People32,
  phone: Phone32,
  pin: Pin32,
  plus: Plus32,
  print: Print32,
  qrCode: QrCode32,
  radio: Radio32,
  radioFilled: RadioFilled32,
  receipt: Receipt32,
  right: Right32,
  // sale: Sale32,
  save: Save32,
  scale: Scale32,
  search: Search32,
  settings: Settings32,
  // shareSocial: ShareSocial32,
  shuffle: Shuffle32,
  sort: Sort32,
  star: Star32,
  storage: Storage32,
  storageAdd: StorageAdd32,
  storageAudit: StorageAudit32,
  storageRemove: StorageRemove32,
  store: Store32,
  sun: Sun32,
  table: Table32,
  telephone: Telephone32,
  time: Time32,
  transaction: Transaction32,
  trash: Trash32,
  truck: Truck32,
  unavailable: Unavailable32,
  up: Up32,
  user: User32,
  username: Username32,
  whatsapp: Whatsapp32,
};
