import * as React from "react";
import Svg, { Rect } from "react-native-svg";

const Organize = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Rect x={12.666} y={8} width={2} height={2} rx={1} fill={color} />
    <Rect x={12.666} y={14.224} width={2} height={2} rx={1} fill={color} />
    <Rect x={12.666} y={20.443} width={2} height={2} rx={1} fill={color} />
    <Rect x={18.885} y={8} width={2} height={2} rx={1} fill={color} />
    <Rect x={18.885} y={14.224} width={2} height={2} rx={1} fill={color} />
    <Rect x={18.885} y={20.443} width={2} height={2} rx={1} fill={color} />
  </Svg>
);

export default Organize;
