import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Search = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m21 21-4.556-4.556m0 0A7.875 7.875 0 1 0 5.306 5.306a7.875 7.875 0 0 0 11.137 11.137Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default Search;
