import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Atendimento = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11.05 13.25C10.938 14.803 9.785 16 8.529 16c-1.257 0-2.41-1.196-2.521-2.75-.115-1.616 1.007-2.75 2.52-2.75 1.514 0 2.636 1.163 2.522 2.75ZM8.5 16.8c-2.49 0-4.883 1.236-5.483 3.644-.079.319.12.634.448.634h10.07c.327 0 .526-.316.448-.634-.6-2.446-2.994-3.644-5.483-3.644ZM11.527 4.55c.078-.64.607-1.131 1.25-1.162l6.838-.323A1.323 1.323 0 0 1 21 4.387v4.04c0 .73-.592 1.323-1.323 1.323h-6.893c-.183 0-.365.038-.532.112l-1.027.451a.265.265 0 0 1-.369-.274l.671-5.49Z"
      fill={color}
    />
  </Svg>
);

export default Atendimento;
