import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Google = () => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.527h6.842a6.974 6.974 0 0 1-2.117 6.629l-1.978-1.695a4.44 4.44 0 0 0 1.38-1.894c.056-.15.109-.303.15-.46H8v-2.58Z"
      fill="#4285F4"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m1.739 11.118 2.144-1.513A4.42 4.42 0 0 0 8 12.42c1.04 0 1.993-.36 2.747-.961l1.978 1.694A6.966 6.966 0 0 1 8 15a6.995 6.995 0 0 1-6.261-3.882Z"
      fill="#34A853"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.825 4.7A6.998 6.998 0 0 1 8 1c1.84 0 3.508.715 4.757 1.875l-1.81 1.81A4.425 4.425 0 0 0 8 3.579a4.422 4.422 0 0 0-4.08 2.717L1.825 4.7Z"
      fill="#EA4335"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 7.999c0-1.194.3-2.317.827-3.3l2.094 1.595A4.406 4.406 0 0 0 3.89 9.62l-2.137 1.527A6.961 6.961 0 0 1 1 7.999Z"
      fill="#FBBC05"
    />
  </Svg>
);

export default Google;
