import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Comment = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M6.667 16.667v-4.22c0-1.226.237-2.49.712-3.793A16.844 16.844 0 0 1 9.26 4.973c.78-1.17 1.634-2.161 2.565-2.973l3.82 2.208a22.431 22.431 0 0 0-1.768 3.653c-.494 1.302-.74 2.831-.74 4.587v4.219h-6.47Zm9.69 0v-4.22c0-1.226.237-2.49.712-3.793a16.848 16.848 0 0 1 1.88-3.681c.78-1.17 1.635-2.161 2.566-2.973l3.819 2.208a22.44 22.44 0 0 0-1.767 3.653c-.494 1.302-.741 2.831-.741 4.587v4.219h-6.47Z"
      fill={color}
    />
  </Svg>
);

export default Comment;
