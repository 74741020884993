import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

const Color = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Rect x={2.5} y={1.5} width={10} height={4} rx={1.5} stroke={color} />
    <Rect x={6.5} y={9.5} width={2} height={5} rx={0.5} stroke={color} />
    <Path
      d="M7.5 9.5c0-.538.058-1.088 1.5-1.5 3.5-1 5-.5 5-2.5V5c0-1.422-.5-1.5-1.5-1.5"
      stroke={color}
    />
  </Svg>
);

export default Color;
