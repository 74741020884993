import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Comment = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M5 12.5V9.336c0-.92.178-1.869.534-2.846.357-.976.827-1.897 1.411-2.76.584-.878 1.225-1.621 1.924-2.23l2.864 1.656a16.83 16.83 0 0 0-1.325 2.74c-.37.977-.556 2.123-.556 3.44V12.5H5Zm7.267 0V9.336c0-.92.178-1.869.535-2.846.356-.976.826-1.897 1.41-2.76.584-.878 1.226-1.621 1.924-2.23L19 3.156a16.83 16.83 0 0 0-1.325 2.74c-.37.977-.556 2.123-.556 3.44V12.5h-4.852Z"
      fill={color}
    />
  </Svg>
);

export default Comment;
