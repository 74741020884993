import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const Info = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M21 28a1 1 0 1 0 0-2v2ZM11 10a1 1 0 1 0 0 2v-2Zm0 16a1 1 0 1 0 0 2v-2Zm10 0c-.87 0-1.84-.02-2.61-.482C17.73 25.123 17 24.257 17 22h-2c0 2.744.935 4.378 2.36 5.233 1.314.788 2.843.767 3.64.767v-2Zm-4-4V14h-2V22h2Zm4 4H11v2h10v-2Zm-4-12c0-1.155-.128-2.284-.881-3.058C15.359 10.159 14.223 10 13 10v2c1.181 0 1.545.192 1.684.335.147.15.316.522.316 1.665h2Zm-4-4h-2v2h2v-2Z"
      fill={color}
    />
    <Circle cx={16} cy={6} r={1} stroke={color} strokeWidth={2} />
  </Svg>
);

export default Info;
