import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const User = ({ color }) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.652 4.665C10.534 6.17 9.319 7.33 7.995 7.33S5.455 6.17 5.34 4.665C5.218 3.099 6.4 2 7.995 2c1.595 0 2.777 1.127 2.657 2.665Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9c-2.263 0-4.439 1.361-4.984 4.012-.072.351.11.698.407.698h9.155c.297 0 .478-.347.407-.698C12.439 10.32 10.263 9 8 9Z"
      stroke={color}
      strokeMiterlimit={10}
    />
  </svg>
);

export default User;
