import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Atendimento = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M7.366 8.833c-.074 1.036-.842 1.834-1.68 1.834s-1.607-.798-1.68-1.834C3.928 7.756 4.675 7 5.685 7c1.009 0 1.757.776 1.68 1.833ZM5.667 11.2c-1.66 0-3.256.824-3.655 2.43-.053.212.08.422.298.422h6.713c.219 0 .351-.21.299-.423C8.922 12 7.326 11.2 5.667 11.2ZM7.685 3.033a.882.882 0 0 1 .833-.774l4.558-.215a.882.882 0 0 1 .924.88v2.694a.882.882 0 0 1-.882.882H8.523a.882.882 0 0 0-.355.075l-.684.3a.176.176 0 0 1-.246-.183l.447-3.66Z"
      fill={color}
    />
  </Svg>
);

export default Atendimento;
