import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { useStyleguide } from '../../../hooks/styleguide';

const CheckboxFilled = ({ color }) => {
  const { styleguide } = useStyleguide();

  return (
    <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M0 6a6 6 0 0 1 6-6h12a6 6 0 0 1 6 6v12a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z"
        fill={color}
      />
      <Path
        d="M5.472 11.934a.75.75 0 0 0-.944 1.166l.944-1.166ZM10.539 17l-.472.583a.75.75 0 0 0 1.045-.099L10.539 17Zm9.034-9.516a.75.75 0 0 0-1.146-.968l1.146.968ZM4.528 13.1l5.54 4.483.943-1.166-5.54-4.483-.943 1.166Zm6.584 4.384 8.46-10-1.145-.968-8.46 10 1.145.968Z"
        fill={styleguide.colors.inBrandPrimary}
      />
    </Svg>
  );
};

export default CheckboxFilled;
