import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Checkbox = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8 2h16v-4H8v4Zm22 6v16h4V8h-4Zm-6 22H8v4h16v-4ZM2 24V8h-4v16h4Zm6 6a6 6 0 0 1-6-6h-4c0 5.523 4.477 10 10 10v-4Zm22-6a6 6 0 0 1-6 6v4c5.523 0 10-4.477 10-10h-4ZM24 2a6 6 0 0 1 6 6h4c0-5.523-4.477-10-10-10v4ZM8-2C2.477-2-2 2.477-2 8h4a6 6 0 0 1 6-6v-4Z"
      fill={color}
    />
  </Svg>
);

export default Checkbox;
