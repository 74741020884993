import * as React from 'react';
import Svg, { G, Rect, Path, Defs } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const CreditCard = () => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
      <G filter="url(#a)">
        <Rect
          width={19.454}
          height={12.969}
          fill="#2082A0"
          rx={2.057}
          transform="rotate(-2.492 135.88 -66.04)"
        />
        <Rect
          width={3.487}
          height={3.487}
          fill="#fff"
          fillOpacity={0.45}
          rx={1.744}
          transform="rotate(-2.492 309.273 -404.384)"
        />
        <Rect
          width={3.487}
          height={3.487}
          fill="#fff"
          fillOpacity={0.45}
          rx={1.744}
          transform="rotate(-2.492 310.582 -344.26)"
        />
      </G>
      <Path fill="#0F495B" d="m3.575 7.852 18.95-.825.14 3.239-18.95.825-.14-3.24Z" />
      <Defs />
    </Svg>
  );
};
export default CreditCard;
