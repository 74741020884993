import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Yub = ({ color, color2 }) => (
  <Svg width={38} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.352 10.474a3.809 3.809 0 0 0-1.994-.546c-.911 0-1.608.245-2.089.734l.057-1.788V6.2c0-.314-.063-.534-.19-.66-.126-.138-.342-.207-.645-.207-.418 0-.969.107-1.653.32-.67.214-1.215.465-1.633.754v.602c.772 0 1.159.308 1.159.923v11.713c1.124.54 2.584.659 3.817.659.962 0 1.836-.213 2.62-.64.786-.427 1.406-1.055 1.862-1.883.468-.841.703-1.846.703-3.013 0-1.055-.184-1.946-.551-2.674-.367-.728-.855-1.268-1.463-1.62Zm-1.766 7.627c-.342.552-.791.828-1.348.828-.367 0-.671-.119-.912-.357v-6.836a1.25 1.25 0 0 1 .722-.207c.62 0 1.114.32 1.481.96.38.64.57 1.613.57 2.919 0 1.243-.171 2.14-.513 2.693Z"
      fill={color}
    />
    <Path
      d="M20.734 18.647c-.152-.201-.228-.452-.228-.753v-6.93c0-.427-.082-.697-.247-.81-.164-.126-.506-.188-1.025-.188-.887 0-2.161.093-2.887.678v.828c.71 0 1.064.195 1.064.584v5.593c-.178.226-.393.42-.646.584-.24.15-.488.226-.74.226-.305 0-.526-.095-.665-.283-.127-.2-.19-.496-.19-.885v-6.327c0-.302-.032-.521-.095-.66a.465.465 0 0 0-.342-.263c-.165-.05-.424-.075-.779-.075-.797 0-1.893.066-2.547.585v.921c.33 0 .667.232.667.584v5.179c0 .916.247 1.644.74 2.184.494.54 1.184.81 2.07.81 1.128 0 1.995-.427 2.603-1.28.1.4.304.715.607.94.304.227.671.34 1.102.34.43 0 .854-.063 1.272-.189.43-.125.747-.301.95-.527v-.527c-.291-.025-.52-.138-.684-.34ZM7.918 18.84c.611-1.885 1.163-3.803 1.842-5.665.412-1.13.544-1.726.583-1.933.043-.235.018-.53-.081-.745a.465.465 0 0 0-.342-.263c-.165-.05-.424-.076-.779-.076-.8 0-1.926.164-2.574.678l.284.829c.304 0 .531.044.683.132.165.087.247.323.181.584l-1.582 4.97-1.443-4.687c-.63-1.871-1.063-2.535-2.1-2.507-.885-.004-2.128.34-2.59.679l.284.829c.52 0 .87.293 1.035.782l2.26 5.588a2.036 2.036 0 0 0 1.894 1.277c-.253.753-.399 1.376-.791 1.766-.393.389-.67.55-1.075.55-.67 0-1.177-.202-1.52-.603C.858 22.647 2.223 24 3.665 24c.975 0 1.785-.402 2.431-1.205.646-.804 1.254-2.122 1.823-3.955Z"
      fill={color}
    />
    <Path
      d="M32.711 7.137a3.634 3.634 0 0 1-2.604-3.498c0-2.01 1.61-3.639 3.597-3.639 1.784 0 3.264 1.314 3.547 3.038.479 2.913-1.485 5.974-3.833 7.467-.233.148-.487-.114-.369-.365.894-1.891.6-2.617-.338-3.003Z"
      fill={color2}
    />
  </Svg>
);

export default Yub;
