import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Mail = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M2.144 3.387a.604.604 0 0 0 .066.85l5.4 4.645a.598.598 0 0 0 .78 0l5.4-4.646a.604.604 0 0 0 .066-.849M2.6 3.177c-.331 0-.6.27-.6.602v8.13c0 .332.269.601.6.601h10.8c.331 0 .6-.27.6-.602V3.78a.601.601 0 0 0-.6-.602H2.6Z"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Mail;
