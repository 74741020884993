import * as React from "react";
import Svg, { Circle } from "react-native-svg";

const Radio = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={12} cy={12} r={11.25} stroke={color} strokeWidth={1.5} />
  </Svg>
);

export default Radio;
