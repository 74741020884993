import * as WebBrowser from 'expo-web-browser';
import React, { useMemo, useState } from 'react';
import { View } from 'react-native';
import { MaskService } from 'react-native-masked-text';
import Animated, {
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';

import { createStyles } from './styles';
import ButtonFloat from '../../../components/ButtonFloat';
import Footing from '../../../components/Footing';
import Header from '../../../components/Header';
import ItemAccordion from '../../../components/ItemAccordion';
import ItemCover from '../../../components/ItemCover';
import ItemSolution from '../../../components/ItemSolution';
import Separator from '../../../components/Separator';
import Typography from '../../../components/Typography';
import { useAuthentication } from '../../../hooks/authentication';
import { useCollection } from '../../../hooks/collection';
import { useStyleguide } from '../../../hooks/styleguide';
import { useUrl } from '../../../hooks/url';

const Home = () => {
  const { styleguide } = useStyleguide();
  const { authentication } = useAuthentication();
  const { url } = useUrl();
  const { landings } = useCollection();

  const scrollY = useSharedValue(0);

  const [indexAccordionOpened, setIndexAccordionOpened] = useState(null);

  const containerHeaderAnimated = useAnimatedStyle(() => {
    const opacity = interpolate(scrollY.value, [9, 10], [0.4, 1], {
      extrapolateLeft: 'clamp',
      extrapolateRight: 'clamp',
    });

    return {
      position: 'absolute',
      width: '100%',
      opacity: { mobile: opacity, desktop: 1 }[styleguide.responsive],
    };
  }, [styleguide.responsive]);

  const logoHeader = useMemo(() => {
    return {
      light: authentication.store.logoLight,
      dark: authentication.store.logoDark,
    }[styleguide.theme];
  }, [styleguide.theme, authentication.store.logoLight, authentication.store.logoDark]);

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  const onScroll = useAnimatedScrollHandler({
    onScroll: ({ contentOffset: { y } }) => {
      scrollY.value = y;
    },
  });

  return (
    <>
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        // bounces={false}
        scrollEventThrottle={16}
        onScroll={onScroll}
        contentContainerStyle={styles.container}
        style={{ height: '100%', width: '100%' }}>
        {landings[0].covers.map((cover, index) => (
          <ItemCover key={index} cover={cover} />
        ))}
        {landings[0].aboutUs !== '' && (
          <View style={styles.aboutUs}>
            <Typography size="medium" name="title" text="Sobre nós" />
            <Typography
              contentContainerStyle={{ marginTop: 8 }}
              size="medium"
              name="body"
              text={landings[0].aboutUs}
              color={styleguide.colors.typographySecondary}
            />
          </View>
        )}
        {landings[0].solutions.length > 0 && (
          <View style={styles.solutions}>
            {landings[0].solutions.map((solution, index) => (
              <View
                key={index}
                style={{
                  width: '100%',
                  paddingHorizontal: { mobile: 16, desktop: 24 }[styleguide.responsive],
                }}>
                {index !== 0 && <Separator />}
                <ItemSolution solution={solution} variant={index % 2 ? 'default' : 'reverse'} />
              </View>
            ))}
          </View>
        )}
        {landings[0].accordions.length > 0 && (
          <View style={styles.accordions}>
            <Typography size="medium" name="title" text="Dúvidas frequentes" />
            {landings[0].accordions.map((accordion, index) => (
              <ItemAccordion
                contentContainerStyle={{ marginTop: 16 }}
                key={index}
                accordion={accordion}
                index={index}
                indexOpened={indexAccordionOpened}
                onIndexOpenedChange={(index) => {
                  setIndexAccordionOpened(index);
                }}
              />
            ))}
          </View>
        )}
        <Footing contentContainerStyle={{ marginTop: 16 }} />
      </Animated.ScrollView>
      <Animated.View style={containerHeaderAnimated}>
        <Header
          // buttonLeft={{
          //   icon: 'menu',
          // }}
          logo={logoHeader}
          buttonsText={
            url.subdomain === 'www'
              ? [
                  {
                    title: 'Entrar',
                    variant: 'secondary',
                  },
                  {
                    title: 'Cadastre-se',
                  },
                ]
              : []
          }
        />
      </Animated.View>
      <ButtonFloat
        buttons={[
          {
            icon: 'whatsapp',
            onPress: () => {
              WebBrowser.openBrowserAsync(
                `https://wa.me/${authentication.store.whatsapp.countryCode.slice(
                  1,
                )}${MaskService.toRawValue(
                  'cel-phone',
                  authentication.store.whatsapp.number,
                )}?text=${encodeURI(`Olá, quero tirar uma dúvida`)}`,
              );

              // Linking.openURL(
              //   `https://wa.me/${authentication.store.phone}?text=${encodeURI(
              //     `Olá, quero tirar uma dúvida`
              //   )}`
              // );
            },
          },
        ]}
      />
    </>
  );
};

export default Home;
