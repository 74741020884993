import * as React from "react";
import Svg, { Path } from "react-native-svg";

const AddItem = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M27.677 5.333h-7.092a.834.834 0 0 0-.588.243L5.818 19.75a1.665 1.665 0 0 0 0 2.348l6.75 6.75a1.665 1.665 0 0 0 2.349 0l14.174-14.17a.835.835 0 0 0 .242-.588V6.995a1.655 1.655 0 0 0-1.656-1.662v0Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M24 11a1 1 0 1 1 .707-.293l.707.707-.707-.707A1 1 0 0 1 24 11Z"
      stroke={color}
      strokeWidth={2}
    />
    <Path
      d="M7 3v4m0 4V7m0 0h4M7 7H3"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
);

export default AddItem;
