import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Hammer = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M17.339 16.238a1.544 1.544 0 0 0-.255-.342l-1.147-1.131a1.309 1.309 0 0 0-1.865-.019c-.397.392-1.125 1.105-2.437 2.402-2.51 2.483-7.17 6.645-9.278 8.42a1 1 0 0 0-.068 1.471l2.437 2.465a1.006 1.006 0 0 0 1.48-.056c1.827-2.148 6.018-6.812 8.5-9.264 1.274-1.254 1.988-1.974 2.393-2.372a1.36 1.36 0 0 0 .24-1.575v0Zm12.563-2.875-2.145-2.125a.34.34 0 0 0-.25-.1.35.35 0 0 0-.25.1.712.712 0 0 1-.597.204c-.28-.03-.578-.118-.77-.304-.438-.429.068-1.272-.317-1.812-.44-.591-.922-1.15-1.442-1.67-.442-.438-2.176-2.092-5.097-3.283a7.737 7.737 0 0 0-2.938-.578c-1.647 0-2.913.735-3.375 1.157-.367.332-.75.86-.75.86a5.7 5.7 0 0 1 .676-.2 4.97 4.97 0 0 1 1.455-.093c.972.081 2.144.537 2.773 1.031 1.013.813 1.449 1.9 1.517 3.302.05 1.043-.952 2.36-1.903 3.434a.491.491 0 0 0 .025.677l1.328 1.326a.5.5 0 0 0 .696.007c.87-.845 1.943-1.78 2.551-2.154.608-.374 1.099-.48 1.335-.506.463-.044.928.056 1.33.289.072.04.137.09.193.149.404.41.38 1.08-.03 1.483l-.126.119a.343.343 0 0 0 0 .49l2.145 2.125a.344.344 0 0 0 .25.098.353.353 0 0 0 .25-.098l3.466-3.428a.364.364 0 0 0 0-.5v0Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Hammer;
