import React, { useMemo } from 'react';
import Animated from 'react-native-reanimated';

import { icons16, icons20, icons24, icons32 } from '../../assets/icons';
import { useStyleguide } from '../../hooks/styleguide';

const Icon = ({ name, color, color2, size, contentContainerStyle = {} }) => {
  const { styleguide } = useStyleguide();

  const IconComponent = useMemo(() => {
    const variants = {
      16: icons16,
      20: icons20,
      24: icons24,
      32: icons32,
    };

    return variants[size][name];
  }, [name, size, icons16, icons20, icons24, icons32]);

  const iconColor = useMemo(() => {
    return color || styleguide.colors.typographyPrimary;
  }, [color, styleguide.colors.typographyPrimary]);

  return (
    <Animated.View style={{ ...contentContainerStyle }}>
      <IconComponent color={iconColor} color2={color2} />
    </Animated.View>
  );
};

export default Icon;
