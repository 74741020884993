import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
const Check = ({ color }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <Path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5 12.517 10.54 17 19 7"
    />
  </Svg>
);
export default Check;
