import * as React from "react";
import Svg, { Path } from "react-native-svg";

const AddUser = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11.534 7.427a4.427 4.427 0 1 1 8.854 0 4.427 4.427 0 0 1-8.854 0ZM6 23v-.094c0-4.27 3.353-7.732 7.488-7.732h4.945c4.136 0 7.489 3.462 7.489 7.732V23"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16 21v8m4-4h-8"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default AddUser;
