import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Help = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={12} cy={12} r={9.75} stroke={color} strokeWidth={1.5} />
    <Circle cx={12} cy={12} r={3.25} stroke={color} strokeWidth={1.5} />
    <Path
      d="M15 9.6v4.8l6 .6V9l-6 .6ZM9 3l.6 6h4.8l.6-6H9ZM9.6 15 9 21h6l-.6-6H9.6ZM3 9v6l6-.6V9.6L3 9Z"
      fill={color}
    />
  </Svg>
);

export default Help;
