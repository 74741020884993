import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Scale = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14.375 1.875h-8.75a3.75 3.75 0 0 0-3.75 3.75v8.75a3.75 3.75 0 0 0 3.75 3.75h8.75a3.75 3.75 0 0 0 3.75-3.75v-8.75a3.75 3.75 0 0 0-3.75-3.75Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinejoin="round"
    />
    <Path
      d="M15.193 5.92A7.625 7.625 0 0 0 10 3.914 7.625 7.625 0 0 0 4.807 5.92a1.354 1.354 0 0 0-.12 1.878L5.99 9.33a1.018 1.018 0 0 0 1.313.204c.622-.384 1.598-.845 2.7-.845 1.1 0 2.076.461 2.698.845a1.018 1.018 0 0 0 1.313-.204l1.3-1.531a1.354 1.354 0 0 0-.12-1.878Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Scale;
