import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Live = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M9.396 8.334c0 .74-.615 1.357-1.394 1.357-.78 0-1.395-.617-1.395-1.357s.615-1.357 1.395-1.357 1.394.617 1.394 1.357Z"
      stroke={color}
    />
    <Path
      d="M10.526 10.81c1.263-1.548 1.263-3.406 0-4.953M5.474 10.81c-1.263-1.548-1.263-3.406 0-4.953M12.107 12.667c1.43-1.445 3.432-5.2 0-8.667M3.896 12.667c-1.43-1.445-3.432-5.2 0-8.667"
      stroke={color}
      strokeLinecap="round"
    />
  </Svg>
);

export default Live;
