import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Menu = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      d="M1.875 4.082h5.417M1.875 9.915h15.417M1.875 15.748h11.25"
    />
  </Svg>
);

export default Menu;
