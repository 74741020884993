import * as React from "react";
import Svg, { Path } from "react-native-svg";

const History = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M4.166 7.407A6.669 6.669 0 0 1 16.976 10a6.667 6.667 0 0 1-12.81 2.592m0-5.185H6.25m-2.084 0-.833-1.99"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
    />
    <Path
      d="M10.625 6.667a.625.625 0 1 0-1.25 0h1.25ZM10 10.417h-.625v.45l.427.143.198-.593Zm2.302 1.426a.625.625 0 1 0 .396-1.186l-.396 1.186ZM9.375 6.667v3.75h1.25v-3.75h-1.25Zm.427 4.343 2.5.833.396-1.186-2.5-.833-.396 1.186Z"
      fill={color}
    />
  </Svg>
);

export default History;
