import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Helpball = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={10} cy={15} r={0.625} stroke={color} strokeWidth={1.25} />
    <Path
      d="M10.625 11.875c-2.5 0-.886-1.614 1.25-3.75 1.049-1.049 1.25-3.75-1.875-3.75-1.25 0-2.5.938-2.5 1.875"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
    />
    <Circle cx={10} cy={10} r={8.125} stroke={color} strokeWidth={1.25} />
  </Svg>
);

export default Helpball;
