import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const AddUser = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M5.767 3.714a2.214 2.214 0 1 1 4.427 0 2.214 2.214 0 0 1-4.427 0ZM3 11.5v-.047c0-2.135 1.676-3.866 3.744-3.866h2.473c2.067 0 3.744 1.731 3.744 3.866v.047"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="M8 10.5v4m2-2H6" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

export default AddUser;
