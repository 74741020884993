import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Chart = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8.003 2.52a6.002 6.002 0 0 1 6.498 5.98 6.002 6.002 0 0 1-11.714 1.833"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <Path
      d="M8 1.5a6.5 6.5 0 0 0-5.935 9.156L8 8V1.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Chart;
