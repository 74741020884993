import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Fit = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3.676 11.846A9.197 9.197 0 0 1 3 8.288C3.026 5.819 4.984 3.75 7.457 3.75c3.044 0 4.544 3 4.544 3s1.5-3 4.543-3c2.473 0 4.431 2.07 4.456 4.538.052 5.125-4.066 8.77-8.578 11.832a.75.75 0 0 1-.843 0C9.517 18.72 7.537 17.2 6 15.472"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2.25 11.25a.75.75 0 0 0 0 1.5v-1.5ZM6 12v.75a.75.75 0 0 0 .67-.415L6 12Zm2.25-4.5.718-.216a.75.75 0 0 0-1.389-.12l.671.336ZM10.5 15l-.718.216a.75.75 0 0 0 1.389.12L10.5 15Zm2.25-4.5.67-.335a.75.75 0 0 0-1.34 0l.67.335Zm1.5 3-.67.335a.75.75 0 0 0 .67.415v-.75Zm2.25.75a.75.75 0 0 0 0-1.5v1.5Zm-14.25-1.5H6v-1.5H2.25v1.5Zm4.42-.415 2.25-4.5-1.34-.67-2.25 4.5 1.34.67Zm.862-4.62 2.25 7.5 1.436-.43-2.25-7.5-1.436.43Zm3.639 7.62 2.25-4.5-1.342-.67-2.25 4.5 1.342.67Zm.908-4.5 1.5 3 1.342-.67-1.5-3-1.342.67Zm2.171 3.415h2.25v-1.5h-2.25v1.5Z"
      fill={color}
    />
  </Svg>
);

export default Fit;
