import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Calendar = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15 3.125h1.25c1.035 0 1.875.84 1.875 1.875v1.25M15 3.125v-1.25m0 1.25H5m0 0H3.75c-1.036 0-1.875.84-1.875 1.875v1.25M5 3.125v-1.25M1.875 6.25v10c0 1.035.84 1.875 1.875 1.875h12.5c1.035 0 1.875-.84 1.875-1.875v-10m-16.25 0h16.25"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.875 10a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM15 10a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM11.875 13.125a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM15 13.125a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM5.625 13.125a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM8.75 13.125a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM5.625 16.25a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM8.75 16.25a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM11.875 16.25a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25Z"
      fill={color}
    />
  </Svg>
);

export default Calendar;
