import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Settings = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16.394 12.02a4 4 0 1 0-.787 7.959 4 4 0 0 0 .787-7.96v0ZM26.025 16a9.642 9.642 0 0 1-.095 1.3l2.825 2.216a.676.676 0 0 1 .153.859L26.235 25a.676.676 0 0 1-.821.287l-2.806-1.13a1.007 1.007 0 0 0-.948.11c-.429.294-.879.556-1.347.783a.995.995 0 0 0-.55.759l-.422 2.993a.693.693 0 0 1-.667.573h-5.346a.694.694 0 0 1-.668-.554l-.42-2.99a1.003 1.003 0 0 0-.563-.763 9.713 9.713 0 0 1-1.341-.786 1 1 0 0 0-.944-.106l-2.806 1.129a.676.676 0 0 1-.821-.286l-2.673-4.625a.675.675 0 0 1 .153-.86l2.388-1.875a1.004 1.004 0 0 0 .375-.88 8.526 8.526 0 0 1 0-1.553 1 1 0 0 0-.38-.871L3.242 12.48a.676.676 0 0 1-.149-.855L5.767 7a.676.676 0 0 1 .82-.287l2.807 1.13a1.007 1.007 0 0 0 .948-.11c.428-.294.878-.556 1.346-.783a.997.997 0 0 0 .552-.759l.42-2.993a.693.693 0 0 1 .668-.573h5.346a.694.694 0 0 1 .668.554l.42 2.99a1.004 1.004 0 0 0 .563.763c.467.225.916.488 1.34.785a1 1 0 0 0 .945.107l2.806-1.129a.676.676 0 0 1 .821.286l2.673 4.625a.675.675 0 0 1-.153.86L26.37 14.34a1.004 1.004 0 0 0-.378.88c.02.258.034.518.034.779Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Settings;
