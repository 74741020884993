import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
const Twitter = ({ color }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <Path
      fill={color}
      d="m13.829 10.507 7.103-8.257h-1.683L13.08 9.42 8.154 2.25H2.473l7.45 10.841-7.45 8.659h1.683l6.513-7.571 5.203 7.571h5.682l-7.726-11.243Zm-2.306 2.68-.755-1.08-6.005-8.59h2.585l4.847 6.933.754 1.08 6.3 9.01h-2.585l-5.141-7.353Z"
    />
  </Svg>
);
export default Twitter;
