import * as React from "react";
import Svg, { Path } from "react-native-svg";

const BookOpened = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m7.841 4.333 6.53-1.961a.1.1 0 0 1 .129.096v9.461a.1.1 0 0 1-.067.095l-6.592 2.31m0-10L1.635 2.05a.1.1 0 0 0-.135.093V11.6a.1.1 0 0 0 .061.092l6.28 2.641m0-10v10"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m9.5 11.196 3.167-1.108M9.5 9l3.167-1.108M9.5 6.804l3.167-1.108"
      stroke={color}
      strokeLinecap="round"
    />
  </Svg>
);

export default BookOpened;
