import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Pin = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15.833 8.148c0 3.58-6.25 10.185-6.25 10.185s-6.25-6.605-6.25-10.185 2.798-6.481 6.25-6.481 6.25 2.902 6.25 6.481Z"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.042 7.917a1.458 1.458 0 1 1-2.917 0 1.458 1.458 0 0 1 2.917 0Z"
      stroke={color}
      strokeWidth={1.25}
    />
  </Svg>
);

export default Pin;
