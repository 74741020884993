import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Google = () => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9.79h10.263a10.462 10.462 0 0 1-3.176 9.944l-2.966-2.542a6.658 6.658 0 0 0 2.07-2.841c.084-.226.163-.455.224-.691H12V9.79Z"
      fill="#4285F4"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m2.608 16.677 3.217-2.27a6.63 6.63 0 0 0 6.174 4.224c1.56 0 2.99-.54 4.121-1.442l2.966 2.542A10.45 10.45 0 0 1 12 22.5c-4.116 0-7.67-2.375-9.391-5.823Z"
      fill="#34A853"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.738 7.051a10.497 10.497 0 0 1 9.26-5.551 10.45 10.45 0 0 1 7.136 2.813L16.42 7.027A6.637 6.637 0 0 0 12 5.369a6.633 6.633 0 0 0-6.12 4.075L2.739 7.05Z"
      fill="#EA4335"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 11.998c0-1.79.45-3.474 1.24-4.95l3.141 2.393a6.608 6.608 0 0 0-.046 4.988L2.63 16.72a10.442 10.442 0 0 1-1.13-4.72Z"
      fill="#FBBC05"
    />
  </Svg>
);

export default Google;
