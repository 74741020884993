import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const Info = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.125 17.5a.625.625 0 1 0 0-1.25v1.25ZM6.875 6.25a.625.625 0 1 0 0 1.25V6.25Zm0 10a.625.625 0 0 0 0 1.25v-1.25Zm6.25 0c-.544 0-1.15-.013-1.632-.302-.411-.246-.868-.788-.868-2.198h-1.25c0 1.715.585 2.736 1.475 3.27.821.493 1.777.48 2.275.48v-1.25Zm-2.5-2.5V8.75h-1.25V13.75h1.25Zm2.5 2.5h-6.25v1.25h6.25v-1.25Zm-2.5-7.5c0-.722-.08-1.427-.55-1.911-.476-.49-1.186-.589-1.95-.589V7.5c.738 0 .966.12 1.053.21.091.094.197.326.197 1.04h1.25Zm-2.5-2.5h-1.25V7.5h1.25V6.25Z"
      fill={color}
    />
    <Circle cx={10} cy={3.75} r={0.625} stroke={color} strokeWidth={1.25} />
  </Svg>
);

export default Info;
