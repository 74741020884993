import * as React from "react";
import Svg, { Path } from "react-native-svg";

const StorageAudit = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m6.328 12.828.354-.935a1 1 0 0 0-1.057.225l.703.71Zm0 14.284h-1a1 1 0 0 0 .659.94l.341-.94Zm9.607 3.494-.342.94a1 1 0 0 0 .671.004l-.33-.944Zm10-3.494.33.944a1 1 0 0 0 .67-.944h-1Zm0-14.284.83-.56a1 1 0 0 0-1.17-.38l.34.94Zm-6.602 8.662-.829.56a1 1 0 0 0 1.17.38l-.341-.94Zm10-3.628.342.94a1 1 0 0 0 .488-1.5l-.83.56Zm-27.37-2.116a1 1 0 0 0 1.408 1.42l-1.408-1.42Zm24.972 3.349a1 1 0 0 0-2 0h2ZM5.987 28.052l9.606 3.494.683-1.88-9.606-3.494-.683 1.88Zm10.277 3.498 10.001-3.494-.66-1.888-10 3.494.66 1.888Zm9.33-19.662-10 3.628.682 1.88 10-3.628-.682-1.88Zm-9.306 3.633-9.606-3.628-.707 1.87 9.606 3.629.707-1.871Zm-1.354.935v14.15h2v-14.15h-2Zm.172.56 3.398 5.034 1.658-1.12-3.399-5.033-1.657 1.119Zm4.568 5.414 10-3.628-.681-1.88-10.001 3.628.682 1.88Zm10.489-5.128-3.399-5.033-1.657 1.119 3.398 5.033 1.658-1.119ZM5.625 12.118l-3.662 3.628 1.408 1.42 3.661-3.627-1.407-1.421Zm-.297.71V15.3h2v-2.47h-2Zm0 2.471v11.813h2V15.3h-2Zm21.607 11.813v-8.017h-2v8.017h2Z"
      fill={color}
    />
    <Path
      d="M15.988 1.417c-2.784 0-5.64 1.61-7.887 4.833a.571.571 0 0 0-.01.634c1.728 2.704 4.545 4.818 7.897 4.818 3.315 0 6.19-2.12 7.92-4.83a.576.576 0 0 0 0-.624c-1.734-2.679-4.63-4.831-7.92-4.831Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      fill={color}
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default StorageAudit;
