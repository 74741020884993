import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Checklist = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      d="M14.334 9.666h12M14.334 16.334h12M14.334 23h12"
    />
    <Path
      d="m5.333 9.524 1.715 1.143L9.334 8M5.333 16.19l1.715 1.143 2.286-2.666M5.333 22.857 7.048 24l2.286-2.666"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Checklist;
