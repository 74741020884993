import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Heart = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M6.214 3.333C8.75 3.333 10 5.758 10 5.758s1.25-2.425 3.786-2.425A3.718 3.718 0 0 1 17.5 7.002c.043 4.14-3.388 7.086-7.149 9.56a.638.638 0 0 1-.703 0c-3.76-2.474-7.19-5.42-7.148-9.56a3.718 3.718 0 0 1 3.714-3.668v0Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Heart;
