import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Scale = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M17.25 2.25H6.75a4.5 4.5 0 0 0-4.5 4.5v10.5a4.5 4.5 0 0 0 4.5 4.5h10.5a4.5 4.5 0 0 0 4.5-4.5V6.75a4.5 4.5 0 0 0-4.5-4.5Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <Path
      d="M18.232 7.105A9.15 9.15 0 0 0 12 4.695a9.15 9.15 0 0 0-6.231 2.41 1.625 1.625 0 0 0-.144 2.253l1.562 1.838a1.222 1.222 0 0 0 1.575.245c.747-.461 1.918-1.015 3.24-1.015 1.32 0 2.492.554 3.238 1.015a1.222 1.222 0 0 0 1.575-.245l1.56-1.838a1.625 1.625 0 0 0-.143-2.253Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Scale;
