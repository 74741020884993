import * as React from "react";
import Svg, { Rect } from "react-native-svg";

const Organize = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Rect x={7.916} y={5} width={1.25} height={1.25} rx={0.625} fill={color} />
    <Rect
      x={7.916}
      y={8.89}
      width={1.25}
      height={1.25}
      rx={0.625}
      fill={color}
    />
    <Rect
      x={7.916}
      y={12.777}
      width={1.25}
      height={1.25}
      rx={0.625}
      fill={color}
    />
    <Rect x={11.803} y={5} width={1.25} height={1.25} rx={0.625} fill={color} />
    <Rect
      x={11.803}
      y={8.89}
      width={1.25}
      height={1.25}
      rx={0.625}
      fill={color}
    />
    <Rect
      x={11.803}
      y={12.777}
      width={1.25}
      height={1.25}
      rx={0.625}
      fill={color}
    />
  </Svg>
);

export default Organize;
