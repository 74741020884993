import * as React from "react";
import Svg, { Path } from "react-native-svg";

const StorageAudit = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m4.746 9.621.265-.702a.75.75 0 0 0-.793.17l.528.532Zm0 10.713h-.75a.75.75 0 0 0 .494.705l.256-.705Zm7.205 2.62-.257.705c.163.06.34.06.504.004l-.247-.709Zm7.5-2.62.248.708a.75.75 0 0 0 .502-.708h-.75Zm0-10.713.622-.42a.75.75 0 0 0-.878-.285l.256.705ZM14.5 16.117l-.621.42a.75.75 0 0 0 .877.286l-.256-.706ZM22 13.397l.256.704a.75.75 0 0 0 .366-1.124l-.622.42ZM1.472 11.808a.75.75 0 0 0 1.056 1.066l-1.056-1.066Zm18.73 2.512a.75.75 0 0 0-1.5 0h1.5ZM4.49 21.039l7.204 2.62.513-1.41-7.205-2.62-.512 1.41Zm7.708 2.623 7.5-2.62-.494-1.416-7.5 2.62.494 1.417Zm6.997-14.746-7.5 2.721.511 1.41 7.501-2.72-.512-1.41Zm-6.98 2.725L5.012 8.919l-.53 1.404 7.205 2.72.53-1.402Zm-1.014.701v10.612h1.5V12.342h-1.5Zm.128.42 2.549 3.775 1.243-.84-2.549-3.774-1.243.839Zm3.426 4.06 7.5-2.72-.51-1.41-7.501 2.72.511 1.41Zm7.867-3.845L20.072 9.2l-1.242.84 2.548 3.775 1.244-.84ZM4.218 9.088 1.472 11.81l1.056 1.066 2.746-2.721-1.056-1.066Zm-.222.533v1.853h1.5V9.621h-1.5Zm0 1.853v8.86h1.5v-8.86h-1.5Zm16.205 8.86v-6.013h-1.5v6.013h1.5Z"
      fill={color}
    />
    <Path
      d="M11.99 1.063c-2.087 0-4.228 1.208-5.914 3.624a.429.429 0 0 0-.007.476c1.295 2.028 3.408 3.613 5.922 3.613 2.486 0 4.643-1.59 5.94-3.622a.432.432 0 0 0 0-.468c-1.3-2.009-3.472-3.623-5.94-3.623Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      fill={color}
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default StorageAudit;
