import * as React from "react";
import Svg, { Path } from "react-native-svg";

const People = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15.704 6.563c-.114 1.588-1.292 2.812-2.578 2.812-1.285 0-2.465-1.223-2.578-2.813-.117-1.652 1.03-2.812 2.578-2.812 1.549 0 2.696 1.19 2.579 2.813Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M13.125 11.875c-2.546 0-4.994 1.264-5.607 3.727-.081.326.123.648.458.648h10.299c.334 0 .537-.322.457-.648-.613-2.502-3.061-3.727-5.607-3.727Z"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
    />
    <Path
      d="M7.812 7.263c-.091 1.269-1.044 2.268-2.07 2.268-1.027 0-1.98-.999-2.07-2.268C3.578 5.943 4.505 5 5.742 5c1.237 0 2.163.968 2.07 2.263Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.047 11.951c-.705-.323-1.481-.447-2.304-.447-2.032 0-3.989 1.01-4.479 2.976-.064.26.099.518.366.518h4.386"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default People;
