import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Hammer = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10.837 9.648a.964.964 0 0 0-.16-.213l-.716-.707a.818.818 0 0 0-1.166-.012c-.248.245-.703.691-1.523 1.502-1.569 1.552-4.482 4.153-5.8 5.262a.624.624 0 0 0-.042.92l1.524 1.54a.63.63 0 0 0 .924-.035c1.143-1.343 3.762-4.258 5.313-5.79.796-.784 1.243-1.234 1.496-1.482a.85.85 0 0 0 .15-.985v0Zm7.852-1.796-1.34-1.329a.212.212 0 0 0-.157-.062.218.218 0 0 0-.156.062.445.445 0 0 1-.373.128c-.175-.019-.362-.073-.482-.19-.273-.268.043-.795-.198-1.132a9.481 9.481 0 0 0-.902-1.044c-.275-.274-1.36-1.308-3.185-2.052a4.836 4.836 0 0 0-1.836-.361c-1.03 0-1.82.46-2.11.723-.229.208-.468.538-.468.538.138-.05.279-.092.422-.125.299-.064.605-.084.91-.058.607.05 1.34.335 1.733.644.633.508.905 1.187.948 2.063.031.652-.595 1.475-1.19 2.147a.307.307 0 0 0 .016.423l.83.829a.312.312 0 0 0 .435.004c.544-.528 1.215-1.112 1.595-1.346.38-.234.687-.3.834-.316.29-.028.58.035.832.18a.535.535 0 0 1 .12.093.659.659 0 0 1-.02.927l-.078.074a.215.215 0 0 0 0 .307l1.34 1.328a.214.214 0 0 0 .157.061.221.221 0 0 0 .156-.061l2.167-2.143a.228.228 0 0 0 0-.312v0Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Hammer;
