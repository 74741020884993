import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Telephone = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M20.437 17.105c-.687-.693-2.351-1.703-3.159-2.11-1.05-.53-1.137-.573-1.964.041-.551.41-.918.776-1.563.638-.645-.137-2.047-.913-3.274-2.137-1.228-1.223-2.048-2.666-2.186-3.309-.138-.643.234-1.005.64-1.557.571-.78.528-.91.04-1.96-.382-.818-1.422-2.467-2.117-3.15-.743-.735-.743-.605-1.222-.406-.39.164-.764.364-1.117.596-.693.46-1.077.842-1.346 1.416-.268.574-.389 1.92.999 4.441 1.387 2.52 2.36 3.81 4.376 5.82 2.015 2.008 3.564 3.089 5.83 4.36 2.801 1.569 3.876 1.263 4.452.994.576-.268.96-.649 1.42-1.34.233-.354.433-.728.597-1.117.2-.477.33-.477-.406-1.22Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Telephone;
