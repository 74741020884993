import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Username = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16 28C9.373 28 4 22.627 4 16S9.373 4 16 4s12 5.373 12 12c0 4.5-3 7.5-6 7.5-6 0-4.806-9.637-1.5-12"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17.979 16s-1.056 4.5-4.056 4.5c-4.86 0-2.223-9 1.55-9 2.812 0 2.506 4.5 2.506 4.5Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Username;
