import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      alignSelf: 'flex-start',
      justifyContent: 'center',
    },

    hover: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: styleguide.colors.hover,
    },
  });
