import * as WebBrowser from 'expo-web-browser';
import moment from 'moment';
import React, { useMemo } from 'react';
import { View } from 'react-native';

import { createStyles } from './styles';
import { useAuthentication } from '../../hooks/authentication';
import { useStyleguide } from '../../hooks/styleguide';
import ButtonIcon from '../ButtonIcon';
import ButtonText from '../ButtonText';
import Separator from '../Separator';
import Typography from '../Typography';
const Footing = ({ contentContainerStyle = {} }) => {
  const { styleguide } = useStyleguide();
  const { authentication } = useAuthentication();

  const separatorVariant = useMemo(() => {
    const variants = {
      mobile: 'horizontal',
      desktop: 'vertical',
    };

    return variants[styleguide.responsive];
  }, [styleguide.responsive]);

  const marginTop = useMemo(() => {
    const variants = {
      mobile: 12,
      desktop: 0,
    };

    return variants[styleguide.responsive];
  }, [styleguide.responsive]);

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  const hasSocialMedia = useMemo(() => {
    if (
      (authentication.store.facebook ||
        authentication.store.instagram ||
        authentication.store.linkedin ||
        authentication.store.twitter ||
        authentication.store.youtube ||
        authentication.store.tiktok) !== ''
    ) {
      return true;
    } else {
      return false;
    }
  }, [authentication.store]);

  return (
    <View style={[styles.container, contentContainerStyle]}>
      <View style={styles.responsive}>
        <View style={styles.content}>
          <Typography size="small" name="title" text="Horários de atendimento:" />
          {/* <Typography
            contentContainerStyle={{ marginTop: 6 }}
            color={styleguide.colors.typographySecondary}
            size="small"
            name="body"
            text={`Segunda: 8:00 - 18:00\nTerça: 8:00 - 18:00\nQuarta: 8:00 - 18:00\nQuinta: 8:00 - 18:00\nSexta: 8:00 - 18:00\nSábado: 8:00 - 18:00\nDomingo: Fechado`}
          /> */}
          <Typography
            contentContainerStyle={{ marginTop: 12 }}
            color={styleguide.colors.typographySecondary}
            size="small"
            name="body"
            text={`Segunda: ${
              authentication.store.monday.length === 0
                ? 'Fechado'
                : authentication.store.monday.map((shift) => `${shift.start} - ${shift.end}`)
            }`}
          />
          <Typography
            contentContainerStyle={{ marginTop: 12 }}
            color={styleguide.colors.typographySecondary}
            size="small"
            name="body"
            text={`Terça: ${
              authentication.store.tuesday.length === 0
                ? 'Fechado'
                : authentication.store.tuesday.map((shift) => `${shift.start} - ${shift.end}`)
            }`}
          />
          <Typography
            contentContainerStyle={{ marginTop: 12 }}
            color={styleguide.colors.typographySecondary}
            size="small"
            name="body"
            text={`Quarta: ${
              authentication.store.wednesday.length === 0
                ? 'Fechado'
                : authentication.store.wednesday.map((shift) => `${shift.start} - ${shift.end}`)
            }`}
          />
          <Typography
            contentContainerStyle={{ marginTop: 12 }}
            color={styleguide.colors.typographySecondary}
            size="small"
            name="body"
            text={`Quinta: ${
              authentication.store.thursday.length === 0
                ? 'Fechado'
                : authentication.store.thursday.map((shift) => `${shift.start} - ${shift.end}`)
            }`}
          />
          <Typography
            contentContainerStyle={{ marginTop: 12 }}
            color={styleguide.colors.typographySecondary}
            size="small"
            name="body"
            text={`Sexta: ${
              authentication.store.friday.length === 0
                ? 'Fechado'
                : authentication.store.friday.map((shift) => `${shift.start} - ${shift.end}`)
            }`}
          />
          <Typography
            contentContainerStyle={{ marginTop: 12 }}
            color={styleguide.colors.typographySecondary}
            size="small"
            name="body"
            text={`Sábado: ${
              authentication.store.saturday.length === 0
                ? 'Fechado'
                : authentication.store.saturday.map((shift) => `${shift.start} - ${shift.end}`)
            }`}
          />
          <Typography
            contentContainerStyle={{ marginTop: 12 }}
            color={styleguide.colors.typographySecondary}
            size="small"
            name="body"
            text={`Domingo: ${
              authentication.store.sunday.length === 0
                ? 'Fechado'
                : authentication.store.sunday.map((shift) => `${shift.start} - ${shift.end}`)
            }`}
          />
        </View>
        {hasSocialMedia && (
          <>
            <Separator contentContainerStyle={{ marginTop }} variant={separatorVariant} />
            <View style={[styles.content, { marginTop }]}>
              <Typography size="small" name="title" text="Vem conversar com a gente" />
              <View style={styles.row}>
                {authentication.store.facebook !== '' && (
                  <ButtonIcon
                    // onPress={() => Linking.openURL(authentication.store.facebook)}
                    onPress={() => WebBrowser.openBrowserAsync(authentication.store.facebook)}
                    contentContainerStyle={{ marginRight: 12 }}
                    size="small"
                    icon="facebook"
                  />
                )}
                {authentication.store.instagram !== '' && (
                  <ButtonIcon
                    // onPress={() => Linking.openURL(authentication.store.instagram)}
                    onPress={() => WebBrowser.openBrowserAsync(authentication.store.instagram)}
                    contentContainerStyle={{ marginRight: 12 }}
                    size="small"
                    icon="instagram"
                  />
                )}
                {authentication.store.linkedin !== '' && (
                  <ButtonIcon
                    // onPress={() => Linking.openURL(authentication.store.linkedin)}
                    onPress={() => WebBrowser.openBrowserAsync(authentication.store.linkedin)}
                    contentContainerStyle={{ marginRight: 12 }}
                    size="small"
                    icon="linkedin"
                  />
                )}
                {authentication.store.twitter !== '' && (
                  <ButtonIcon
                    // onPress={() => Linking.openURL(authentication.store.twitter)}
                    onPress={() => WebBrowser.openBrowserAsync(authentication.store.twitter)}
                    contentContainerStyle={{ marginRight: 12 }}
                    size="small"
                    icon="twitter"
                  />
                )}
                {authentication.store.youtube !== '' && (
                  <ButtonIcon
                    // onPress={() => Linking.openURL(authentication.store.youtube)}
                    onPress={() => WebBrowser.openBrowserAsync(authentication.store.youtube)}
                    contentContainerStyle={{ marginRight: 12 }}
                    size="small"
                    icon="youtube"
                  />
                )}
                {authentication.store.tiktok !== '' && (
                  <ButtonIcon
                    // onPress={() => Linking.openURL(authentication.store.tiktok)}
                    onPress={() => WebBrowser.openBrowserAsync(authentication.store.tiktok)}
                    contentContainerStyle={{ marginRight: 12 }}
                    size="small"
                    icon="tiktok"
                  />
                )}
              </View>
            </View>
          </>
        )}
        <Separator variant={separatorVariant} contentContainerStyle={{ marginTop }} />
        <View style={[styles.content, { marginTop }]}>
          <Typography size="small" name="title" text="Transparência" />

          <ButtonText
            contentContainerStyle={{ marginTop: 12 }}
            variant="secondary"
            size="small"
            title="Termos de uso"
          />
          <ButtonText
            contentContainerStyle={{ marginTop: 12 }}
            variant="secondary"
            size="small"
            title="Politica de Privacidade"
          />
          <ButtonText
            contentContainerStyle={{ marginTop: 12 }}
            variant="secondary"
            size="small"
            title="Direitos do Consumidor"
          />
          <ButtonText
            contentContainerStyle={{ marginTop: 12 }}
            variant="secondary"
            size="small"
            title="Cookies"
          />
        </View>
      </View>
      <Typography
        contentContainerStyle={{ marginTop: 12 }}
        color={styleguide.colors.typographySecondary}
        size="small"
        name="caption"
        text={`©Copyright ${moment().year()} ${authentication.store.legalName} - ${
          authentication.store.type
        } nº ${authentication.store.document} - ${authentication.store.address.street} ${
          authentication.store.address.number
        }, ${authentication.store.address.complement}, ${
          authentication.store.address.neighborhood
        }, ${authentication.store.address.city} - ${authentication.store.address.state} CEP ${
          authentication.store.address.zipCode
        }. Todos os direitos reservados`}
      />
    </View>
  );
};

export default Footing;
