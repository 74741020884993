import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Clip = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11 5.14v5.36c0 2.6-1 4-3 4s-3-1.4-3-4V3.58C5 2.5 5.727 1.5 7 1.5c1.5 0 2 1.04 2 2.08V10c0 .66 0 1.5-1 1.5s-1-.84-1-1.5V5.14"
      stroke={color}
      strokeLinecap="round"
    />
  </Svg>
);

export default Clip;
