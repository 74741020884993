import * as React from "react";
import Svg, { Path } from "react-native-svg";

const LockClosed = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M21 12h2a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V15a3 3 0 0 1 3-3h2m10 0V8c0-3-2-5-5-5s-5 2-5 4v5m10 0H11"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LockClosed;
