import * as React from "react";
import Svg, { Path } from "react-native-svg";

const EyeClosed = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M7.99 3.5c-2.436 0-4.935 1.41-6.902 4.23a.5.5 0 0 0-.008.554C2.591 10.65 5.056 12.5 7.99 12.5c2.9 0 5.416-1.856 6.93-4.227a.504.504 0 0 0 0-.545C13.403 5.383 10.87 3.5 7.99 3.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8 10.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      stroke={color}
      strokeMiterlimit={10}
    />
    <Path stroke={color} strokeLinecap="round" d="M2.707 2.766 13 13.059" />
  </Svg>
);

export default EyeClosed;
