import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Atendimento = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14.733 17.667c-.15 2.07-1.686 3.666-3.361 3.666-1.676 0-3.215-1.595-3.361-3.666C7.858 15.512 9.354 14 11.37 14c2.02 0 3.515 1.551 3.362 3.667ZM11.333 22.4c-3.319 0-6.51 1.648-7.31 4.859-.106.424.16.845.597.845h13.427c.436 0 .7-.42.596-.845-.799-3.262-3.99-4.86-7.31-4.86ZM15.37 6.066a1.764 1.764 0 0 1 1.667-1.548l9.116-.43A1.764 1.764 0 0 1 28 5.848v5.387C28 12.21 27.21 13 26.236 13h-9.19c-.245 0-.487.05-.71.15l-1.369.6a.353.353 0 0 1-.492-.365l.895-7.32Z"
      fill={color}
    />
  </Svg>
);

export default Atendimento;
