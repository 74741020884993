import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const Info = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15.75 21a.75.75 0 0 0 0-1.5V21ZM8.25 7.5a.75.75 0 1 0 0 1.5V7.5Zm0 12a.75.75 0 1 0 0 1.5v-1.5Zm7.5 0c-.653 0-1.38-.015-1.958-.362-.494-.296-1.042-.946-1.042-2.638h-1.5c0 2.058.702 3.283 1.77 3.924.986.592 2.133.576 2.73.576v-1.5Zm-3-3V10.5h-1.5V16.5h1.5Zm3 3h-7.5V21h7.5v-1.5Zm-3-9c0-.866-.096-1.713-.66-2.294-.57-.587-1.423-.706-2.34-.706V9c.886 0 1.159.144 1.263.251.11.113.237.392.237 1.25h1.5Zm-3-3a15526.215 15526.215 0 0 0-1.371 0H8.25V9h.129A2814.78 2814.78 0 0 1 9.75 9V7.5Z"
      fill={color}
    />
    <Circle cx={12} cy={4.5} r={0.75} stroke={color} strokeWidth={1.5} />
  </Svg>
);

export default Info;
