import * as React from "react";
import Svg, { Path } from "react-native-svg";

const History = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M6.666 11.852a10.67 10.67 0 0 1 9.83-6.519c5.891 0 10.667 4.776 10.667 10.667s-4.776 10.667-10.667 10.667a10.67 10.67 0 0 1-9.83-6.519m0-8.296H10m-3.334 0L5.333 8.667"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M17 10.667a1 1 0 0 0-2 0h2Zm-1 6h-1v.72l.684.228.316-.948Zm3.684 2.282a1 1 0 0 0 .632-1.898l-.632 1.898ZM15 10.667v6h2v-6h-2Zm.684 6.948 4 1.334.632-1.898-4-1.333-.632 1.897Z"
      fill={color}
    />
  </Svg>
);

export default History;
