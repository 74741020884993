import * as React from "react";
import Svg, { Path } from "react-native-svg";

const QrCode = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 15H2V2h13v13ZM4.75 12.25h7.5v-7.5h-7.5v7.5ZM6 21h5v5H6v-5Zm11-6h13V2H17v13Zm10.25-2.75h-7.5v-7.5h7.5v7.5ZM11 6H6v5h5V6Zm15 15h-5v-4h-4v4h4v5h5v4h4v-4h-4v-5Zm4-4h-3v3h3v-3ZM20 27h-3v3h3v-3Zm6-21h-5v5h5V6ZM2 30h13V17H2v13Zm10.25-2.75h-7.5v-7.5h7.5v7.5Z"
      fill={color}
    />
  </Svg>
);

export default QrCode;
