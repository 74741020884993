import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Creditcard = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18.162 7.5V5.943c0-1.211-.981-2.193-2.192-2.193H4.068c-1.211 0-2.193.982-2.193 2.193V7.5m16.287 0v6.586c0 1.211-.981 2.193-2.192 2.193H4.068a2.193 2.193 0 0 1-2.193-2.193V7.5m16.287 0H1.875M5 12.5v-.86h1.875v.86H5Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Creditcard;
