import React, { useMemo } from 'react';
import { View } from 'react-native';

import { createStyles } from './styles';
import { useStyleguide } from '../../hooks/styleguide';
import Typography from '../Typography';

const Separator = ({
  variant = 'horizontal',
  text,
  border = 'solid',
  contentContainerStyle = {},
}) => {
  const { styleguide } = useStyleguide();

  const flexDirection = useMemo(() => {
    const variants = {
      horizontal: 'row',
      vertical: 'column',
    };

    return variants[variant];
  }, [variant]);

  const borderBottomWidth = useMemo(() => {
    const variants = {
      horizontal: 1,
      vertical: 0,
    };

    return variants[variant];
  }, [variant]);

  const borderLeftWidth = useMemo(() => {
    const variants = {
      horizontal: 0,
      vertical: 1,
    };

    return variants[variant];
  }, [variant]);

  const borderStyle = useMemo(() => {
    const variants = {
      solid: 'solid',
      dashed: 'dashed',
    };

    return variants[border];
  }, [border]);

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  return (
    <View
      style={[
        styles.container,
        { flexDirection, ...(variant === 'horizontal' ? { width: '100%' } : { height: '100%' }) },
        contentContainerStyle,
      ]}>
      <View style={[styles.separator, { borderStyle, borderBottomWidth, borderLeftWidth }]} />
      {text && (
        <Typography
          variant="caption"
          color={styleguide.colors.typographySecondary}
          contentContainerStyle={{
            paddingHorizontal: 8,
          }}
          text={text}
        />
      )}
      <View style={[styles.separator, { borderStyle, borderBottomWidth, borderLeftWidth }]} />
    </View>
  );
};

export default Separator;
