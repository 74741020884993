import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Shuffle = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M2.5 13.75h3.328a3.125 3.125 0 0 0 2.6-1.391L10 10m5.625 1.875L17.5 13.75l-1.875 1.875v-3.75Zm0-7.5L17.5 6.25l-1.875 1.875v-3.75Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2.5 6.25h3.328a3.125 3.125 0 0 1 2.6 1.391l3.144 4.718a3.126 3.126 0 0 0 2.6 1.391h2.078m0-7.5h-2.078a3.125 3.125 0 0 0-2.6 1.391l-.322.484"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Shuffle;
