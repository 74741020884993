import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Google = () => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8.159h8.552a8.718 8.718 0 0 1-2.646 8.286l-2.472-2.118a5.55 5.55 0 0 0 1.725-2.368c.07-.188.136-.379.186-.576H10V8.159Z"
      fill="#4285F4"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m2.173 13.898 2.68-1.892A5.525 5.525 0 0 0 10 15.526c1.3 0 2.492-.45 3.434-1.202l2.472 2.119A8.708 8.708 0 0 1 10 18.75c-3.43 0-6.392-1.979-7.827-4.852Z"
      fill="#34A853"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.282 5.876A8.747 8.747 0 0 1 9.999 1.25c2.299 0 4.384.894 5.946 2.344l-2.262 2.262A5.53 5.53 0 0 0 10 4.474a5.527 5.527 0 0 0-5.1 3.396L2.282 5.876Z"
      fill="#EA4335"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 9.999c0-1.493.375-2.896 1.034-4.125l2.617 1.994a5.507 5.507 0 0 0-.039 4.156l-2.67 1.908A8.702 8.702 0 0 1 1.25 10Z"
      fill="#FBBC05"
    />
  </Svg>
);

export default Google;
