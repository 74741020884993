import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Unavailable = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={8} cy={8} r={5.5} stroke={color} />
    <Path stroke={color} strokeLinecap="round" d="m3.333 13.293 9.96-9.96" />
  </Svg>
);

export default Unavailable;
