import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const BarCode = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d="M3.75 3.75v10.5M3.75 16.5v3.75" stroke={color} strokeWidth={1.5} />
    <Path d="M6.75 3.75v10.5M6.75 16.5v3.75" stroke={color} strokeWidth={3} />
    <Path d="M10.5 3.75v10.5M10.5 16.5v3.75" stroke={color} strokeWidth={1.5} />
    <Path d="M14.25 3.75v10.5M14.25 16.5v3.75" stroke={color} strokeWidth={2.25} />
    <Path d="M17.25 3.75v10.5M17.25 16.5v3.75" stroke={color} strokeWidth={1.125} />
    <Path d="M19.875 3.75v10.5M19.875 16.5v3.75" stroke={color} strokeWidth={2.25} />
    <Path stroke="#E65C5C" strokeWidth={1.5} strokeLinecap="round" d="M2.25 16.5h19.5" />
  </Svg>
);

export default BarCode;
