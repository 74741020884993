import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Item = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.172 2H9.626a.417.417 0 0 0-.294.121L2.242 9.21a.832.832 0 0 0 0 1.174l3.375 3.375a.832.832 0 0 0 1.175 0l7.087-7.085A.417.417 0 0 0 14 6.38V2.83A.827.827 0 0 0 13.172 2Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="M11.334 4.833a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Z" stroke={color} />
  </Svg>
);

export default Item;
