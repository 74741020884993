import * as React from "react";
import Svg, { Path } from "react-native-svg";

const BookOpened = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15.683 8.667 28.87 4.705a.1.1 0 0 1 .129.096V23.93a.1.1 0 0 1-.067.095l-13.25 4.643m0-20L3.135 4.05A.1.1 0 0 0 3 4.143v19.124a.1.1 0 0 0 .061.092l12.622 5.308m0-20v20"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m19 22.392 6.333-2.215M19 18l6.333-2.216M19 13.608l6.333-2.216"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
);

export default BookOpened;
