import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Moon = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M5 4.25c0-.957.14-1.925.5-2.75-2.388 1.04-4 3.479-4 6.25a6.75 6.75 0 0 0 6.75 6.75c2.771 0 5.21-1.612 6.25-4-.825.36-1.793.5-2.75.5A6.75 6.75 0 0 1 5 4.25Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Moon;
