import * as React from "react";
import Svg, { Path } from "react-native-svg";

const StorageAdd = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m3.164 5.747-.17-.47a.5.5 0 0 0-.182.115l.352.355Zm0 7.142h-.5a.5.5 0 0 0 .33.47l.17-.47Zm4.803 1.747-.17.47a.5.5 0 0 0 .335.002l-.165-.472Zm5-1.747.166.472a.5.5 0 0 0 .335-.472h-.5Zm0-7.142.415-.28a.5.5 0 0 0-.25-.192l-.164.472ZM7.968 4l.165-.472a.5.5 0 0 0-.336.002l.171.47Zm1.7 6.078-.415.28a.5.5 0 0 0 .585.19l-.17-.47Zm5-1.814.17.47a.5.5 0 0 0 .244-.75l-.414.28ZM.982 7.206a.5.5 0 0 0 .703.71l-.703-.71ZM13.468 8.88a.5.5 0 0 0-1 0h1ZM2.993 13.36l4.803 1.747.342-.94-4.803-1.747-.342.94Zm5.14 1.749 5-1.747-.33-.944-5 1.747.33.944Zm5-9.833-5-1.747-.33.944 5 1.747.33-.944ZM7.796 3.53 2.993 5.277l.342.94L8.138 4.47l-.342-.94Zm5.001 1.747-5 1.814.34.94 5.001-1.814-.34-.94ZM8.144 7.093 3.34 5.28l-.353.936L7.79 8.029l.353-.936Zm-.677.468v7.075h1V7.56h-1Zm.086.28 1.699 2.517.829-.56-1.7-2.517-.828.56Zm2.284 2.707 5-1.814-.34-.94-5.001 1.814.34.94Zm5.244-2.564-1.699-2.517-.829.56 1.7 2.517.828-.56ZM2.812 5.392.982 7.206l.703.71 1.831-1.814-.704-.71Zm-.148.355v1.235h1V5.747h-1Zm0 1.235v5.907h1V6.982h-1Zm10.804 5.907V8.88h-1v4.009h1Z"
      fill={color}
    />
    <Path
      d="M12 2.746h1.333m1.334 0h-1.334m0 0V4.17m0-1.424V1.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default StorageAdd;
