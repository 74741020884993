import * as React from "react";
import Svg, { Path } from "react-native-svg";

const CalendarDay = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M24 5h2a3 3 0 0 1 3 3v2m-5-5V3m0 2H8m0 0H6a3 3 0 0 0-3 3v2m5-5V3m-5 7v16a3 3 0 0 0 3 3h20a3 3 0 0 0 3-3V10M3 10h26"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16 24V14l-2 2m2 8h2m-2 0h-2"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default CalendarDay;
