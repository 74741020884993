import * as React from "react";
import Svg, { Path } from "react-native-svg";

const BarCode = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d="M5 5v14M5 22v5" stroke={color} strokeWidth={2} />
    <Path d="M9 5v14M9 22v5" stroke={color} strokeWidth={4} />
    <Path d="M14 5v14M14 22v5" stroke={color} strokeWidth={2} />
    <Path d="M19 5v14M19 22v5" stroke={color} strokeWidth={3} />
    <Path d="M23 5v14M23 22v5" stroke={color} strokeWidth={1.5} />
    <Path d="M26.5 5v14M26.5 22v5" stroke={color} strokeWidth={3} />
    <Path stroke="#E65C5C" strokeWidth={2} strokeLinecap="round" d="M3 22h26" />
  </Svg>
);

export default BarCode;
