import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const User = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M21.303 9.33c-.236 3.01-2.664 5.33-5.313 5.33-2.648 0-5.081-2.319-5.313-5.33C10.436 6.198 12.8 4 15.99 4c3.19 0 5.555 2.255 5.313 5.33Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16 18c-4.526 0-8.878 2.723-9.968 8.025-.145.701.218 1.395.814 1.395h18.31c.594 0 .955-.694.813-1.395C24.879 20.638 20.526 18 16 18Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default User;
