import React, { useMemo } from 'react';
import { View } from 'react-native';

import { createStyles } from './styles';
import { useStyleguide } from '../../hooks/styleguide';
import Typography from '../Typography';

const Badge = ({ number }) => {
  const { styleguide } = useStyleguide();

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  return (
    <View style={styles.container}>
      <Typography
        size="small"
        name="label"
        color={styleguide.colors.inBrandPrimary}
        text={number > 9 ? '9+' : number}
      />
    </View>
  );
};

export default Badge;
