import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Username = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8 14a6 6 0 1 1 6-6c0 2.25-1.5 3.75-3 3.75-3 0-2.403-4.819-.75-6"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.99 8s-.529 2.25-2.029 2.25c-2.43 0-1.111-4.5.776-4.5C9.142 5.75 8.989 8 8.989 8Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Username;
