import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Facebook = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8.042a7 7 0 1 0-8.094 6.915v-4.891H5.128V8.042h1.778V6.5c0-1.755 1.046-2.724 2.644-2.724.766 0 1.567.137 1.567.137v1.723h-.883c-.869 0-1.14.539-1.14 1.093v1.313h1.94l-.31 2.024h-1.63v4.892A7.002 7.002 0 0 0 15 8.042Z"
      fill={color}
    />
  </Svg>
);

export default Facebook;
