import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Facebook = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.75 10.052a8.75 8.75 0 1 0-10.117 8.644v-6.114H6.41v-2.53h2.222V8.124c0-2.192 1.306-3.404 3.305-3.404.957 0 1.959.17 1.959.17v2.154h-1.104c-1.086 0-1.426.675-1.426 1.368v1.64h2.426l-.387 2.53h-2.039v6.115a8.752 8.752 0 0 0 7.383-8.645Z"
      fill={color}
    />
  </Svg>
);

export default Facebook;
