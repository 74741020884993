import * as Linking from 'expo-linking';
import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';

import { useStyleguide } from './styleguide';

const UrlContext = createContext({});

const getInitialUrl = await Linking.getInitialURL();

const UrlProvider = ({ children }) => {
  const { queryParams, hostname, path, scheme } = Linking.parse(getInitialUrl);
  const splitHostname = hostname.split('.');

  console.log(
    'getInitialUrl',
    getInitialUrl,
    // 'splitHostname',
    // splitHostname,
    'scheme',
    scheme,
    'hostname',
    hostname,
    'path',
    path,
    'queryParams',
    queryParams,
  );

  const initialUrl = {
    subdomain: splitHostname.length === 1 || splitHostname[0] === '192' ? 'www' : splitHostname[0],
    // domain: splitHostname.length === 1 ? splitHostname[0] : splitHostname[1],
    domain: hostname,
    params: queryParams || {},
  };
  const { styleguide, setStyleguide } = useStyleguide();

  const [url, setUrl] = useState(initialUrl);

  const addParams = useCallback(
    async (params) => {
      const newParams = { ...url.params, ...params };

      setUrl((prevState) => ({ ...prevState, params: newParams }));

      if (styleguide.platform.OS === 'web') {
        const newUrl = Linking.createURL('', {
          queryParams: newParams,
        });

        // eslint-disable-next-line no-undef
        const newState = new URL(newUrl);

        window.history.replaceState(null, null, newState);
      }
    },
    [url, styleguide.platform.OS],
  );

  const removeParam = useCallback(
    (param) => {
      const newParams = { ...url.params };

      delete newParams[param];

      setUrl((prevState) => ({ ...prevState, params: newParams }));

      if (styleguide.platform.OS === 'web') {
        const newUrl = Linking.createURL('', {
          queryParams: newParams,
        });

        // eslint-disable-next-line no-undef
        const newState = new URL(newUrl);

        window.history.replaceState(null, null, newState);
      }
    },
    [url, styleguide.platform.OS],
  );

  useEffect(() => {
    if (url.subdomain === 'dash') {
      setStyleguide((prevState) => ({
        ...prevState,
        application: 'dash',
      }));
    } else {
      setStyleguide((prevState) => ({
        ...prevState,
        application: 'app',
      }));
    }
  }, [url.subdomain]);

  return (
    <UrlContext.Provider
      value={{
        url,
        addParams,
        removeParam,
      }}>
      {children}
    </UrlContext.Provider>
  );
};

function useUrl() {
  const context = useContext(UrlContext);

  if (!context) {
    throw new Error('useUrl must be used within as UrlProvider');
  }

  return context;
}

export { UrlProvider, useUrl };
