import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Clip = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.75 6.425v6.7c0 3.25-1.25 5-3.75 5s-3.75-1.75-3.75-5v-8.65c0-1.35.91-2.6 2.5-2.6 1.875 0 2.5 1.3 2.5 2.6V12.5c0 .825 0 1.875-1.25 1.875s-1.25-1.05-1.25-1.875V6.425"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
    />
  </Svg>
);

export default Clip;
