import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Bell = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M9.999 12v.5A2.001 2.001 0 0 1 8 14.5a1.997 1.997 0 0 1-1.998-2V12m7.359-1.018c-.802-.982-1.368-1.482-1.368-4.19 0-2.48-1.265-3.363-2.307-3.792a.556.556 0 0 1-.31-.33C9.193 2.048 8.68 1.5 8 1.5c-.68 0-1.193.548-1.373 1.17a.551.551 0 0 1-.311.33c-1.043.43-2.307 1.31-2.307 3.792C4.008 9.5 3.442 10 2.64 10.982 2.309 11.39 2.6 12 3.18 12h9.645c.578 0 .867-.613.537-1.018Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Bell;
