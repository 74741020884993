export const responsiveMobile = 360;
export const responsiveTablet = 720;
export const responsiveNotebook = responsiveTablet + responsiveMobile;
export const responsiveDesktop = responsiveNotebook + responsiveMobile;

export const WIDTH_WINDOW_TABLET = 1205;
// export const WIDTH_WINDOW_TABLET = 1133;
export const WIDTH_WINDOW_WEB = 1512;
export const WIDTH_NAVIGATOR_DRAWER_TABLET = 80;
export const WIDTH_NAVIGATOR_DRAWER_WEB = 288;
