import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Clip = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16.5 7.71v8.04c0 3.9-1.5 6-4.5 6s-4.5-2.1-4.5-6V5.37c0-1.62 1.09-3.12 3-3.12 2.25 0 3 1.56 3 3.12V15c0 .99 0 2.25-1.5 2.25s-1.5-1.26-1.5-2.25V7.71"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

export default Clip;
