import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Apple = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.639 5.346c-1.575 0-2.241.752-3.338.752-1.124 0-1.982-.746-3.347-.746-1.336 0-2.76.815-3.665 2.205-1.27 1.959-1.055 5.65 1.002 8.793.736 1.125 1.72 2.387 3.008 2.401h.024c1.12 0 1.453-.733 2.995-.742h.023c1.519 0 1.823.738 2.939.738h.024c1.288-.014 2.324-1.412 3.06-2.533.53-.807.727-1.211 1.133-2.123-2.976-1.13-3.454-5.35-.511-6.967-.899-1.125-2.161-1.776-3.351-1.776l.004-.002Z"
      fill={color}
    />
    <Path
      d="M13.292 1.25c-.938.064-2.031.66-2.672 1.44-.581.706-1.06 1.754-.872 2.77h.075c.998 0 2.02-.601 2.617-1.372.575-.733 1.011-1.772.852-2.838Z"
      fill={color}
    />
  </Svg>
);

export default Apple;
