import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Mail = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M2.68 4.234a.755.755 0 0 0 .082 1.062l6.75 5.806c.28.242.695.242.976 0l6.75-5.806a.755.755 0 0 0 .081-1.062M3.25 3.971a.751.751 0 0 0-.75.753v10.161c0 .416.336.753.75.753h13.5c.414 0 .75-.337.75-.753V4.725a.751.751 0 0 0-.75-.754H3.25Z"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Mail;
