import React, { useCallback, useMemo } from 'react';
import { Pressable } from 'react-native';

import { createStyles } from './styles';
import { useStyleguide } from '../../hooks/styleguide';
import Icon from '../Icon';

const ButtonFloat = ({ buttons = [], contentContainerStyle = {} }) => {
  const { styleguide } = useStyleguide();

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  const handlePress = useCallback(() => {}, []);

  return (
    <Pressable
      onPress={() => {
        if (buttons.length > 1) {
          handlePress();
        } else {
          buttons[0].onPress();
        }
      }}
      style={[styles.container, contentContainerStyle]}>
      <Icon
        size="32"
        name={buttons.length > 1 ? 'plus' : buttons[0].icon}
        color={styleguide.colors.inBrandPrimary}
      />
    </Pressable>
  );
};

export default ButtonFloat;
