import * as React from "react";
import Svg, { Path } from "react-native-svg";

const More = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16 16.334a1 1 0 1 0-2 0 1 1 0 0 0 2 0ZM16 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0ZM16 25.666a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default More;
