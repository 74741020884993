import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      backgroundColor: styleguide.colors.backgroundPrimary,
      position: styleguide.platform.OS === 'web' ? 'fixed' : 'absolute',
      height:
        styleguide.platform.OS === 'android'
          ? styleguide.frame.height
          : styleguide.windowDimensions.height,
      width:
        styleguide.platform.OS === 'android'
          ? styleguide.frame.width
          : styleguide.windowDimensions.width,
      // touchAction: 'none', // android pich zoom
      // overflowY: 'scroll', // android web addressbar prevent hide
      // overflowX: 'hidden', // android web addressbar prevent hide

      // top: 0,
      // left: 0,
      // right: 0,
      // bottom: 0,
      // overflow: 'hidden',
    },

    // keyboardDismiss: {
    //   height: '100%',
    //   width: '100%',
    //   cursor: 'default',
    //   position: 'absolute',
    //   backgroundColor: 'black',
    // },
  });
