import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Unavailable = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={12} cy={12} r={8.25} stroke={color} strokeWidth={1.5} />
    <Path
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M5 19.939 19.939 5"
    />
  </Svg>
);

export default Unavailable;
