import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Telephone = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.625 11.403c-.458-.461-1.568-1.135-2.106-1.406-.701-.353-.759-.382-1.31.027-.367.273-.611.517-1.041.426-.43-.092-1.365-.61-2.183-1.425-.819-.816-1.366-1.778-1.458-2.206-.092-.429.156-.67.427-1.039.381-.519.352-.605.026-1.306-.254-.545-.947-1.644-1.41-2.1-.496-.49-.496-.403-.815-.27-.26.109-.51.242-.745.397-.462.306-.718.561-.897.944-.18.383-.26 1.28.665 2.96s1.574 2.54 2.918 3.88c1.343 1.34 2.376 2.06 3.886 2.906 1.868 1.047 2.584.843 2.968.664.384-.179.64-.433.947-.894.156-.236.289-.485.399-.745.132-.318.219-.318-.271-.813Z"
      stroke={color}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Telephone;
