import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Shuffle = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M2 11h2.662a2.5 2.5 0 0 0 2.08-1.113L8 8m4.5 1.5L14 11l-1.5 1.5v-3Zm0-6L14 5l-1.5 1.5v-3Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2 5h2.662a2.5 2.5 0 0 1 2.08 1.113l2.516 3.774A2.5 2.5 0 0 0 11.338 11H13m0-6h-1.662a2.5 2.5 0 0 0-2.08 1.113L9 6.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Shuffle;
