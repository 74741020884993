import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Transaction = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M28 21.99v-9.016m0 0h-8.932m8.932 0L12.974 28M4 10.01v9.016m0 0h8.93m-8.93 0 15-15"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Transaction;
