import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Home = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M19 28h9V13L16 4 4 13v15h9m6 0v-9h-6v9m6 0h-6"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Home;
