import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const Info = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10.5 14a.5.5 0 1 0 0-1v1Zm-5-9a.5.5 0 1 0 0 1V5Zm0 8a.5.5 0 0 0 0 1v-1Zm5 0c-.435 0-.92-.01-1.305-.241-.33-.198-.695-.63-.695-1.759h-1c0 1.372.468 2.189 1.18 2.616.657.394 1.422.384 1.82.384v-1Zm-2-2V7h-1V11h1Zm2 2h-5v1h5v-1Zm-2-6c0-.577-.064-1.142-.44-1.53C7.68 5.08 7.11 5 6.5 5v1c.59 0 .773.096.842.168.073.075.158.26.158.832h1Zm-2-2h-1v1h1V5Z"
      fill={color}
    />
    <Circle cx={8} cy={3} r={0.5} stroke={color} />
  </Svg>
);

export default Info;
