import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Store = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15.625 16.875v-7.5m-11.25 0v7.5m-1.25 0h13.75m-8.72 0V12.5c0-.153.062-.3.174-.408a.605.605 0 0 1 .421-.169h2.5c.158 0 .31.06.42.17a.568.568 0 0 1 .175.407v4.375"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M3.125 7.812v-.274c0-.162.041-.322.121-.466L5.1 3.7c.194-.353.59-.576 1.025-.576h7.75c.434 0 .83.223 1.025.576l1.854 3.37c.08.145.121.305.121.467v.274m-13.75 0Zm0 0c0 .863.77 1.563 1.719 1.563.949 0 1.718-.7 1.718-1.563 0 .863.77 1.563 1.72 1.563.948 0 1.718-.7 1.718-1.563 0 .863.77 1.563 1.719 1.563.949 0 1.719-.7 1.719-1.563 0 .863.769 1.563 1.718 1.563.95 0 1.719-.7 1.719-1.563m0 0Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Store;
