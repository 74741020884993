import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Calendar = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12 2.5h1A1.5 1.5 0 0 1 14.5 4v1M12 2.5v-1m0 1H4m0 0H3A1.5 1.5 0 0 0 1.5 4v1M4 2.5v-1M1.5 5v8A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V5m-13 0h13"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M9.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM12 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM9.5 10.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM12 10.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM4.5 10.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM7 10.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM4.5 13a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM7 13a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM9.5 13a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
      fill={color}
    />
  </Svg>
);

export default Calendar;
