import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Live = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18.793 16.667c0 1.48-1.23 2.715-2.79 2.715s-2.79-1.234-2.79-2.715c0-1.48 1.23-2.714 2.79-2.714s2.79 1.234 2.79 2.714Z"
      stroke={color}
      strokeWidth={2}
    />
    <Path
      d="M21.052 21.618c2.526-3.095 2.526-6.81 0-9.904M10.948 21.618c-2.526-3.095-2.526-6.81 0-9.904M24.214 25.333c2.86-2.889 6.863-10.4 0-17.333M7.792 25.333c-2.86-2.889-6.863-10.4 0-17.333"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
);

export default Live;
