import * as React from "react";
import Svg, { Path } from "react-native-svg";

const StorageRemove = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m3.955 7.184-.213-.588a.625.625 0 0 0-.226.144l.44.444Zm0 8.927H3.33c0 .263.165.498.412.587l.213-.587Zm6.004 2.184-.213.587c.135.05.283.05.42.003l-.207-.59Zm6.25-2.184.207.59a.625.625 0 0 0 .419-.59h-.625Zm0-8.927.519-.35a.625.625 0 0 0-.312-.24l-.206.59ZM9.96 5l.206-.59a.625.625 0 0 0-.42.003L9.96 5Zm2.124 7.597-.518.35c.16.238.462.336.731.238l-.213-.588Zm6.25-2.267.214.587a.625.625 0 0 0 .305-.937l-.518.35ZM1.228 9.007a.625.625 0 1 0 .88.888l-.88-.888ZM16.835 11.1a.625.625 0 1 0-1.25 0h1.25ZM3.742 16.698l6.004 2.184.427-1.175-6.004-2.183-.427 1.175Zm6.423 2.187 6.25-2.184-.411-1.18-6.25 2.184.411 1.18Zm6.25-12.291-6.25-2.184-.412 1.18 6.25 2.184.413-1.18Zm-6.67-2.181L3.743 6.596l.427 1.175 6.004-2.184-.427-1.174Zm6.252 2.183-6.25 2.268.425 1.175 6.25-2.268-.425-1.175Zm-5.817 2.27L4.176 6.6l-.441 1.17 6.003 2.267.442-1.17Zm-.846.585v8.844h1.25V9.45h-1.25Zm.107.35 2.124 3.146 1.036-.7-2.124-3.146-1.036.7Zm2.855 3.384 6.25-2.268-.425-1.175-6.251 2.268.426 1.175Zm6.556-3.205-2.124-3.146-1.036.7 2.124 3.145 1.036-.699ZM3.516 6.74 1.227 9.007l.88.888 2.288-2.267-.88-.888Zm-.186.444v1.544h1.25V7.184H3.33Zm0 1.544v7.383h1.25V8.728H3.33Zm13.505 7.383v-5.01h-1.25v5.01h1.25Z"
      fill={color}
    />
    <Path
      d="M15 3.333h3.333"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default StorageRemove;
