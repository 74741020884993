import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Chair = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11 13.5v-2.807M11 8.5v2.193m0 0H5m0 0V8.5m0 2.193V13.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M4 7.667C4 7.298 4.298 7 4.667 7h6.666c.368 0 .667.298.667.667V9H4V7.667Z"
      fill={color}
    />
    <Path
      d="M10.5 7.5v-5m-5 0v5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5.5 2.5s1.466.356 2.5.356 2.5-.356 2.5-.356v2.644S9.034 5.5 8 5.5s-2.5-.356-2.5-.356V2.5Z"
      fill={color}
    />
  </Svg>
);

export default Chair;
