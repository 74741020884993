import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Helpball = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={16} cy={24} r={1} stroke={color} strokeWidth={2} />
    <Path
      d="M17 19c-4 0-1.418-2.582 2-6 1.678-1.678 2-6-3-6-2 0-4 1.5-4 3"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Circle cx={16} cy={16} r={13} stroke={color} strokeWidth={2} />
  </Svg>
);

export default Helpball;
