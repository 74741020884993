import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Print = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18 17.25h1.125A1.88 1.88 0 0 0 21 15.375v-7.5A1.88 1.88 0 0 0 19.125 6H4.875A1.88 1.88 0 0 0 3 7.875v7.5a1.88 1.88 0 0 0 1.875 1.875H6"
      stroke={color}
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <Path
      d="M16.86 11.25H7.14c-.63 0-1.14.51-1.14 1.14v7.47c0 .63.51 1.14 1.14 1.14h9.72c.63 0 1.14-.51 1.14-1.14v-7.47c0-.63-.51-1.14-1.14-1.14ZM18 6V4.875A1.88 1.88 0 0 0 16.125 3h-8.25A1.88 1.88 0 0 0 6 4.875V6"
      stroke={color}
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Print;
