import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { useAuthentication } from './authentication';
import { useDatabase } from './database';
import { useStyleguide } from './styleguide';

const CollectionContext = createContext({});

const CollectionProvider = ({ children }) => {
  const { authentication } = useAuthentication();
  const { listenArray } = useDatabase();
  const { styleguide } = useStyleguide();

  const landingsUnsubscribeRef = useRef(() => {});
  // const usersUnsubscribeRef = useRef(() => {});
  // const storesUnsubscribeRef = useRef(() => {});
  // const permissionsUnsubscribeRef = useRef(() => {});
  // const sectionsUnsubscribeRef = useRef(() => {});
  // const productsUnsubscribeRef = useRef(() => {});
  // const complementsUnsubscribeRef = useRef(() => {});
  // const componentsUnsubscribeRef = useRef(() => {});
  // const suppliersUnsubscribeRef = useRef(() => {});

  const [landings, setLandings] = useState([]);
  const [loadingLandings, setLoadingLandings] = useState(true);
  // const [permissions, setPermissions] = useState([]);
  // const [loadingPermissions, setLoadingPermissions] = useState(true);
  // const [stores, setStores] = useState([]);
  // const [loadingStores, setLoadingStores] = useState(true);
  // const [users, setUsers] = useState([]);
  // const [sections, setSections] = useState([]);
  // const [loadingSections, setLoadingSections] = useState(true);
  // const [products, setProducts] = useState([]);
  // const [loadingProducts, setLoadingProducts] = useState(true);
  // const [complements, setComplements] = useState([]);
  // const [loadingComplements, setLoadingComplements] = useState(true);
  // const [components, setComponents] = useState([]);
  // const [loadingComponents, setLoadingComponents] = useState(true);
  // const [suppliers, setSuppliers] = useState([]);
  // const [loadingSuppliers, setLoadingSuppliers] = useState(true);
  const [loadingCollections, setLoadingCollections] = useState(true);

  // useEffect(() => {
  //   const color = COLORS_BRAND.filter((item) => item.name === store.color)[0];
  //   const brandColors = styleguide.theme === 'light' ? color.brandColorLight : color.brandColorDark;

  //   setStyleguide((prevState) => ({
  //     ...prevState,
  //     brandColors,
  //   }));
  // }, [store.color, styleguide.theme]);

  // useEffect(() => {
  //   if (authentication.userId !== undefined) {
  //     storesUnsubscribeRef.current = listenArray({
  //       collectionName: 'stores',
  //       setLoadingHooks: setLoadingStores,
  //       setHooks: setStores,
  //       foreignKeyName: 'usersIds',
  //       foreignKeyValue: authentication.userId,
  //       foreignKeyValueType: 'array',
  //     });

  //     return () => {
  //       storesUnsubscribeRef.current();
  //     };
  //   }
  // }, [authentication.user.id]);

  useEffect(() => {
    (async () => {
      if (authentication.store.id !== undefined && styleguide.application !== '') {
        setLoadingCollections(true);

        landingsUnsubscribeRef.current = await listenArray({
          collectionName: 'landings',
          setLoadingHooks: setLoadingLandings,
          setHooks: setLandings,
          foreignKeyName: 'storeId',
          foreignKeyValue: authentication.store.id,
        });
        // productsUnsubscribeRef.current = listenArray({
        //   collectionName: 'products',
        //   setLoadingHooks: setLoadingProducts,
        //   setHooks: setProducts,
        //   foreignKeyName: 'storeId',
        //   foreignKeyValue: authentication.store.id,
        // });
        // permissionsUnsubscribeRef.current = listenArray({
        //   collectionName: 'permissions',
        //   setLoadingHooks: setLoadingPermissions,
        //   setHooks: setPermissions,
        //   foreignKeyName: 'storeId',
        //   foreignKeyValue: authentication.storeId,
        // });
        // sectionsUnsubscribeRef.current = listenArray({
        //   collectionName: 'sections',
        //   setLoadingHooks: setLoadingSections,
        //   setHooks: setSections,
        //   foreignKeyName: 'storeId',
        //   foreignKeyValue: authentication.storeId,
        // });
        // complementsUnsubscribeRef.current = listenArray({
        //   collectionName: 'complements',
        //   setLoadingHooks: setLoadingComplements,
        //   setHooks: setComplements,
        //   foreignKeyName: 'storeId',
        //   foreignKeyValue: authentication.storeId,
        // });
        // componentsUnsubscribeRef.current = listenArray({
        //   collectionName: 'components',
        //   setLoadingHooks: setLoadingComponents,
        //   setHooks: setComponents,
        //   foreignKeyName: 'storeId',
        //   foreignKeyValue: authentication.storeId,
        // });
        // suppliersUnsubscribeRef.current = listenArray({
        //   collectionName: 'suppliers',
        //   setLoadingHooks: setLoadingSuppliers,
        //   setHooks: setSuppliers,
        //   foreignKeyName: 'storeId',
        //   foreignKeyValue: authentication.storeId,
        // });
      }

      return () => {
        landingsUnsubscribeRef.current();
        // productsUnsubscribeRef.current();
        // permissionsUnsubscribeRef.current();
        // sectionsUnsubscribeRef.current();
        // complementsUnsubscribeRef.current();
        // componentsUnsubscribeRef.current();
        // suppliersUnsubscribeRef.current();
      };
    })();
  }, [authentication.store.id, styleguide.application]);

  useEffect(() => {
    if (
      !loadingLandings
      // && !loadingProducts
    ) {
      setLoadingCollections(false);
    }
  }, [loadingLandings]);

  return (
    <CollectionContext.Provider
      value={{
        landings,
        loadingLandings,
        // products,
        // users,
        // permissions,
        // loadingPermissions,
        // stores,
        // loadingStores,
        // sections,
        // loadingSections,
        // loadingProducts,
        // complements,
        // loadingComplements,
        // components,
        // loadingComponents,
        // suppliers,
        // loadingSuppliers,
        loadingCollections,
      }}>
      {children}
    </CollectionContext.Provider>
  );
};

function useCollection() {
  const context = useContext(CollectionContext);

  if (!context) {
    throw new Error('useCollections must be used within as CollectionsProvider');
  }

  return context;
}

export { CollectionProvider, useCollection };
