import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Settings = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8.197 6.01a2 2 0 1 0-.393 3.98 2 2 0 0 0 .393-3.98v0ZM13.013 8a4.804 4.804 0 0 1-.048.65l1.413 1.108a.337.337 0 0 1 .076.43L13.118 12.5a.338.338 0 0 1-.411.143l-1.403-.565a.504.504 0 0 0-.474.055 5.13 5.13 0 0 1-.673.392.498.498 0 0 0-.276.38l-.21 1.496a.346.346 0 0 1-.334.287H6.664a.348.348 0 0 1-.334-.278l-.21-1.494a.502.502 0 0 0-.281-.382 4.85 4.85 0 0 1-.671-.393.5.5 0 0 0-.472-.053l-1.403.565a.338.338 0 0 1-.41-.144l-1.337-2.312a.338.338 0 0 1 .076-.43l1.194-.937a.502.502 0 0 0 .188-.44 4.263 4.263 0 0 1 0-.777.5.5 0 0 0-.19-.435L1.621 6.24a.338.338 0 0 1-.074-.428L2.883 3.5a.338.338 0 0 1 .41-.143l1.404.565a.503.503 0 0 0 .474-.055 5.14 5.14 0 0 1 .673-.392.498.498 0 0 0 .276-.38L6.33 1.6a.346.346 0 0 1 .334-.286h2.673a.347.347 0 0 1 .334.277l.21 1.494a.502.502 0 0 0 .281.382c.234.113.458.244.67.393a.5.5 0 0 0 .473.053l1.403-.564a.338.338 0 0 1 .41.143l1.337 2.312a.338.338 0 0 1-.076.43l-1.194.937a.501.501 0 0 0-.19.44c.01.13.018.26.018.39Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Settings;
