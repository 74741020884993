export const colorSystem = {
  light: {
    background: '#E0E0E0',
    surface: '#F0F0F0',
    pressable: '#FFFFFF',
    typographyPrimary: '#3D3D3D',
    typographySecondary: '#858585',
    border: '#B8B8B8',
    inBrandPrimary: '#FFFFFF',
    inBrandSecondary: '#D6D6D6',
    brand: 'transparent',
    green: '#3DB520',
    red: '#D83A3A',
    blue: '#3B7CCE',
    yellow: '#F48C06',
    hover: '#00000033',
  },
  dark: {
    background: '#000000',
    surface: '#0F0F0F',
    pressable: '#1F1F1F',
    typographyPrimary: '#FFFFFF',
    typographySecondary: '#ADADAD',
    border: '#707070',
    inBrandPrimary: '#000000',
    inBrandSecondary: '#292929',
    brand: 'transparent',
    green: '#59D83A',
    red: '#F24242',
    blue: '#4794F5',
    yellow: '#EDAB00',
    hover: '#FFFFFF33',
  },
  shared: {
    black: '#000000',
    white: '#FFFFFF',
  },
};

export const colorBrand = {
  light: [
    {
      name: 'black',
      hexadecimal: '#262626',
    },
    {
      name: 'gray',
      hexadecimal: '#7F8C8D',
    },
    {
      name: 'white',
      hexadecimal: '#BEC3C7',
    },
    {
      name: 'blue',
      hexadecimal: '#364D7F',
    },
    {
      name: 'sky',
      hexadecimal: '#2580B6',
    },
    {
      name: 'powder',
      hexadecimal: '#98ABD4',
    },
    {
      name: 'navy',
      hexadecimal: '#2D3F4F',
    },
    {
      name: 'teal',
      hexadecimal: '#346272',
    },
    {
      name: 'mint',
      hexadecimal: '#1F9F89',
    },
    {
      name: 'forest',
      hexadecimal: '#2F5038',
    },
    {
      name: 'lime',
      hexadecimal: '#92B037',
    },
    {
      name: 'green',
      hexadecimal: '#31AE66',
    },
    {
      name: 'brown',
      hexadecimal: '#513B2F',
    },
    {
      name: 'coffe',
      hexadecimal: '#8F725F',
    },
    {
      name: 'sand',
      hexadecimal: '#D7C399',
    },
    {
      name: 'maroon',
      hexadecimal: '#672824',
    },
    {
      name: 'orange',
      hexadecimal: '#D25317',
    },
    {
      name: 'yellow',
      hexadecimal: '#FFA72D',
    },
    {
      name: 'plum',
      hexadecimal: '#4E2C4E',
    },
    {
      name: 'purple',
      hexadecimal: '#594AA0',
    },
    {
      name: 'magenta',
      hexadecimal: '#8B47AA',
    },
    {
      name: 'red',
      hexadecimal: '#C0392F',
    },
    {
      name: 'watermelon',
      hexadecimal: '#D9535A',
    },
    {
      name: 'pink',
      hexadecimal: '#D35D9C',
    },
  ],
  dark: [
    {
      name: 'black',
      hexadecimal: '#2B2B2B',
    },
    {
      name: 'gray',
      hexadecimal: '#95A5A5',
    },
    {
      name: 'white',
      hexadecimal: '#EDF0F1',
    },
    {
      name: 'blue',
      hexadecimal: '#4F66A0',
    },
    {
      name: 'sky',
      hexadecimal: '#2F99D8',
    },
    {
      name: 'powder',
      hexadecimal: '#B6C9EF',
    },
    {
      name: 'navy',
      hexadecimal: '#344A5B',
    },
    {
      name: 'teal',
      hexadecimal: '#3A6F80',
    },
    {
      name: 'mint',
      hexadecimal: '#25BC9D',
    },
    {
      name: 'forest',
      hexadecimal: '#365F43',
    },
    {
      name: 'lime',
      hexadecimal: '#A8C54B',
    },
    {
      name: 'green',
      hexadecimal: '#39CB77',
    },
    {
      name: 'brown',
      hexadecimal: '#5F4536',
    },
    {
      name: 'coffe',
      hexadecimal: '#A38673',
    },
    {
      name: 'sand',
      hexadecimal: '#F1DEB8',
    },
    {
      name: 'maroon',
      hexadecimal: '#7A312B',
    },
    {
      name: 'orange',
      hexadecimal: '#E87F32',
    },
    {
      name: 'yellow',
      hexadecimal: '#FFCC36',
    },
    {
      name: 'plum',
      hexadecimal: '#5E345C',
    },
    {
      name: 'purple',
      hexadecimal: '#7160C3',
    },
    {
      name: 'magenta',
      hexadecimal: '#985BB2',
    },
    {
      name: 'red',
      hexadecimal: '#E94B41',
    },
    {
      name: 'watermelon',
      hexadecimal: '#EE727B',
    },
    {
      name: 'pink',
      hexadecimal: '#F27EC0',
    },
  ],
};

export const colorProduct = [
  { id: 1, name: 'red', title: 'Vermelho', hexadecimal: '#ec292a' },
  { id: 2, name: 'green', title: 'Verde', hexadecimal: '#2dba5a' },
  { id: 3, name: 'blue', title: 'Azul', hexadecimal: '#347cc8' },
  { id: 4, name: 'yellow', title: 'Amarelo', hexadecimal: '#ffcf49' },
  { id: 5, name: 'orange', title: 'Laranja', hexadecimal: '#fa723c' },
  { id: 6, name: 'pink', title: 'Salmão', hexadecimal: '#ffb0cf' },
  { id: 7, name: 'strayYellow', title: 'Palha', hexadecimal: '#e2ac4e' },
  { id: 8, name: 'purple', title: 'Roxo', hexadecimal: '#9638d2' },
  { id: 9, name: 'brown', title: 'Marrom', hexadecimal: '#a75430' },
  { id: 10, name: 'grey', title: 'Grafite', hexadecimal: '#797f86' },
  { id: 11, name: 'white', title: 'Branco', hexadecimal: '#ffffff' },
  { id: 12, name: 'black', title: 'Preto', hexadecimal: '#222222' },
  { id: 13, name: 'oliveGreen', title: 'Verde Oliva', hexadecimal: '#a4a728' },
  { id: 14, name: 'cranberry', title: 'Cereja', hexadecimal: '#f3448e' },
  { id: 15, name: 'turquoise', title: 'Turquesa', hexadecimal: '#1acba1' },
  {
    id: 16,
    name: 'gold',
    title: 'Dourado',
    hexadecimal: ['#F2C833', '#F5E8B9', '#F2C833', '#F5E8B9'],
  },
  {
    id: 17,
    name: 'silver',
    title: 'Prateado',
    hexadecimal: ['#D7D7D7', '#F4F4F4', '#D7D7D7', '#F4F4F4'],
  },
  {
    id: 18,
    name: 'multi',
    title: 'Colorido',
    hexadecimal: ['#3678BD', '#43E8DE', '#EAE21D', '#E52121'],
  },
];
