import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useColorScheme, useWindowDimensions, Platform, Keyboard } from 'react-native';
import { useSafeAreaInsets, useSafeAreaFrame } from 'react-native-safe-area-context';

import { useAuthentication } from './authentication';
import { colorSystem, colorBrand } from '../constants/color';
import { responsiveTablet, responsiveNotebook, responsiveDesktop } from '../constants/responsive';
import { typography } from '../constants/typography';

const StyleguideContext = createContext({});

const StyleguideProvider = ({ children }) => {
  const theme = useColorScheme();
  const windowDimensions = useWindowDimensions();
  const frame = useSafeAreaFrame();
  const insets = useSafeAreaInsets();
  const { authentication } = useAuthentication();

  const responsive = useMemo(() => {
    return windowDimensions.width < responsiveTablet ? 'mobile' : 'desktop';
    // : windowDimensions.width < responsiveNotebook
    // ? 'tablet'
    // : windowDimensions.width < responsiveDesktop
    // ? 'notebook'
    // : 'desktop';
  }, [windowDimensions.width]);

  const colors = useMemo(() => {
    // initialStyleguide.colors = theme === 'light' ? LIGHT_COLORS : DARK_COLORS;
    return theme === 'light' ? colorSystem.light : colorSystem.dark;
  }, [theme]);

  const brand = useMemo(() => {
    return authentication.store.id !== undefined
      ? colorBrand[theme].filter((color) => color.name === authentication.store.color)[0]
          .hexadecimal
      : 'transparent';
    // : colorBrand[theme].filter((color) => color.name === 'mint')[0].hexadecimal;

    // theme === 'light'
    // ? 'black'
    // : 'white';
  }, [theme, authentication.store.id]);

  // const brandColors = useMemo(() => {
  //   // initialStyleguide.brandColors =
  //   // theme === 'light' ? BRAND_COLORS[0].brandColorLight : BRAND_COLORS[0].brandColorDark;

  //   return theme === 'light' ? COLORS_BRAND[0].brandColorLight : COLORS_BRAND[0].brandColorDark;
  // }, [theme]);

  // const navigatorDrawerWidth = useMemo(() => {
  //   const variants = {
  //     mobile: 0,
  //     tablet: WIDTH_NAVIGATOR_DRAWER_TABLET,
  //     web: WIDTH_NAVIGATOR_DRAWER_WEB,
  //   };
  //   return variants[responsive];
  // }, [responsive]);

  // const heightSidePage = useMemo(() => {
  //   return windowDimensions.height;
  // }, [windowDimensions.height]);

  // const widthSidePage = useMemo(() => {
  //   const variants = {
  //     mobile: windowDimensions.width,
  //     tablet: 0.33 * (windowDimensions.width - navigatorDrawerWidth),
  //     web: 0.33 * (windowDimensions.width - navigatorDrawerWidth),
  //   };
  //   return variants[responsive];
  // }, [responsive, windowDimensions.width, navigatorDrawerWidth]);

  // const heightModal = useMemo(() => {
  //   return 0.9 * windowDimensions.height;
  // }, [windowDimensions.height]);

  // const widthModal = useMemo(() => {
  //   const variants = {
  //     mobile: windowDimensions.width,
  //     tablet: 0.33 * (windowDimensions.width - navigatorDrawerWidth),
  //     web: 0.33 * (windowDimensions.width - navigatorDrawerWidth),
  //   };
  //   return variants[responsive];
  // }, [responsive, windowDimensions.width, navigatorDrawerWidth]);

  // const heightPage = useMemo(() => {
  //   return windowDimensions.height;
  // }, [windowDimensions.height]);

  // const widthPage = useMemo(() => {
  //   const variants = {
  //     mobile: windowDimensions.width,
  //     tablet: windowDimensions.width - widthSidePage - navigatorDrawerWidth,
  //     web: windowDimensions.width - widthSidePage - navigatorDrawerWidth,
  //   };
  //   return variants[responsive];
  // }, [responsive, widthSidePage, windowDimensions.width, navigatorDrawerWidth]);

  // useEffect(() => {
  //   const paddingHorizontal = responsive === 'mobile' ? 16 : responsive === 'tablet' ? 32 : 48;

  //   const paddingHorizontalSidePage =
  //     responsive === 'mobile' ? 16 : responsive === 'tablet' ? 24 : 32;

  //   const widthNavigatorNumber =
  //     responsive === 'mobile'
  //       ? 0
  //       : responsive === 'tablet'
  //       ? WIDTH_NAVIGATOR_DRAWER_CLOSED
  //       : WIDTH_NAVIGATOR_DRAWER_OPEN;

  //   const widthSidePage =
  //     responsive === 'mobile'
  //       ? windowDimensions.width
  //       : 0.33 * (windowDimensions.width - widthNavigatorNumber);

  //   setStyleguide((prevState) => ({
  //     ...prevState,
  //     responsive,
  //     windowDimensions,
  //     paddingHorizontal,
  //     paddingHorizontalSidePage,
  //     widthNavigatorNumber,
  //     widthSidePage,
  //   }));
  // }, [windowDimensions]);

  const initialStyleguide = {
    application: '',
    // keyboardIsOpened,
    platform: Platform,
    theme,
    colors,
    // brandColors,
    windowDimensions,
    frame,
    insets,
    responsive,
    typography,
    brand,
    // navigatorDrawer: {
    //   width: navigatorDrawerWidth,
    // },
    // page: {
    //   width: widthPage,
    //   height: heightPage,
    // },
    // sidePage: {
    //   height: heightSidePage,
    //   width: widthSidePage,
    // },
    // modal: {
    //   height: heightModal,
    //   width: widthModal,
    // },
  };

  const [styleguide, setStyleguide] = useState(initialStyleguide);
  const [keyboardIsOpened, setKeyboardIsOpened] = useState(false);

  useEffect(() => {
    const keyboardStringShow =
      styleguide.platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow';
    const keyboardStringHide =
      styleguide.platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide';
    const keyboardWillShow = Keyboard.addListener(keyboardStringShow, () => {
      setKeyboardIsOpened(true);
    });
    const keyboardWillHide = Keyboard.addListener(keyboardStringHide, () => {
      setKeyboardIsOpened(false);
    });

    return () => {
      keyboardWillShow.remove();
      keyboardWillHide.remove();
    };
  }, []);

  // useEffect(() => {
  //   setStyleguide((prevState) => ({
  //     ...prevState,
  //     keyboardIsOpened,
  //   }));
  // }, [keyboardIsOpened]);

  useEffect(() => {
    (async () => {
      try {
        const themeStorage = await AsyncStorage.getItem('theme');
        if (themeStorage !== null) {
          setStyleguide((prevState) => ({
            ...prevState,
            theme: themeStorage,
          }));
        }
      } catch (error) {
        console.error('error useEffect styleguide', error);
      } finally {
        return;
      }
    })();
  }, []);

  useEffect(() => {
    setStyleguide((prevState) => ({
      ...prevState,
      theme,
      colors,
      windowDimensions,
      frame,
      insets,
      responsive,
      brand,
    }));
  }, [theme, colors, windowDimensions, frame, insets, responsive, brand]);

  // useEffect(() => {
  //   AsyncStorage.setItem('theme', theme);
  // }, [theme]);

  // useEffect(() => {
  //   setStyleguide((prevState) => ({
  //     ...prevState,
  //     navigatorDrawer: {
  //       ...prevState.navigatorDrawer,
  //       width: navigatorDrawerWidth,
  //     },
  //   }));
  // }, [navigatorDrawerWidth]);

  // useEffect(() => {
  //   setStyleguide((prevState) => ({
  //     ...prevState,
  //     page: {
  //       ...prevState.page,
  //       height: heightPage,
  //     },
  //   }));
  // }, [heightPage]);

  // useEffect(() => {
  //   setStyleguide((prevState) => ({
  //     ...prevState,
  //     page: {
  //       ...prevState.page,
  //       width: widthPage,
  //     },
  //   }));
  // }, [widthPage]);

  // useEffect(() => {
  //   setStyleguide((prevState) => ({
  //     ...prevState,
  //     sidePage: {
  //       ...prevState.sidePage,
  //       height: heightSidePage,
  //     },
  //   }));
  // }, [heightSidePage]);

  // useEffect(() => {
  //   setStyleguide((prevState) => ({
  //     ...prevState,
  //     sidePage: {
  //       ...prevState.sidePage,
  //       width: widthSidePage,
  //     },
  //   }));
  // }, [widthSidePage]);

  // useEffect(() => {
  //   setStyleguide((prevState) => ({
  //     ...prevState,
  //     modal: {
  //       ...prevState.modal,
  //       height: heightModal,
  //     },
  //   }));
  // }, [heightModal]);

  // useEffect(() => {
  //   setStyleguide((prevState) => ({
  //     ...prevState,
  //     modal: {
  //       ...prevState.modal,
  //       width: widthModal,
  //     },
  //   }));
  // }, [widthModal]);

  return (
    <StyleguideContext.Provider
      value={{
        keyboardIsOpened,
        styleguide,
        setStyleguide,
      }}>
      {children}
    </StyleguideContext.Provider>
  );
};

function useStyleguide() {
  const context = useContext(StyleguideContext);

  if (!context) {
    throw new Error('useStyleguide must be used within as StyleguideProvider');
  }

  return context;
}

export { StyleguideProvider, useStyleguide };
