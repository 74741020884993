import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Trash = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M4 4.5h8m-8 0v9h8v-9m-8 0H3m9 0h1m-6.5-2h3M6 6.5v5m4-5v5M8 6.274v5.475"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Trash;
