import * as React from "react";
import Svg, { Path } from "react-native-svg";

const StorageAdd = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m6.328 11.494-.341-.94a1 1 0 0 0-.362.23l.703.71Zm0 14.284h-1a1 1 0 0 0 .659.94l.341-.94Zm9.607 3.494-.342.94a1 1 0 0 0 .671.004l-.33-.945Zm10-3.494.33.944a1 1 0 0 0 .67-.944h-1Zm0-14.284.83-.56a1 1 0 0 0-.5-.384l-.33.944ZM15.935 8l.33-.944a1 1 0 0 0-.672.004l.341.94Zm3.398 12.156-.829.56a1 1 0 0 0 1.17.38l-.341-.94Zm10-3.628.342.94a1 1 0 0 0 .488-1.5l-.83.56Zm-27.37-2.116a1 1 0 0 0 1.408 1.42l-1.408-1.42Zm24.972 3.348a1 1 0 0 0-2 0h2ZM5.987 26.718l9.606 3.493.683-1.88-9.606-3.493-.683 1.88Zm10.277 3.498 10.001-3.494-.66-1.888-10 3.494.66 1.888ZM26.265 10.55l-10-3.494-.66 1.888 10 3.494.66-1.888ZM15.593 7.06l-9.606 3.494.683 1.88 9.606-3.494-.683-1.88Zm10.001 3.494-10 3.628.682 1.88 10-3.628-.682-1.88Zm-9.306 3.632-9.606-3.628-.707 1.871 9.606 3.628.707-1.87Zm-1.354.936v14.15h2v-14.15h-2Zm.172.56 3.398 5.033 1.658-1.119-3.399-5.034-1.657 1.12Zm4.568 5.414 10-3.628-.681-1.88-10.001 3.628.682 1.88Zm10.489-5.128-3.399-5.034-1.657 1.12 3.398 5.033 1.658-1.119ZM5.625 10.783l-3.662 3.629 1.408 1.42 3.661-3.628-1.407-1.42Zm-.297.71v2.472h2v-2.471h-2Zm0 2.472v11.813h2V13.965h-2Zm21.607 11.813V17.76h-2v8.018h2Z"
      fill={color}
    />
    <Path
      d="M24 5.492h2.667m2.666 0h-2.666m0 0V8.34m0-2.848V3"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default StorageAdd;
