import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Flashlight = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.006 7.424c-.74.595-1.673.676-2.377.73-.382.03-.856.303-1.128.574l-4.944 4.943c-.51.51-1.226.392-1.735-.116l-.378-.378c-.51-.51-.623-1.227-.115-1.734l4.944-4.94c.271-.272.547-.747.575-1.128.051-.71.094-1.611.727-2.382m4.431 4.431c.072-.057.141-.12.209-.187l.37-.37c.409-.409.595-.891.186-1.3l-3.34-3.338c-.408-.409-.89-.223-1.298.186l-.371.373c-.068.067-.13.136-.187.205m4.431 4.431-4.431-4.43"
      stroke={color}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Flashlight;
