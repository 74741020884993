import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Cash = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M2.455 15h15.09c.32 0 .58-.28.58-.625v-8.75c0-.345-.26-.625-.58-.625H2.455c-.32 0-.58.28-.58.625v8.75c0 .345.26.625.58.625Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinejoin="round"
    />
    <Path
      d="M10 13.125c1.644 0 2.977-1.4 2.977-3.125 0-1.726-1.333-3.125-2.976-3.125-1.644 0-2.977 1.4-2.977 3.125 0 1.726 1.333 3.125 2.977 3.125ZM18.125 8.125a2.8 2.8 0 0 1-2.052-.915 3.251 3.251 0 0 1-.85-2.21M1.875 8.125c.381 0 .758-.08 1.11-.238.353-.157.672-.387.942-.677.27-.29.483-.635.629-1.014.146-.38.22-.786.22-1.196m13.349 6.875a2.8 2.8 0 0 0-2.052.915 3.25 3.25 0 0 0-.85 2.21M1.875 11.875c.381 0 .758.08 1.11.238.353.157.672.387.942.677.27.29.483.635.629 1.014.146.38.22.786.22 1.196"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Cash;
