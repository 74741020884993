import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Copy = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12.719 4H5.78C4.797 4 4 4.797 4 5.781v6.938c0 .984.797 1.781 1.781 1.781h6.938c.984 0 1.781-.797 1.781-1.781V5.78C14.5 4.797 13.703 4 12.719 4Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <Path
      d="M11.984 4 12 3.25a1.755 1.755 0 0 0-1.75-1.75H3.5a2.006 2.006 0 0 0-2 2v6.75A1.755 1.755 0 0 0 3.25 12H4"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Copy;
