import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Help = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={8} cy={8} r={6.5} stroke={color} />
    <Circle cx={8} cy={8} r={2.167} stroke={color} />
    <Path
      d="M10 6.4v3.2l4 .4V6l-4 .4ZM6 2l.4 4h3.2l.4-4H6ZM6.4 10 6 14h4l-.4-4H6.4ZM2 6v4l4-.4V6.4L2 6Z"
      fill={color}
    />
  </Svg>
);

export default Help;
