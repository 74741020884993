import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Bell = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12.373 15.125v.625a2.501 2.501 0 0 1-2.498 2.5 2.497 2.497 0 0 1-2.497-2.5v-.625m9.198-1.272c-1.002-1.228-1.71-1.853-1.71-5.238 0-3.1-1.581-4.204-2.883-4.74a.696.696 0 0 1-.388-.412C11.366 2.685 10.726 2 9.875 2c-.85 0-1.49.686-1.717 1.464a.689.689 0 0 1-.388.411c-1.303.537-2.883 1.638-2.883 4.74-.002 3.385-.71 4.01-1.712 5.238-.415.508-.052 1.272.675 1.272h12.055c.723 0 1.084-.766.671-1.272Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Bell;
