import * as React from "react";
import Svg, { Path } from "react-native-svg";

const QrCode = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 11.25H1.5V1.5h9.75v9.75ZM3.562 9.187h5.626V3.564H3.561v5.624ZM4.5 15.75h3.75v3.75H4.5v-3.75Zm8.25-4.5h9.75V1.5h-9.75v9.75Zm7.688-2.063h-5.625V3.564h5.624v5.624ZM8.25 4.5H4.5v3.75h3.75V4.5ZM19.5 15.75h-3.75v-3h-3v3h3v3.75h3.75v3h3v-3h-3v-3.75Zm3-3h-2.25V15h2.25v-2.25Zm-7.5 7.5h-2.25v2.25H15v-2.25ZM19.5 4.5h-3.75v3.75h3.75V4.5Zm-18 18h9.75v-9.75H1.5v9.75Zm7.688-2.063H3.561v-5.625h5.626v5.626Z"
      fill={color}
    />
  </Svg>
);

export default QrCode;
