import * as React from "react";
import Svg, { Path } from "react-native-svg";

const CalendarDay = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18 3.75h1.5A2.25 2.25 0 0 1 21.75 6v1.5M18 3.75v-1.5m0 1.5H6m0 0H4.5A2.25 2.25 0 0 0 2.25 6v1.5M6 3.75v-1.5M2.25 7.5v12a2.25 2.25 0 0 0 2.25 2.25h15a2.25 2.25 0 0 0 2.25-2.25v-12m-19.5 0h19.5"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12 18v-7.5L10.5 12m1.5 6h1.5M12 18h-1.5"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default CalendarDay;
