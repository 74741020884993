import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Calendar = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M24 5h2a3 3 0 0 1 3 3v2m-5-5V3m0 2H8m0 0H6a3 3 0 0 0-3 3v2m5-5V3m-5 7v16a3 3 0 0 0 3 3h20a3 3 0 0 0 3-3V10M3 10h26"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M19 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM24 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM19 21a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM24 21a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM9 21a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM14 21a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM9 26a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM14 26a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM19 26a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill={color}
    />
  </Svg>
);

export default Calendar;
