import * as React from "react";
import Svg, { Path } from "react-native-svg";

const LockOpen = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10.5 3c0-.5-1-1.5-2.5-1.5s-2.5 1-2.5 2V6m0 0h-1A1.5 1.5 0 0 0 3 7.5V13a1.5 1.5 0 0 0 1.5 1.5h7A1.5 1.5 0 0 0 13 13V7.5A1.5 1.5 0 0 0 11.5 6h-6Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LockOpen;
