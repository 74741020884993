import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Moon = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10 8.5c0-1.914.282-3.85 1-5.5-4.777 2.08-8 6.957-8 12.5C3 22.956 9.044 29 16.5 29c5.543 0 10.42-3.223 12.5-8-1.65.718-3.586 1-5.5 1C16.044 22 10 15.956 10 8.5Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Moon;
