import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Edit = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12.105 18h6.737M15.329 5.827a1.565 1.565 0 0 1 2.213 2.213L8.32 17.262 5.369 18l.737-2.951 9.223-9.222Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Edit;
