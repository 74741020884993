import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
const Youtube = ({ color }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <Path
      fill={color}
      fillRule="evenodd"
      d="M5.092 5.429c-1.51.118-2.651 1.346-2.725 2.858-.06 1.247-.117 2.68-.117 3.67 0 1.002.058 2.457.12 3.713.072 1.494 1.187 2.713 2.676 2.85 1.648.153 4.032.306 6.954.306 2.914 0 5.294-.153 6.941-.304 1.495-.138 2.612-1.365 2.684-2.864.063-1.323.125-2.836.125-3.7 0-.856-.06-2.346-.123-3.658-.073-1.518-1.216-2.754-2.731-2.872A88.93 88.93 0 0 0 12 5.176a88.88 88.88 0 0 0-6.908.253Zm4.958 3.634 5.027 2.895-5.027 2.894V9.063Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default Youtube;
