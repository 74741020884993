import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Cash = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3.929 24H28.07c.513 0 .929-.448.929-1V9c0-.552-.416-1-.929-1H3.93C3.416 8 3 8.448 3 9v14c0 .552.416 1 .929 1Z"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <Path
      d="M16.001 21c2.63 0 4.762-2.239 4.762-5s-2.132-5-4.762-5c-2.63 0-4.762 2.239-4.762 5s2.132 5 4.762 5ZM29 13c-1.231 0-2.412-.527-3.283-1.464-.87-.938-1.36-2.21-1.36-3.536M3 13c.61 0 1.213-.13 1.777-.38a4.637 4.637 0 0 0 1.506-1.084 5.042 5.042 0 0 0 1.006-1.623A5.336 5.336 0 0 0 7.643 8M29 19c-1.231 0-2.412.527-3.283 1.465-.87.937-1.36 2.209-1.36 3.535M3 19c.61 0 1.213.13 1.777.38.563.252 1.075.62 1.506 1.085a5.042 5.042 0 0 1 1.006 1.622c.234.606.354 1.256.354 1.913"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Cash;
