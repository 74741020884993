import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Helpball = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={12} cy={18} r={0.75} stroke={color} strokeWidth={1.5} />
    <Path
      d="M12.75 14.25c-3 0-1.064-1.936 1.5-4.5 1.258-1.258 1.5-4.5-2.25-4.5-1.5 0-3 1.125-3 2.25"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <Circle cx={12} cy={12} r={9.75} stroke={color} strokeWidth={1.5} />
  </Svg>
);

export default Helpball;
