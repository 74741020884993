import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const User = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.315 5.831c-.148 1.882-1.666 3.331-3.321 3.331-1.655 0-3.176-1.449-3.32-3.33C6.521 3.873 8 2.5 9.993 2.5c1.994 0 3.471 1.41 3.32 3.331Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10 11.25c-2.829 0-5.549 1.702-6.23 5.015-.09.439.137.872.508.872h11.444c.372 0 .598-.433.509-.872-.682-3.367-3.402-5.015-6.231-5.015Z"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default User;
