import React, { forwardRef } from 'react';
import { View } from 'react-native';

// import { Container } from './styles';

const Toast = () => {
  return <View />;
};

export default forwardRef(Toast);
