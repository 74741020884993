import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Atendimento = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M9.208 11.042c-.093 1.294-1.053 2.291-2.1 2.291-1.048 0-2.01-.997-2.101-2.291-.096-1.347.839-2.292 2.1-2.292 1.262 0 2.197.97 2.101 2.292ZM7.083 14c-2.074 0-4.069 1.03-4.568 3.037-.067.265.1.528.373.528h8.391c.273 0 .438-.263.373-.528C11.152 14.998 9.158 14 7.083 14ZM9.606 3.791a1.102 1.102 0 0 1 1.042-.967l5.698-.27A1.102 1.102 0 0 1 17.5 3.657v3.367c0 .608-.494 1.102-1.102 1.102h-5.745c-.152 0-.303.032-.443.093l-.855.376a.22.22 0 0 1-.308-.228l.559-4.575Z"
      fill={color}
    />
  </Svg>
);

export default Atendimento;
