import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const Save = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3.75 6A2.25 2.25 0 0 1 6 3.75H16.19l1.78 1.78 1.621 1.622c.422.422.659.994.659 1.59V18A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6Z"
      stroke={color}
      strokeWidth={1.5}
    />
    <Circle cx={12} cy={14} r={2.25} stroke={color} strokeWidth={1.5} />
  </Svg>
);

export default Save;
