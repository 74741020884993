import * as React from "react";
import Svg, { Path } from "react-native-svg";

const AddUser = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M7.209 4.642a2.767 2.767 0 1 1 5.534 0 2.767 2.767 0 0 1-5.534 0ZM3.75 14.375v-.059c0-2.669 2.095-4.832 4.68-4.832h3.09c2.586 0 4.681 2.163 4.681 4.832v.059"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10 13.125v5m2.5-2.5h-5"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default AddUser;
