import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Time = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10 2.5c-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5Z"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
    />
    <Path
      d="M10 5.625v4.863l2.5 1.387"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Time;
