import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Phone = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11.75 2h-7.5v12h7.5V2ZM7.996 11.75h.008"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Phone;
