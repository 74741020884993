import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Username = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10 17.5a7.5 7.5 0 1 1 7.5-7.5c0 2.813-1.875 4.688-3.75 4.688-3.75 0-3.004-6.024-.938-7.5"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.237 10s-.66 2.813-2.535 2.813c-3.038 0-1.39-5.626.97-5.626 1.756 0 1.565 2.813 1.565 2.813Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Username;
