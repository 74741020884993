import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Star = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15 6.5H9.625L8 1.5l-1.625 5H1l4.375 3-1.688 5L8 11.375l4.313 3.125-1.688-5L15 6.5Z"
      stroke={color}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Star;
