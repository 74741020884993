import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Camera = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8 10h.75c.539 0 .99-.298 1.341-.707l1.664-2.63c.35-.408.831-.663 1.37-.663h5.75c.539 0 1.02.255 1.37.664l1.664 2.629c.35.409.863.707 1.404.707H27a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V12a2 2 0 0 1 2-2h1m2 0V8H6v2m2 0H6"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16 22a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Camera;
