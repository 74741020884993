import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Check = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3.648 7.956a.5.5 0 1 0-.63.778l.63-.778Zm3.378 3.377-.314.39a.5.5 0 0 0 .696-.067l-.382-.323ZM13.05 4.99a.5.5 0 1 0-.764-.646l.764.646ZM3.019 8.734l3.693 2.988.629-.777-3.693-2.989-.63.778Zm4.389 2.922 5.64-6.666-.763-.646-5.64 6.667.763.645Z"
      fill={color}
    />
  </Svg>
);

export default Check;
