import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Heart = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M4.971 2.667C7 2.667 8 4.606 8 4.606s1-1.94 3.029-1.94a2.974 2.974 0 0 1 2.97 2.934c.035 3.313-2.71 5.67-5.718 7.65a.511.511 0 0 1-.562 0C4.71 11.27 1.966 8.912 2 5.6a2.974 2.974 0 0 1 2.971-2.933v0Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Heart;
