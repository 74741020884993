import * as React from "react";
import Svg, { Path } from "react-native-svg";

const BarCode = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3.125 3.125v8.75M3.125 13.75v3.125"
      stroke={color}
      strokeWidth={1.25}
    />
    <Path
      d="M5.625 3.125v8.75M5.625 13.75v3.125"
      stroke={color}
      strokeWidth={2.5}
    />
    <Path
      d="M8.75 3.125v8.75M8.75 13.75v3.125"
      stroke={color}
      strokeWidth={1.25}
    />
    <Path
      d="M11.875 3.125v8.75M11.875 13.75v3.125"
      stroke={color}
      strokeWidth={1.875}
    />
    <Path
      d="M14.375 3.125v8.75M14.375 13.75v3.125"
      stroke={color}
      strokeWidth={0.938}
    />
    <Path
      d="M16.563 3.125v8.75M16.563 13.75v3.125"
      stroke={color}
      strokeWidth={1.875}
    />
    <Path
      stroke="#E65C5C"
      strokeWidth={1.25}
      strokeLinecap="round"
      d="M1.875 13.75h16.25"
    />
  </Svg>
);

export default BarCode;
