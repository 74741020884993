import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Heart = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M9.943 5.333C14 5.333 16 9.213 16 9.213s2-3.88 6.057-3.88c3.298 0 5.909 2.676 5.942 5.868.07 6.626-5.42 11.338-11.436 15.298a1.022 1.022 0 0 1-1.125 0C9.421 22.539 3.932 17.827 4 11.2c.033-3.192 2.644-5.868 5.942-5.868v0Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Heart;
