import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Store = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12.5 13.5v-6m-9 0v6m-1 0h11m-6.976 0V10c0-.122.05-.24.14-.326A.484.484 0 0 1 7 9.538h2c.126 0 .247.05.337.136.089.086.14.204.14.326v3.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2.5 6.25v-.22c0-.13.033-.257.097-.373L4.08 2.961A.93.93 0 0 1 4.9 2.5h6.2a.93.93 0 0 1 .82.46l1.483 2.697a.771.771 0 0 1 .097.373v.22m-11 0Zm0 0c0 .69.616 1.25 1.375 1.25.76 0 1.375-.56 1.375-1.25 0 .69.616 1.25 1.375 1.25C7.385 7.5 8 6.94 8 6.25c0 .69.616 1.25 1.375 1.25.76 0 1.375-.56 1.375-1.25 0 .69.616 1.25 1.375 1.25.76 0 1.375-.56 1.375-1.25m0 0Z"
      stroke={color}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Store;
