import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Filter = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M9.167 10.917V8l3.5-4.667H3.333L6.833 8v4.667"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Filter;
