import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Apple = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10.91 4.277c-1.26 0-1.792.6-2.67.6-.9 0-1.585-.596-2.677-.596-1.069 0-2.208.652-2.932 1.764-1.016 1.567-.844 4.52.802 7.034.589.9 1.375 1.91 2.406 1.921h.019c.896 0 1.162-.587 2.396-.594h.018c1.215 0 1.459.59 2.351.59h.02c1.03-.01 1.859-1.129 2.447-2.026.424-.645.582-.969.907-1.698-2.381-.904-2.764-4.279-.41-5.573-.718-.9-1.728-1.421-2.68-1.421l.003-.001Z"
      fill={color}
    />
    <Path
      d="M10.633 1c-.75.05-1.625.528-2.138 1.152-.464.565-.847 1.403-.697 2.216h.06c.799 0 1.616-.481 2.094-1.098.46-.586.808-1.417.68-2.27Z"
      fill={color}
    />
  </Svg>
);

export default Apple;
