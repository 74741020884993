import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Print = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15 14.375h.938a1.567 1.567 0 0 0 1.562-1.563v-6.25A1.567 1.567 0 0 0 15.937 5H4.063A1.567 1.567 0 0 0 2.5 6.563v6.25a1.567 1.567 0 0 0 1.563 1.562H5"
      stroke={color}
      strokeWidth={1.25}
      strokeLinejoin="round"
    />
    <Path
      d="M14.05 9.375h-8.1a.95.95 0 0 0-.95.95v6.225c0 .525.425.95.95.95h8.1a.95.95 0 0 0 .95-.95v-6.225a.95.95 0 0 0-.95-.95ZM15 5v-.938A1.567 1.567 0 0 0 13.437 2.5H6.564A1.567 1.567 0 0 0 5 4.063V5"
      stroke={color}
      strokeWidth={1.25}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Print;
