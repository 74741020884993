import * as React from "react";
import Svg, { Path } from "react-native-svg";

const CalendarDay = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12 2.5h1A1.5 1.5 0 0 1 14.5 4v1M12 2.5v-1m0 1H4m0 0H3A1.5 1.5 0 0 0 1.5 4v1M4 2.5v-1M1.5 5v8A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V5m-13 0h13"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8 12V7L7 8m1 4h1m-1 0H7"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default CalendarDay;
