import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Print = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M24 23h1.5a2.508 2.508 0 0 0 2.5-2.5v-10A2.508 2.508 0 0 0 25.5 8h-19A2.508 2.508 0 0 0 4 10.5v10A2.508 2.508 0 0 0 6.5 23H8"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <Path
      d="M22.48 15H9.52C8.68 15 8 15.68 8 16.52v9.96c0 .84.68 1.52 1.52 1.52h12.96c.84 0 1.52-.68 1.52-1.52v-9.96c0-.84-.68-1.52-1.52-1.52ZM24 8V6.5A2.508 2.508 0 0 0 21.5 4h-11A2.508 2.508 0 0 0 8 6.5V8"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Print;
