import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Hammer = ({ color }) => (
  <Svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.004 12.178a1.155 1.155 0 0 0-.191-.256l-.86-.848a.982.982 0 0 0-1.399-.014c-.298.293-.843.829-1.828 1.801-1.882 1.862-5.378 4.984-6.958 6.315a.75.75 0 0 0-.051 1.104l1.828 1.848a.754.754 0 0 0 1.11-.042c1.37-1.61 4.513-5.11 6.374-6.948.956-.94 1.492-1.48 1.795-1.778a1.02 1.02 0 0 0 .18-1.182v0Zm9.423-2.156-1.609-1.594a.255.255 0 0 0-.187-.074.262.262 0 0 0-.188.074.535.535 0 0 1-.448.153c-.21-.022-.433-.088-.577-.227-.329-.322.05-.955-.238-1.36-.33-.443-.691-.861-1.082-1.252-.33-.328-1.632-1.57-3.823-2.463a5.803 5.803 0 0 0-2.203-.433c-1.235 0-2.185.552-2.531.868-.276.25-.563.645-.563.645.166-.06.335-.11.507-.15.359-.076.726-.1 1.091-.07.73.061 1.609.403 2.08.774.76.609 1.087 1.424 1.138 2.476.037.782-.714 1.77-1.427 2.575a.368.368 0 0 0 .019.508l.996.995a.375.375 0 0 0 .522.005c.653-.633 1.457-1.335 1.913-1.615.456-.281.824-.36 1-.38.348-.033.697.042 1 .217.052.03.101.068.144.111a.79.79 0 0 1-.024 1.113l-.094.089a.259.259 0 0 0 0 .367l1.609 1.594a.258.258 0 0 0 .187.074.265.265 0 0 0 .188-.074l2.6-2.571a.273.273 0 0 0 0-.375v0Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Hammer;
