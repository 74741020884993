import * as React from "react";
import Svg, { Path } from "react-native-svg";

const QrCode = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.375 9.375H1.25V1.25h8.125v8.125ZM2.969 7.656h4.687V2.97H2.97v4.687Zm.781 5.469h3.125v3.125H3.75v-3.125Zm6.875-3.75h8.125V1.25h-8.125v8.125Zm6.406-1.719h-4.687V2.97h4.687v4.687ZM6.875 3.75H3.75v3.125h3.125V3.75Zm9.375 9.375h-3.125v-2.5h-2.5v2.5h2.5v3.125h3.125v2.5h2.5v-2.5h-2.5v-3.125Zm2.5-2.5h-1.875V12.5h1.875v-1.875Zm-6.25 6.25h-1.875v1.875H12.5v-1.875ZM16.25 3.75h-3.125v3.125h3.125V3.75Zm-15 15h8.125v-8.125H1.25v8.125Zm6.406-1.719H2.97v-4.687h4.687v4.687Z"
      fill={color}
    />
  </Svg>
);

export default QrCode;
