import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Sun = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12.75 2.25a.75.75 0 0 0-1.5 0h1.5Zm-1.5 2.25a.75.75 0 0 0 1.5 0h-1.5Zm1.5 15a.75.75 0 0 0-1.5 0h1.5Zm-1.5 2.25a.75.75 0 0 0 1.5 0h-1.5Zm8.175-16.114a.75.75 0 1 0-1.061-1.06l1.06 1.06Zm-2.652.53a.75.75 0 1 0 1.06 1.06l-1.06-1.06ZM7.227 17.834a.75.75 0 0 0-1.06-1.06l1.06 1.06Zm-2.652.53a.75.75 0 0 0 1.061 1.06l-1.06-1.06ZM21.75 12.75a.75.75 0 0 0 0-1.5v1.5Zm-2.25-1.5a.75.75 0 0 0 0 1.5v-1.5Zm-15 1.5a.75.75 0 0 0 0-1.5v1.5Zm-2.25-1.5a.75.75 0 0 0 0 1.5v-1.5Zm16.114 8.175a.75.75 0 0 0 1.06-1.061l-1.06 1.06Zm-.53-2.652a.75.75 0 0 0-1.06 1.06l1.06-1.06ZM6.166 7.227a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm-.53-2.652a.75.75 0 0 0-1.06 1.061l1.06-1.06ZM11.25 2.25V4.5h1.5V2.25h-1.5Zm0 17.25v2.25h1.5V19.5h-1.5Zm7.114-14.925-1.59 1.591 1.06 1.06 1.59-1.59-1.06-1.06ZM6.166 16.773l-1.59 1.591 1.06 1.06 1.59-1.59-1.06-1.06ZM21.75 11.25H19.5v1.5h2.25v-1.5Zm-17.25 0H2.25v1.5H4.5v-1.5Zm14.925 7.114-1.591-1.59-1.06 1.06 1.59 1.59 1.06-1.06ZM7.227 6.166l-1.591-1.59-1.06 1.06 1.59 1.59 1.06-1.06Z"
      fill={color}
    />
    <Path
      d="M12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default Sun;
