import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Shuffle = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3 16.5h3.993a3.75 3.75 0 0 0 3.12-1.67L12 12m6.75 2.25L21 16.5l-2.25 2.25v-4.5Zm0-9L21 7.5l-2.25 2.25v-4.5Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M3 7.5h3.993a3.75 3.75 0 0 1 3.12 1.67l3.774 5.66a3.75 3.75 0 0 0 3.12 1.67H19.5m0-9h-2.493a3.75 3.75 0 0 0-3.12 1.67l-.387.58"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Shuffle;
