import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Scale = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11.5 1.5h-7a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h7a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <Path
      d="M12.155 4.737A6.1 6.1 0 0 0 8 3.13a6.1 6.1 0 0 0-4.154 1.607 1.083 1.083 0 0 0-.096 1.502l1.042 1.225a.815.815 0 0 0 1.05.163C6.339 7.32 7.12 6.951 8 6.951c.88 0 1.662.369 2.16.676a.815.815 0 0 0 1.05-.163l1.04-1.225a1.083 1.083 0 0 0-.096-1.502Z"
      stroke={color}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Scale;
