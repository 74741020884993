import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Fit = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3.063 9.872A7.665 7.665 0 0 1 2.5 6.907c.021-2.058 1.654-3.782 3.714-3.782 2.536 0 3.786 2.5 3.786 2.5s1.25-2.5 3.786-2.5c2.061 0 3.693 1.724 3.714 3.782.043 4.27-3.388 7.307-7.148 9.86a.625.625 0 0 1-.703 0C7.93 15.6 6.28 14.333 5 12.893"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M1.875 9.375a.625.625 0 1 0 0 1.25v-1.25ZM5 10v.625a.625.625 0 0 0 .559-.345L5 10Zm1.875-3.75.599-.18a.625.625 0 0 0-1.158-.1l.559.28ZM8.75 12.5l-.599.18a.625.625 0 0 0 1.158.1l-.559-.28Zm1.875-3.75.559-.28a.625.625 0 0 0-1.118 0l.559.28Zm1.25 2.5-.559.28a.625.625 0 0 0 .559.345v-.625Zm1.875.625a.625.625 0 1 0 0-1.25v1.25Zm-11.875-1.25H5v-1.25H1.875v1.25Zm3.684-.345 1.875-3.75-1.118-.56-1.875 3.75 1.118.56Zm.717-3.85 1.875 6.25 1.198-.36-1.875-6.25-1.198.36Zm3.033 6.35 1.875-3.75-1.118-.56-1.875 3.75 1.118.56Zm.757-3.75 1.25 2.5 1.118-.56-1.25-2.5-1.118.56Zm1.809 2.845h1.875v-1.25h-1.875v1.25Z"
      fill={color}
    />
  </Svg>
);

export default Fit;
