import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Cart = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M6.5 14.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM11.5 14.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM2 2h2l.329 2.5M12 12H5.316L4.92 9m0 0H13l1-4.5H4.329M4.92 9l-.592-4.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Cart;
