import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Live = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14.094 12.5c0 1.11-.922 2.036-2.092 2.036s-2.092-.925-2.092-2.035.923-2.036 2.092-2.036c1.17 0 2.092.925 2.092 2.036Z"
      stroke={color}
      strokeWidth={1.5}
    />
    <Path
      d="M15.79 16.214c1.894-2.322 1.894-5.107 0-7.429M8.21 16.214c-1.894-2.322-1.894-5.107 0-7.429M18.16 19c2.145-2.167 5.148-7.8 0-13M5.844 19c-2.145-2.167-5.148-7.8 0-13"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

export default Live;
