import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Mail = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M4.289 6.775a1.207 1.207 0 0 0 .13 1.698l10.8 9.29c.45.387 1.113.387 1.562 0l10.8-9.29a1.207 1.207 0 0 0 .13-1.698M5.2 6.355c-.663 0-1.2.538-1.2 1.204v16.258c0 .665.537 1.204 1.2 1.204h21.6c.663 0 1.2-.54 1.2-1.204V7.559c0-.666-.537-1.205-1.2-1.205H5.2Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Mail;
