import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Cost = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10.332 15.003a3.192 3.192 0 0 0 1.013-.248 2.42 2.42 0 0 0 .734-.515c.204-.207.355-.447.454-.72.105-.278.157-.583.157-.914a1.637 1.637 0 0 0-.603-1.314 2.506 2.506 0 0 0-.655-.4 8.495 8.495 0 0 0-.803-.31l-.149 2.21-.074 1.106-.074 1.105ZM10.043 4.74a3.326 3.326 0 0 0-.987.23 2.21 2.21 0 0 0-.681.445c-.18.177-.315.378-.402.603-.087.22-.13.45-.13.693 0 .284.049.533.148.746.099.207.235.39.41.55.175.16.379.3.612.418.233.118.486.225.76.32l.067-1.002.068-1 .068-1.002.067-1.001Zm.69 4.315c.42.136.833.281 1.24.435.409.154.775.352 1.101.595.327.243.589.548.787.915.203.36.305.814.305 1.358 0 .533-.087 1.033-.262 1.5-.174.469-.43.88-.768 1.235a3.88 3.88 0 0 1-1.232.87c-.483.22-1.037.35-1.66.391l-.105 1.554a.438.438 0 0 1-.13.293.398.398 0 0 1-.306.133h-.577l.131-1.998a5.658 5.658 0 0 1-1.93-.533 5.323 5.323 0 0 1-1.494-1.1l.472-.729a.57.57 0 0 1 .174-.16.438.438 0 0 1 .228-.062c.11 0 .235.057.375.17.14.112.312.239.516.38.21.143.454.282.733.418.286.136.626.228 1.022.276l.158-2.358.157-2.357c-.408-.125-.81-.264-1.206-.418a4.002 4.002 0 0 1-1.057-.612 2.934 2.934 0 0 1-.75-.95c-.187-.38-.28-.856-.28-1.43a3.302 3.302 0 0 1 .978-2.362c.32-.32.713-.58 1.18-.781.465-.208.998-.323 1.598-.347l.087-1.278a.494.494 0 0 1 .122-.302.408.408 0 0 1 .315-.133h.576l-.113 1.749c.605.077 1.13.237 1.572.48.449.242.845.538 1.188.887l-.376.586c-.116.178-.25.267-.401.267a.648.648 0 0 1-.306-.107c-.117-.077-.26-.166-.428-.266a4.19 4.19 0 0 0-.585-.293 3.305 3.305 0 0 0-.76-.213l-.144 2.148-.145 2.15Z"
      fill={color}
    />
  </Svg>
);

export default Cost;
