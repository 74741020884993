import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const Save = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M2.5 4A1.5 1.5 0 0 1 4 2.5h6.793l1.187 1.187 1.08 1.08a1.5 1.5 0 0 1 .44 1.061V12a1.5 1.5 0 0 1-1.5 1.5H4A1.5 1.5 0 0 1 2.5 12V4Z"
      stroke={color}
    />
    <Circle cx={8} cy={9.333} r={1.5} stroke={color} />
  </Svg>
);

export default Save;
