import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Truck = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14.5 11.5v.5a.5.5 0 0 0 .5-.5h-.5Zm0-3.5h.5a.5.5 0 0 0-.071-.257L14.5 8ZM13 5.5l.429-.257A.5.5 0 0 0 13 5v.5Zm-2.5 0V5a.5.5 0 0 0-.5.5h.5Zm0 4.568.142.48-.142-.48ZM2.5 4h7V3h-7v1ZM2 10.5v-6H1v6h1Zm13 1V8h-1v3.5h1Zm-.071-3.757-1.5-2.5-.858.514 1.5 2.5.858-.514ZM13 5h-2.5v1H13V5Zm-3 .5V8h1V5.5h-1Zm0-1V8h1V4.5h-1Zm4.5 3h-4v1h4v-1ZM3 11h-.5v1H3v-1Zm.5.5c0-.17.055-.436.206-.645.132-.184.357-.355.794-.355v-1c-.763 0-1.288.33-1.606.77-.3.416-.394.9-.394 1.23h1Zm1-1c.437 0 .662.17.794.355.15.209.206.475.206.645h1c0-.33-.095-.814-.394-1.23-.318-.44-.843-.77-1.606-.77v1Zm0 2c-.437 0-.662-.17-.794-.355A1.177 1.177 0 0 1 3.5 11.5h-1c0 .33.095.814.394 1.23.318.44.843.77 1.606.77v-1Zm1-1c0 .17-.055.436-.206.645-.132.184-.357.355-.794.355v1c.763 0 1.288-.33 1.606-.77.3-.416.394-.9.394-1.23h-1Zm5.5-1c.437 0 .662.17.794.355.15.209.206.475.206.645h1c0-.33-.095-.814-.394-1.23-.318-.44-.843-.77-1.606-.77v1Zm0 2c-.437 0-.662-.17-.794-.355A1.177 1.177 0 0 1 10 11.5H9c0 .33.095.814.394 1.23.318.44.843.77 1.606.77v-1Zm1-1c0 .17-.055.436-.206.645-.132.184-.357.355-.794.355v1c.763 0 1.288-.33 1.606-.77.3-.416.394-.9.394-1.23h-1Zm-2 0c0-.326.168-.812.642-.953l-.284-.959C9.258 9.914 9 10.983 9 11.5h1Zm.642-.953c.096-.028.214-.047.358-.047v-1c-.232 0-.446.03-.642.088l.284.96ZM10 8v2.068h1V8h-1Zm4.5 3h-2v1h2v-1Zm-5 0H6v1h3.5v-1ZM1 10.5A1.5 1.5 0 0 0 2.5 12v-1a.5.5 0 0 1-.5-.5H1ZM9.5 4a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 9.5 3v1Zm-7-1A1.5 1.5 0 0 0 1 4.5h1a.5.5 0 0 1 .5-.5V3Z"
      fill={color}
    />
  </Svg>
);

export default Truck;
