import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Trash = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M6 6.75h12m-12 0v13.5h12V6.75m-12 0H4.5m13.5 0h1.5m-9.75-3h4.5M9 9.75v7.5m6-7.5v7.5m-3-7.84v8.214"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Trash;
