import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Edit = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10.088 15h5.614M12.774 4.856A1.304 1.304 0 0 1 14.617 6.7l-7.685 7.685-2.46.615.615-2.46 7.686-7.684Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Edit;
