import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Plus = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8 3.5v9M12.5 8h-9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Plus;
