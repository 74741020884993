import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Flashlight = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M26.012 14.849c-1.479 1.188-3.346 1.352-4.755 1.457-.763.06-1.711.608-2.254 1.15l-9.888 9.887c-1.02 1.02-2.454.783-3.47-.233l-.756-.756c-1.02-1.02-1.248-2.454-.232-3.468l9.889-9.881c.542-.543 1.094-1.494 1.15-2.254.102-1.42.188-3.223 1.454-4.764m8.862 8.862c.143-.115.282-.24.418-.375l.741-.741c.817-.817 1.188-1.781.372-2.598l-6.68-6.678c-.816-.816-1.781-.445-2.598.373l-.742.745a5.666 5.666 0 0 0-.373.412m8.862 8.862L17.15 5.987"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Flashlight;
