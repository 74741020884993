import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      paddingTop: { mobile: styleguide.insets.top, desktop: styleguide.insets.top + 60 }[
        styleguide.responsive
      ],
      paddingBottom: styleguide.insets.bottom + 80,
    },

    aboutUs: {
      width: '100%',
      paddingVertical: 16,
      paddingHorizontal: { mobile: 16, desktop: 24 }[styleguide.responsive],
      borderRadius: { mobile: 0, desktop: 8 }[styleguide.responsive],
      backgroundColor: styleguide.colors.surface,
      marginTop: 16,
    },

    solutions: {
      width: '100%',
      marginTop: 16,
      backgroundColor: styleguide.colors.surface,
      borderRadius: { mobile: 0, desktop: 8 }[styleguide.responsive],
    },

    suppliers: {},

    accordions: {
      width: '100%',
      marginTop: 16,
      backgroundColor: styleguide.colors.surface,
      paddingHorizontal: { mobile: 16, desktop: 24 }[styleguide.responsive],
      paddingVertical: 16,
      borderRadius: { mobile: 0, desktop: 8 }[styleguide.responsive],
    },
  });
