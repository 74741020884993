import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Live = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11.746 10.417c0 .925-.77 1.697-1.744 1.697-.975 0-1.743-.772-1.743-1.697s.768-1.696 1.743-1.696c.975 0 1.744.771 1.744 1.696Z"
      stroke={color}
      strokeWidth={1.25}
    />
    <Path
      d="M13.157 13.511c1.58-1.934 1.58-4.255 0-6.19M6.843 13.511c-1.58-1.934-1.58-4.255 0-6.19M15.134 15.833c1.787-1.805 4.29-6.5 0-10.833M4.87 15.833C3.083 14.028.58 9.333 4.87 5"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
    />
  </Svg>
);

export default Live;
