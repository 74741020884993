import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const Save = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3.125 5c0-1.036.84-1.875 1.875-1.875h8.491l1.484 1.484 1.35 1.35c.352.352.55.83.55 1.327V15c0 1.035-.84 1.875-1.875 1.875H5A1.875 1.875 0 0 1 3.125 15V5Z"
      stroke={color}
      strokeWidth={1.25}
    />
    <Circle cx={10} cy={11.667} r={1.875} stroke={color} strokeWidth={1.25} />
  </Svg>
);

export default Save;
