import * as React from 'react';

const Cart = (props) => (
  <svg width={25} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.423 22.286a.752.752 0 1 0 0-1.503.752.752 0 0 0 0 1.503ZM17.939 22.286a.752.752 0 1 0 0-1.503.752.752 0 0 0 0 1.503ZM3.664 3.498H6.67l.494 3.758m11.53 11.272H8.648l-.594-4.509m0 0h12.143L21.7 7.256H7.164m.89 6.763-.89-6.763"
      stroke="#fff"
      strokeWidth={1.503}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Cart;
