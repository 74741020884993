export const enviroment = 'prod'; //prod, dev

export const firebaseConfig = {
  apiKey: 'AIzaSyChEcY6LTO-lV_tOj5fVxYR9va9ssMXIY0',
  authDomain: 'yub-app-a7323.firebaseapp.com',
  projectId: 'yub-app-a7323',
  storageBucket: 'yub-app-a7323.appspot.com',
  messagingSenderId: '109837987465',
  appId: '1:109837987465:web:a79bbd00bfbc06524b2058',
  measurementId: 'G-BQ45Q9CJ76',
};
