import React, { useMemo } from 'react';
import Animated from 'react-native-reanimated';

import { typography } from '../../constants/typography';
import { useStyleguide } from '../../hooks/styleguide';

const Typography = ({
  onLayout = () => {},
  size,
  name,
  text,
  color,
  contentContainerStyle,
  ...rest
}) => {
  const { styleguide } = useStyleguide();

  const textColor = useMemo(() => {
    return color ? color : styleguide.colors.typographyPrimary;
  }, [color, styleguide.colors.typographyPrimary]);

  return (
    <Animated.Text
      onLayout={onLayout}
      allowFontScaling={false}
      style={[{ color: textColor }, typography[size][name], contentContainerStyle]}
      {...rest}>
      {text}
    </Animated.Text>
  );
};

export default Typography;
