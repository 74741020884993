import * as React from "react";
import Svg, { Path } from "react-native-svg";

const LockOpen = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.125 3.75c0-.625-1.25-1.875-3.125-1.875s-3.125 1.25-3.125 2.5V7.5m0 0h-1.25c-1.036 0-1.875.84-1.875 1.875v6.875c0 1.035.84 1.875 1.875 1.875h8.75c1.036 0 1.875-.84 1.875-1.875V9.375c0-1.036-.84-1.875-1.875-1.875h-7.5Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LockOpen;
