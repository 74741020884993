import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Cart = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13 29a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM23 29a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM4 4h4l.658 5M24 24H10.631l-.789-6m0 0H26l2-9H8.658m1.184 9L8.658 9"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Cart;
