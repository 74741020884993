import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Menu = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M2.25 4.898h6.5M2.25 11.898h18.5M2.25 18.898h13.5"
    />
  </Svg>
);

export default Menu;
