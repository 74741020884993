import * as React from "react";
import Svg, { Path } from "react-native-svg";

const StorageAudit = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m3.955 8.018.221-.585a.625.625 0 0 0-.66.14l.44.445Zm0 8.927H3.33c0 .263.165.498.412.587l.213-.587ZM9.96 19.13l-.213.587c.135.05.283.05.42.003l-.207-.59Zm6.25-2.184.207.59a.625.625 0 0 0 .419-.59h-.625Zm0-8.927.519-.35a.625.625 0 0 0-.731-.238l.213.588Zm-4.126 5.413-.518.35c.16.237.462.335.731.238l-.213-.588Zm6.25-2.267.214.587a.625.625 0 0 0 .305-.937l-.518.35ZM1.228 9.84a.625.625 0 0 0 .88.888l-.88-.888Zm15.608 2.093a.625.625 0 1 0-1.25 0h1.25ZM3.742 17.532l6.004 2.184.427-1.175-6.004-2.183-.427 1.174Zm6.423 2.187 6.25-2.184-.411-1.18-6.25 2.184.411 1.18ZM15.997 7.43l-6.25 2.268.425 1.175 6.25-2.268-.425-1.175ZM10.18 9.7 4.176 7.433l-.441 1.17 6.003 2.267.442-1.17Zm-.846.585v8.844h1.25v-8.844h-1.25Zm.107.35 2.124 3.146 1.036-.7-2.124-3.146-1.036.7Zm2.855 3.384 6.25-2.268-.425-1.175-6.251 2.268.426 1.175Zm6.556-3.205-2.124-3.146-1.036.7 2.124 3.145 1.036-.699ZM3.516 7.574 1.227 9.84l.88.888 2.288-2.267-.88-.888Zm-.186.444v1.544h1.25V8.018H3.33Zm0 1.544v7.383h1.25V9.562H3.33Zm13.505 7.383v-5.01h-1.25v5.01h1.25Z"
      fill={color}
    />
    <Path
      d="M9.992.885c-1.74 0-3.524 1.007-4.929 3.021a.357.357 0 0 0-.006.397c1.08 1.69 2.84 3.01 4.935 3.01 2.072 0 3.87-1.325 4.95-3.018a.36.36 0 0 0 0-.39C13.86 2.23 12.05.885 9.993.885Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10 5a1.25 1.25 0 1 0 0-2.5A1.25 1.25 0 0 0 10 5Z"
      fill={color}
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default StorageAudit;
