import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Calendar = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18 3.75h1.5A2.25 2.25 0 0 1 21.75 6v1.5M18 3.75v-1.5m0 1.5H6m0 0H4.5A2.25 2.25 0 0 0 2.25 6v1.5M6 3.75v-1.5M2.25 7.5v12a2.25 2.25 0 0 0 2.25 2.25h15a2.25 2.25 0 0 0 2.25-2.25v-12m-19.5 0h19.5"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.25 12a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM18 12a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM18 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM6.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM6.75 19.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 19.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM14.25 19.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      fill={color}
    />
  </Svg>
);

export default Calendar;
