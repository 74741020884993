import * as React from "react";
import Svg, { Path } from "react-native-svg";

const StorageAudit = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m3.164 6.414.177-.468a.5.5 0 0 0-.529.113l.352.355Zm0 7.142h-.5a.5.5 0 0 0 .33.47l.17-.47Zm4.803 1.747-.17.47a.5.5 0 0 0 .335.002l-.165-.472Zm5-1.747.166.472a.5.5 0 0 0 .335-.472h-.5Zm0-7.142.415-.28a.5.5 0 0 0-.585-.19l.17.47Zm-3.3 4.331-.415.28a.5.5 0 0 0 .585.19l-.17-.47Zm5-1.814.17.47a.5.5 0 0 0 .244-.75l-.414.28ZM.982 7.873a.5.5 0 0 0 .703.71l-.703-.71Zm12.486 1.674a.5.5 0 0 0-1 0h1ZM2.993 14.026l4.803 1.747.342-.94-4.803-1.747-.342.94Zm5.14 1.749 5-1.747-.33-.944-5 1.747.33.944Zm4.664-9.831-5 1.814.34.94 5.001-1.814-.34-.94ZM8.144 7.76 3.34 5.946l-.353.936L7.79 8.696l.353-.936Zm-.677.468v7.075h1V8.228h-1Zm.086.28 1.699 2.517.829-.56-1.7-2.517-.828.56Zm2.284 2.707 5-1.814-.34-.94-5.001 1.814.34.94Zm5.244-2.564-1.699-2.517-.829.56 1.7 2.517.828-.56ZM2.812 6.06.982 7.873l.703.71L3.516 6.77l-.704-.71Zm-.148.355V7.65h1V6.414h-1Zm0 1.236v5.906h1V7.65h-1Zm10.804 5.906V9.547h-1v4.01h1Z"
      fill={color}
    />
    <Path
      d="M7.994.708c-1.392 0-2.82.806-3.943 2.417a.286.286 0 0 0-.005.317c.863 1.352 2.272 2.409 3.948 2.409 1.658 0 3.095-1.06 3.96-2.415a.288.288 0 0 0 0-.312C11.087 1.784 9.64.708 7.994.708Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill={color}
      stroke={color}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default StorageAudit;
