import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Login = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m13.75 10-2.917 2.917M13.75 10l-2.917-2.917M13.75 10H3.333"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6.875 6.667a.625.625 0 0 0 1.25 0h-1.25Zm1.25 6.666a.625.625 0 1 0-1.25 0h1.25ZM10 3.958h4.167v-1.25H10v1.25Zm6.042 1.875v8.334h1.25V5.833h-1.25Zm-1.875 10.209H10v1.25h4.167v-1.25ZM8.125 6.667v-.834h-1.25v.834h1.25Zm0 7.5v-.834h-1.25v.834h1.25ZM10 16.042a1.875 1.875 0 0 1-1.875-1.875h-1.25c0 1.726 1.4 3.125 3.125 3.125v-1.25Zm6.042-1.875c0 1.035-.84 1.875-1.875 1.875v1.25c1.726 0 3.125-1.4 3.125-3.125h-1.25ZM14.167 3.958c1.035 0 1.875.84 1.875 1.875h1.25c0-1.726-1.4-3.125-3.125-3.125v1.25ZM10 2.708a3.125 3.125 0 0 0-3.125 3.125h1.25c0-1.035.84-1.875 1.875-1.875v-1.25Z"
      fill={color}
    />
  </Svg>
);

export default Login;
