import * as React from "react";
import Svg, { Path } from "react-native-svg";

const AddItem = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M17.298 3.333h-4.432a.521.521 0 0 0-.368.152l-8.862 8.86a1.04 1.04 0 0 0 0 1.467l4.22 4.218a1.04 1.04 0 0 0 1.467 0l8.859-8.855a.521.521 0 0 0 .152-.368V4.372a1.032 1.032 0 0 0-1.036-1.039v0Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M15 6.875a.625.625 0 0 1-.347-.105l-.34.508.34-.508a.625.625 0 1 1 .347.105Z"
      stroke={color}
      strokeWidth={1.25}
    />
    <Path
      d="M4.375 1.875v2.5m0 2.5v-2.5m0 0h2.5m-2.5 0h-2.5"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
    />
  </Svg>
);

export default AddItem;
