import React, { createContext, useCallback, useContext, useRef, useState } from 'react';

import { useUrl } from './url';

const AuthenticationContext = createContext({});

const NavigationProvider = ({ children }) => {
  const initialNavigation = {
    routes: [],
  };

  const [navigation, setNavigation] = useState(initialNavigation);

  const { addParams, removeParam } = useUrl();

  const routesRef = useRef();

  const navigate = useCallback(
    ({ variant = '', screen = '', props = {} }) => {
      if (variant === 'page') {
        if (screen === '') {
          removeParam('page');
        } else {
          addParams({ page: screen });
        }

        routesRef.current.showPage({
          screen,
          props,
        });
      } else if (variant === 'sidePage') {
        if (screen === '') {
          removeParam('sidePage');
        } else {
          addParams({ sidePage: screen });
        }

        routesRef.current.showSidePage({
          screen,
          props,
        });
      } else if (variant === 'modal') {
        if (screen === '') {
          removeParam('modal');
        } else {
          addParams({ modal: screen });
        }

        routesRef.current.showModal({
          screen,
          props,
        });
      }
    },
    [removeParam, addParams],
  );

  return (
    <AuthenticationContext.Provider
      value={{
        routesRef,
        navigate,
        navigation,
        setNavigation,
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

function useNavigation() {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error('useNavigation must be used within as NavigationProvider');
  }

  return context;
}

export { NavigationProvider, useNavigation };
