import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Cash = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M2.946 18h18.108c.384 0 .696-.336.696-.75V6.75c0-.414-.312-.75-.696-.75H2.946c-.384 0-.696.336-.696.75v10.5c0 .414.312.75.696.75Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <Path
      d="M12.001 15.75c1.973 0 3.572-1.679 3.572-3.75 0-2.071-1.6-3.75-3.572-3.75S8.43 9.929 8.43 12c0 2.071 1.599 3.75 3.571 3.75ZM21.75 9.75a3.36 3.36 0 0 1-2.462-1.098A3.901 3.901 0 0 1 18.268 6M2.25 9.75c.457 0 .91-.097 1.333-.285a3.477 3.477 0 0 0 1.13-.813c.323-.349.58-.762.754-1.217.175-.455.265-.943.265-1.435m16.018 8.25a3.36 3.36 0 0 0-2.462 1.098A3.901 3.901 0 0 0 18.268 18M2.25 14.25c.457 0 .91.097 1.333.286.422.188.806.464 1.13.812.323.349.58.762.754 1.217.175.455.265.942.265 1.435"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Cash;
