import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Settings = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10.246 7.512a2.5 2.5 0 1 0-.491 4.976 2.5 2.5 0 0 0 .491-4.976v0ZM16.266 10a6.03 6.03 0 0 1-.06.812l1.766 1.385a.422.422 0 0 1 .096.537l-1.671 2.891a.422.422 0 0 1-.513.18l-1.754-.707a.629.629 0 0 0-.593.068 6.425 6.425 0 0 1-.841.49.623.623 0 0 0-.345.475L12.088 18a.432.432 0 0 1-.417.358H8.33a.434.434 0 0 1-.418-.346l-.262-1.868a.628.628 0 0 0-.352-.477 6.069 6.069 0 0 1-.838-.491.623.623 0 0 0-.59-.067l-1.754.706a.422.422 0 0 1-.513-.18l-1.67-2.89a.422.422 0 0 1 .095-.537l1.492-1.172a.627.627 0 0 0 .235-.55 5.328 5.328 0 0 1 0-.97.625.625 0 0 0-.237-.545L2.026 7.8a.422.422 0 0 1-.093-.534l1.671-2.891a.422.422 0 0 1 .513-.18l1.754.707a.63.63 0 0 0 .593-.068c.268-.185.549-.348.841-.49a.623.623 0 0 0 .345-.474l.263-1.871a.433.433 0 0 1 .417-.358h3.341a.434.434 0 0 1 .418.346l.262 1.868a.629.629 0 0 0 .352.477c.292.141.572.306.838.491a.625.625 0 0 0 .59.067l1.754-.706a.422.422 0 0 1 .513.18l1.67 2.89a.422.422 0 0 1-.095.537l-1.492 1.172a.627.627 0 0 0-.237.55c.013.162.022.324.022.487Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Settings;
