import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Sun = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8.5 1.5a.5.5 0 0 0-1 0h1ZM7.5 3a.5.5 0 0 0 1 0h-1Zm1 10a.5.5 0 0 0-1 0h1Zm-1 1.5a.5.5 0 0 0 1 0h-1Zm5.45-10.743a.5.5 0 1 0-.707-.707l.707.707Zm-1.768.354a.5.5 0 1 0 .707.707l-.707-.707Zm-6.364 7.778a.5.5 0 1 0-.707-.707l.707.707Zm-1.768.354a.5.5 0 1 0 .707.707l-.707-.707ZM14.5 8.5a.5.5 0 0 0 0-1v1Zm-1.5-1a.5.5 0 0 0 0 1v-1Zm-10 1a.5.5 0 0 0 0-1v1Zm-1.5-1a.5.5 0 0 0 0 1v-1Zm10.743 5.45a.5.5 0 1 0 .707-.707l-.707.707Zm-.354-1.768a.5.5 0 1 0-.707.707l.707-.707ZM4.111 4.818a.5.5 0 0 0 .707-.707l-.707.707ZM3.757 3.05a.5.5 0 1 0-.707.707l.707-.707ZM7.5 1.5V3h1V1.5h-1Zm0 11.5v1.5h1V13h-1Zm4.743-9.95-1.06 1.06.706.708 1.06-1.06-.706-.708ZM4.11 11.182l-1.06 1.06.706.708 1.06-1.06-.706-.708ZM14.5 7.5H13v1h1.5v-1ZM3 7.5H1.5v1H3v-1Zm9.95 4.743-1.06-1.06-.708.706 1.06 1.06.708-.706ZM4.818 4.11l-1.06-1.06-.708.706 1.06 1.06.708-.706Z"
      fill={color}
    />
    <Path
      d="M8 10.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default Sun;
