import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Whatsapp = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2 3.793a8.678 8.678 0 0 0-6.163-2.543c-4.803 0-8.712 3.89-8.714 8.672a8.618 8.618 0 0 0 1.163 4.336L1.25 18.75l4.62-1.206a8.735 8.735 0 0 0 4.163 1.055h.004c4.802 0 8.71-3.89 8.713-8.672a8.601 8.601 0 0 0-2.55-6.134Zm-6.163 13.342h-.003a7.255 7.255 0 0 1-3.686-1.005l-.265-.156-2.74.716.73-2.66-.171-.273a7.166 7.166 0 0 1-1.108-3.835c0-3.974 3.25-7.207 7.245-7.207a7.227 7.227 0 0 1 7.24 7.212c-.002 3.975-3.25 7.208-7.242 7.208Zm3.972-5.398c-.218-.108-1.29-.633-1.488-.705-.198-.072-.344-.108-.49.109-.144.217-.562.703-.689.85-.127.146-.254.162-.471.053-.218-.108-.92-.337-1.751-1.075-.648-.575-1.084-1.284-1.211-1.501-.127-.217-.014-.334.095-.442.098-.097.217-.253.326-.38.11-.126.146-.217.218-.362.072-.144.036-.27-.018-.379-.054-.108-.49-1.175-.671-1.609-.177-.423-.356-.365-.49-.372a8.842 8.842 0 0 0-.417-.008.802.802 0 0 0-.58.272c-.2.217-.763.742-.763 1.807 0 1.066.781 2.097.89 2.242.107.144 1.534 2.333 3.718 3.272.406.173.82.326 1.242.456.521.165.996.142 1.37.086.419-.062 1.29-.524 1.47-1.03.181-.507.182-.94.128-1.03-.055-.091-.2-.146-.418-.254Z"
      fill={color}
    />
  </Svg>
);

export default Whatsapp;
