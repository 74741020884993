import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Time = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8 2C4.687 2 2 4.688 2 8c0 3.313 2.688 6 6 6 3.313 0 6-2.688 6-6 0-3.313-2.688-6-6-6Z"
      stroke={color}
      strokeMiterlimit={10}
    />
    <Path
      d="M8 4.5v3.89l2 1.11"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Time;
