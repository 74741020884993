import * as React from "react";
import Svg, { Path } from "react-native-svg";

const People = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M25.128 10.5c-.184 2.542-2.07 4.5-4.126 4.5-2.056 0-3.945-1.957-4.125-4.5C16.69 7.856 18.526 6 21.002 6c2.477 0 4.313 1.904 4.126 4.5Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21 19c-4.073 0-7.99 2.023-8.972 5.963-.13.521.197 1.037.733 1.037h16.478c.536 0 .86-.516.733-1.037C28.99 20.96 25.073 19 21 19Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <Path
      d="M12.5 11.621c-.147 2.03-1.67 3.629-3.313 3.629-1.642 0-3.169-1.598-3.312-3.629C5.725 9.51 7.208 8 9.187 8c1.979 0 3.462 1.548 3.313 3.621Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12.876 19.122c-1.128-.517-2.371-.716-3.688-.716-3.25 0-6.381 1.616-7.166 4.763-.103.416.159.828.586.828h7.018"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default People;
