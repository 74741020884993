import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Checklist = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      d="M8.959 6.042h7.5M8.959 10.209h7.5M8.959 14.375h7.5"
    />
    <Path
      d="m3.333 5.952 1.072.715L5.833 5M3.333 10.119l1.072.714 1.428-1.667M3.333 14.286 4.405 15l1.428-1.666"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Checklist;
