import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Image, View } from 'react-native';
import Animated, { interpolate, useAnimatedStyle, withTiming } from 'react-native-reanimated';

import { createStyles } from './styles';
import { useStyleguide } from '../../hooks/styleguide';
import ButtonIcon from '../ButtonIcon';
import ButtonText from '../ButtonText';
import Typography from '../Typography';

const Header = ({
  scrollY = { value: 0 },
  buttonLeft,
  logo,
  avatar,
  title,
  description,
  titleLarge,
  searchBar,
  buttonsIcon = [],
  buttonsText = [],
}) => {
  const { styleguide } = useStyleguide();

  const titleLargeHeight = 44;
  const searchBarHeight = 52;

  const searchInputRef = useRef(null);

  const [isShearchBarFocused, setIsSearchBarFocused] = useState(false);

  const titleDescriptionAnimated = useAnimatedStyle(() => {
    const opacity = interpolate(scrollY.value, [titleLargeHeight, titleLargeHeight + 1], [0, 1], {
      extrapolateLeft: 'clamp',
      extrapolateRight: 'clamp',
    });

    return {
      opacity: titleLarge ? withTiming(opacity, { duration: 200 }) : 1,
      marginLeft: 6,
      flex: 1,
      // justifyContent: 'center',
      // maxHeight: 40,
    };
  }, [titleLargeHeight, titleLarge]);

  const viewSearchAnimated = useAnimatedStyle(() => {
    const opacity = interpolate(
      scrollY.value,
      [titleLargeHeight + searchBarHeight, titleLargeHeight + searchBarHeight + 1],
      [0, 1],
      {
        extrapolateLeft: 'clamp',
        extrapolateRight: 'clamp',
      },
    );

    return {
      opacity: withTiming(opacity, { duration: 200 }),
      marginLeft: 6,
    };
  }, []);

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  const focusSearchBar = useCallback(() => {
    setIsSearchBarFocused(true);

    searchBar.scrollToFirstItem();

    searchInputRef.current?.focus();
  }, [searchBar]);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {buttonLeft && <ButtonIcon {...buttonLeft} size="small" />}
        {logo && (
          <Image
            resizeMode="contain"
            source={{ uri: logo.uri.icon }}
            style={[styles.logo, { width: (Number(logo.width) / Number(logo.height)) * 32 }]}
          />
        )}

        {avatar && <Image source={{ uri: avatar }} style={styles.avatar} />}

        <Animated.View style={titleDescriptionAnimated}>
          {title && <Typography size="small" name="title" text={title} />}
          {description && (
            <Typography
              color={styleguide.colors.typographySecondary}
              size="small"
              // style={{ flex: 1 }}
              name="caption"
              text={description}
            />
          )}
        </Animated.View>

        {searchBar && (
          <Animated.View style={viewSearchAnimated}>
            <ButtonIcon
              variant="primary"
              onPress={() => {
                focusSearchBar();
              }}
              size="small"
              icon="search"
            />
          </Animated.View>
        )}

        {buttonsIcon.map((item) => (
          <ButtonIcon
            key={item.icon}
            variant="primary"
            contentContainerStyle={{ marginLeft: 6 }}
            size="small"
            {...item}
          />
        ))}

        {buttonsText.map((item) => (
          <ButtonText
            key={item.title}
            variant="primary"
            contentContainerStyle={{ marginLeft: 6 }}
            size="small"
            {...item}
          />
        ))}
      </View>
    </View>
  );
};

export default Header;
