import * as React from "react";
import Svg, { Path } from "react-native-svg";

const BookOpened = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m9.802 5.417 8.194-2.462a.1.1 0 0 1 .129.096V14.93a.1.1 0 0 1-.067.095l-8.256 2.893m0-12.5L2.01 2.55a.1.1 0 0 0-.135.093v11.874a.1.1 0 0 0 .061.092l7.866 3.308m0-12.5v12.5"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m11.875 13.995 3.958-1.385m-3.958-1.36 3.958-1.385m-3.958-1.36 3.958-1.385"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
    />
  </Svg>
);

export default BookOpened;
