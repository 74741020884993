import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Scale = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M23 3H9a6 6 0 0 0-6 6v14a6 6 0 0 0 6 6h14a6 6 0 0 0 6-6V9a6 6 0 0 0-6-6Z"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <Path
      d="M24.31 9.473C22.78 8.08 20.013 6.26 16 6.26c-4.013 0-6.78 1.82-8.308 3.213a2.166 2.166 0 0 0-.191 3.005l2.082 2.45a1.63 1.63 0 0 0 2.1.326c.995-.614 2.557-1.352 4.319-1.352s3.324.738 4.319 1.352a1.63 1.63 0 0 0 2.1-.325l2.08-2.451a2.166 2.166 0 0 0-.192-3.005Z"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Scale;
