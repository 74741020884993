import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Chart = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12.005 3.78c.246-.02.495-.03.747-.03 4.969 0 9 4.031 9 9s-4.031 9-9 9A9.004 9.004 0 0 1 4.18 15.5"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <Path
      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75a9.717 9.717 0 0 0 .848 3.984L12 12V2.25Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Chart;
