import { manipulateAsync } from 'expo-image-manipulator';
import {
  getFirestore,
  onSnapshot,
  doc,
  query,
  collection,
  where,
  setDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  limit,
  getDoc,
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import moment from 'moment';
import React, { createContext, useContext, useCallback } from 'react';

import { useAuthentication } from './authentication';
import { useStyleguide } from './styleguide';

const DatabaseContext = createContext({});

const DatabaseProvider = ({ children }) => {
  const db = getFirestore();
  const { styleguide } = useStyleguide();
  const { authentication } = useAuthentication();

  const createObject = useCallback(
    async ({ collectionName, setObject = () => {}, object }) => {
      console.info(`firestore createObject, ${collectionName}`);

      let injection = {};

      if (
        // collectionName !== 'users'&&
        authentication.user.id !== undefined
      ) {
        injection = {
          ...injection,
          userId: authentication.user.id,
        };
      }

      if (
        // collectionName !== 'users'&&
        // collectionName !== 'stores' &&
        authentication.store.id !== undefined &&
        authentication.permission.id !== undefined
      ) {
        injection = {
          ...injection,
          storeId: authentication.store.id,
        };
      }

      try {
        let objectRef;

        if (object.id !== undefined) {
          objectRef = doc(db, collectionName, object.id);
        } else {
          objectRef = doc(collection(db, collectionName));
        }

        const newObject = {
          createdAt: moment().format(),
          updatedAt: moment().format(),
          id: objectRef.id,
          ...injection,
          ...object,
        };

        await setDoc(objectRef, newObject);

        setObject(newObject);

        return newObject;
      } catch (error) {
        console.error('error createObject', error);
      }
    },
    [authentication.user.id, authentication.store.id, styleguide.application],
  );

  const createImage = async ({ image }) => {
    let resizeObj = {};

    if (image.height > image.width) {
      resizeObj = { width: 1024 };
    } else {
      resizeObj = { height: 1024 };
    }

    const manipResult = await manipulateAsync(image.uri, [{ resize: resizeObj }]);

    const responseImage = await fetch(manipResult.uri);

    const blob = await responseImage.blob();

    const storage = getStorage();

    let prefix;

    if (styleguide.application === 'app') {
      prefix = authentication.userId;
    } else {
      prefix = authentication.storeId;
    }

    const documentRef = `${prefix}/${moment().format()}`;

    const storageRef = ref(storage, String(documentRef));

    const snapshot = await uploadBytes(storageRef, blob);

    const downloadURL = await getDownloadURL(snapshot.ref);

    // await deleteObjectStorage(storageRef);

    const imageUrl = downloadURL.replace(/\?/g, '_1024x1024?');
    const imageUrl2 = downloadURL.replace(/\?/g, '_512x512?');
    const imageUrl3 = downloadURL.replace(/\?/g, '_256x256?');
    const imageUrl4 = downloadURL.replace(/\?/g, '_128x128?');

    return {
      imageUrl,
      imageUrl2,
      imageUrl3,
      imageUrl4,
    };
  };

  const getObject = useCallback(async ({ collectionName, objectId }) => {
    try {
      console.info(`firestore getObject, ${collectionName}`);

      const docRef = doc(db, collectionName, objectId);

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return {};
      }
    } catch (error) {
      console.error(`firestore getObject, ${collectionName}`, error);
    }
  }, []);

  const getArray = useCallback(
    async ({
      collectionName,
      setLoadingArrayHook = () => {},
      foreignKeyName,
      foreignKeyValue,
      setLastVisible = () => {},
      setArrayHook = () => {},
    }) => {
      try {
        console.info(`firestore getArray, ${collectionName}`);

        // setAudits(EMPTY_AUDITS);

        const auditsQuery = query(
          collection(db, collectionName),
          where(foreignKeyName, '==', foreignKeyValue),
          limit(50),
        );

        const hookArray = [];

        setLoadingArrayHook(true);

        const querySnapshot = await getDocs(auditsQuery);

        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

        querySnapshot.forEach((doc) => {
          hookArray.push(doc.data());
        });

        hookArray.sort((a, b) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'));

        setArrayHook(hookArray);

        setLoadingArrayHook(false);

        return hookArray;
      } catch (error) {
        setLoadingArrayHook(false);

        console.error(`firestore getArray, ${collectionName}`, error);
      }
    },
    [],
  );

  const listenObject = useCallback(({ collectionName, objectId, setObject }) => {
    const objectRef = doc(db, collectionName, objectId);

    return onSnapshot(
      objectRef,
      (doc) => {
        console.info(`firestore listenObject, ${collectionName}`);

        const object = doc.data();

        if (object !== undefined) {
          setObject(doc.data());
        }
      },
      (error) => {
        console.error(`error firestore listenObject ${collectionName}`, error);
      },
    );
  }, []);

  const listenArray = useCallback(
    ({
      collectionName,
      startDate,
      endDate,
      setHooks,
      setLoadingHooks = () => {},
      foreignKeyName,
      foreignKeyValue,
      foreignKeyValueType = 'string',
      foreignKeyName2,
      foreignKeyValue2,
      foreignKeyValueType2 = 'string',
    }) => {
      const whereContent = {
        string: '==',
        array: 'array-contains',
      }[foreignKeyValueType];

      const whereContent2 = {
        string: '==',
        array: 'array-contains',
      }[foreignKeyValueType2];

      const arrayQuery = [
        collection(db, collectionName),
        where(foreignKeyName, whereContent, foreignKeyValue),
      ];

      if (foreignKeyName2) {
        arrayQuery.push(where(foreignKeyName2, whereContent2, foreignKeyValue2));
      }

      if (startDate) {
        arrayQuery.push(where('createdAt', '>=', startDate));
      }

      if (endDate) {
        arrayQuery.push(where('createdAt', '<=', endDate));
      }

      const arrayRef = query(...arrayQuery);

      return onSnapshot(
        arrayRef,
        (querySnapshot) => {
          console.info(`firestore listenArray, ${collectionName}`);

          const arrayHook = [];

          querySnapshot.forEach((doc) => {
            arrayHook.push(doc.data());
          });

          arrayHook.sort((a, b) => Number(a.index) - Number(b.index));

          setHooks(arrayHook);
          setLoadingHooks(false);
        },
        (error) => {
          console.error('error firestore listenArray', collectionName, error);
        },
      );
    },
    [],
  );

  const updateObject = useCallback(async ({ collectionName, objectId, param, text }) => {
    try {
      console.info(`firestore updateObject, ${collectionName}`);

      const objectRef = doc(db, collectionName, objectId);

      await updateDoc(objectRef, {
        [param]: text,
        updatedAt: moment().format(),
      });
    } catch (error) {
      console.error(`error updateObject ${collectionName} `, error);
    } finally {
      return;
    }
  }, []);

  const deleteObject = useCallback(
    async ({
      collectionName,
      objectId,
      objectIndex = '',
      objectSectionId = '',
      objectVariant = '',
      arrayHook = [],
    }) => {
      try {
        console.info(`firestore deleteObject, ${collectionName}`);

        const objectRef = doc(db, collectionName, objectId);

        await deleteDoc(objectRef);

        if (objectIndex !== '') {
          let forwardObjects = JSON.parse(JSON.stringify(arrayHook));

          forwardObjects = forwardObjects.filter(
            (item) => Number(item.index) > Number(objectIndex),
          );

          if (objectSectionId !== '') {
            forwardObjects = forwardObjects.filter((item) => item.sectionId === objectSectionId);
          }

          if (objectVariant !== '') {
            forwardObjects = forwardObjects.filter((item) => item.variant === objectVariant);
          }

          await Promise.all(
            forwardObjects.map(async (item) => {
              await updateObject({
                collectionName,
                objectId: item.id,
                param: 'index',
                text: String(Number(item.index) - 1),
              });
            }),
          );
        }
      } catch (error) {
        console.error(`error deleteObject ${collectionName}`, error);
      } finally {
        return;
      }
    },
    [],
  );

  return (
    <DatabaseContext.Provider
      value={{
        createObject,
        createImage,
        getObject,
        getArray,
        listenObject,
        listenArray,
        updateObject,
        deleteObject,
      }}>
      {children}
    </DatabaseContext.Provider>
  );
};

function useDatabase() {
  const context = useContext(DatabaseContext);

  if (!context) {
    throw new Error('useDatabase must be used within as DatabaseProvider');
  }

  return context;
}

export { DatabaseProvider, useDatabase };
