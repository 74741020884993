import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Store = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M25 27V15M7 15v12m-2 0h22m-13.952 0v-7c0-.245.1-.48.279-.653a.968.968 0 0 1 .673-.27h4c.253 0 .495.097.673.27a.909.909 0 0 1 .28.653v7"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5 12.5v-.44c0-.259.066-.514.194-.745L8.16 5.92C8.47 5.357 9.105 5 9.8 5h12.4c.695 0 1.33.357 1.64.921l2.966 5.394c.128.231.194.486.194.745v.44m-22 0Zm0 0C5 13.88 6.231 15 7.75 15s2.75-1.12 2.75-2.5c0 1.38 1.231 2.5 2.75 2.5S16 13.88 16 12.5c0 1.38 1.231 2.5 2.75 2.5s2.75-1.12 2.75-2.5c0 1.38 1.231 2.5 2.75 2.5S27 13.88 27 12.5m0 0Z"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Store;
