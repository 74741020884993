import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      width: '100%',
      paddingVertical: 16,
      alignItems: 'center',
    },

    image: {
      height: 328,
      width: 328,
      borderRadius: 8,
    },

    spacing: {
      height: 16,
      width: 16,
    },

    content: {
      flex: 1,
    },

    check: {
      flexDirection: 'row',
      marginTop: 16,
    },
  });
