import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      backgroundColor: styleguide.colors.surface,
      position: 'absolute',
      width: '100%',
      borderBottomLeftRadius: { mobile: 0, desktop: 8 }[styleguide.responsive],
      borderBottomRightRadius: { mobile: 0, desktop: 8 }[styleguide.responsive],
      borderRightWidth: { mobile: 0, desktop: 1 }[styleguide.responsive],
      borderLeftWidth: { mobile: 0, desktop: 1 }[styleguide.responsive],
      borderBottomWidth: 1,
      borderColor: styleguide.colors.border,
    },

    content: {
      width: '100%',
      flexDirection: 'row',
      zIndex: 2,
      paddingVertical: 6,
      paddingHorizontal: 12,
      backgroundColor: styleguide.colors.background.secondary,
    },

    logo: {
      height: 32,
      // borderRadius: 4,
      marginLeft: 6,
    },

    avatar: {
      height: 32,
      width: 32,
      borderRadius: 16,
      marginLeft: 6,
    },
  });
