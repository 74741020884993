import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { useStyleguide } from '../../../hooks/styleguide';

const CheckboxFilled = ({ color }) => {
  const { styleguide } = useStyleguide();

  return (
    <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M0 5a5 5 0 0 1 5-5h10a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5Z"
        fill={color}
      />
      <Path
        d="M4.56 9.945a.625.625 0 0 0-.786.972l.786-.972Zm4.223 4.222-.393.486c.264.213.65.177.87-.083l-.477-.403Zm7.528-7.93a.625.625 0 0 0-.954-.807l.954.807Zm-12.537 4.68 4.616 3.736.786-.972L4.56 9.945l-.786.972ZM9.26 14.57l7.05-8.333-.953-.807-7.051 8.333.954.807Z"
        fill={styleguide.colors.inBrandPrimary}
      />
    </Svg>
  );
};

export default CheckboxFilled;
