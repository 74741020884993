import * as React from "react";
import Svg, { Circle } from "react-native-svg";

const Available = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={10} cy={10} r={7.5} fill={color} />
  </Svg>
);

export default Available;
