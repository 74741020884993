import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Check = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M7.296 15.912a1 1 0 1 0-1.258 1.555l1.258-1.554Zm6.756 6.755-.629.777a1 1 0 0 0 1.393-.131l-.764-.646ZM26.098 9.979a1 1 0 0 0-1.527-1.291l1.527 1.291Zm-20.06 7.488 7.386 5.977 1.259-1.554-7.386-5.978-1.258 1.555Zm8.779 5.846L26.097 9.979 24.57 8.688 13.29 22.02l1.526 1.292Z"
      fill={color}
    />
  </Svg>
);

export default Check;
