import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Edit = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16.14 24h8.983M20.438 7.769a2.087 2.087 0 0 1 2.951 2.951L11.093 23.016 7.158 24l.984-3.935L20.438 7.77Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Edit;
