import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Item = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16.465 2.5h-4.433a.521.521 0 0 0-.368.151l-8.861 8.86a1.04 1.04 0 0 0 0 1.467l4.219 4.22a1.04 1.04 0 0 0 1.468 0l8.859-8.857a.521.521 0 0 0 .151-.367V3.538A1.032 1.032 0 0 0 16.465 2.5Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.167 6.042a.625.625 0 1 1 0-1.25.625.625 0 0 1 0 1.25Z"
      stroke={color}
      strokeWidth={1.25}
    />
  </Svg>
);

export default Item;
