import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Sort = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M17.25 3v17.25m0 0 3-3m-3 3-3-3M3.75 5.25h9m-9 6.75h6m-6 6.75H7.5"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

export default Sort;
