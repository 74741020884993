import React, { useMemo, useState } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';

import { createStyles } from './styles';
import { useStyleguide } from '../../hooks/styleguide';
import Badge from '../Badge';
import Icon from '../Icon';
import Typography from '../Typography';

const ButtonIcon = ({
  size,
  variant = 'primary',
  icon,
  title,
  loading = false,
  onPress,
  badge,
  contentContainerStyle = {},
}) => {
  const { styleguide } = useStyleguide();

  const [isHovered, setIsHovered] = useState(false);

  const padding = useMemo(() => {
    const variants = {
      medium: 6,
      small: 4,
    };

    return variants[size];
  }, [size]);

  const iconSize = useMemo(() => {
    const variants = {
      medium: '32',
      small: '24',
    };

    return variants[size];
  }, [size]);

  const typographySize = useMemo(() => {
    const variants = {
      medium: 'medium',
      small: 'small',
    };

    return variants[size];
  }, [size]);

  const colorTypography = useMemo(() => {
    // if (style.color) return style.color;
    // else {
    //   return styleguide.colors.typographyPrimary;
    // }
    const variants = {
      primary: styleguide.brand,
      secondary: styleguide.colors.typographyPrimary,
    };

    return variants[variant];
  }, [styleguide.brand, styleguide.colors.typographyPrimary, variant]);

  const opacity = useMemo(() => {
    const variants = {
      false: 1,
      true: 0,
    };

    return variants[loading];
    //  return loading ? 0 : 1
  }, [loading]);

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  return (
    <Pressable
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      style={[
        styles.container,
        {
          padding,
          borderRadius: padding,
        },
        contentContainerStyle,
      ]}
      onPress={loading ? () => {} : onPress}>
      <Icon
        name={icon}
        size={iconSize}
        color={colorTypography}
        contentContainerStyle={{ opacity }}
      />

      {title && (
        <Typography
          contentContainerStyle={{
            opacity,
          }}
          color={colorTypography}
          size={typographySize}
          name="caption"
          text={title}
        />
      )}

      {loading && (
        <ActivityIndicator style={{ position: 'absolute' }} color={colorTypography} size="small" />
      )}

      {isHovered && <View style={[styles.hover, { borderRadius: padding }]} />}

      {badge && <Badge number={badge} />}
    </Pressable>
  );
};

export default ButtonIcon;
