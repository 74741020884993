import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Clip = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M22 10.28V21c0 5.2-2 8-6 8s-6-2.8-6-8V7.16C10 5 11.454 3 14 3c3 0 4 2.08 4 4.16V20c0 1.32 0 3-2 3s-2-1.68-2-3v-9.72"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
);

export default Clip;
