import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Star = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M30 13H19.25L16 3l-3.25 10H2l8.75 6-3.375 10L16 22.75 24.625 29 21.25 19 30 13Z"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Star;
