import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Sort = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14.375 2.5v14.375m0 0 2.5-2.5m-2.5 2.5-2.5-2.5M3.125 4.375h7.5M3.125 10h5m-5 5.625H6.25"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
    />
  </Svg>
);

export default Sort;
