import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Camera = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M4 5h.375c.27 0 .495-.15.67-.354l.833-1.314A.9.9 0 0 1 6.562 3h2.875a.9.9 0 0 1 .686.332l.831 1.314c.176.205.432.354.702.354H13.5a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1H3m1 0V4H3v1m1 0H3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      stroke={color}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Camera;
