import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Moon = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M7.5 6.375c0-1.435.211-2.888.75-4.125-3.583 1.56-6 5.218-6 9.375 0 5.592 4.533 10.125 10.125 10.125 4.157 0 7.816-2.417 9.375-6-1.237.539-2.69.75-4.125.75C12.033 16.5 7.5 11.967 7.5 6.375Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Moon;
