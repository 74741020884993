import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Transaction = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M21 16.492V9.73m0 0h-6.699m6.7 0L9.73 21M3 7.508v6.762m0 0h6.698M3 14.27 14.25 3.02"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Transaction;
