import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Logout = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11.875 13.125v1.563a1.563 1.563 0 0 1-1.563 1.562h-6.25A1.563 1.563 0 0 1 2.5 14.687V5.313A1.562 1.562 0 0 1 4.063 3.75H10c.863 0 1.875.7 1.875 1.563v1.562m2.5 6.25L17.5 10l-3.125-3.125M6.875 10h10"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Logout;
