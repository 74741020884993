import * as React from "react";
import Svg, { Path } from "react-native-svg";

const QrCode = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 7.5H1V1h6.5v6.5ZM2.375 6.125h3.75v-3.75h-3.75v3.75ZM3 10.5h2.5V13H3v-2.5Zm5.5-3H15V1H8.5v6.5Zm5.125-1.375h-3.75v-3.75h3.75v3.75ZM5.5 3H3v2.5h2.5V3Zm7.5 7.5h-2.5v-2h-2v2h2V13H13v2h2v-2h-2v-2.5Zm2-2h-1.5V10H15V8.5Zm-5 5H8.5V15H10v-1.5ZM13 3h-2.5v2.5H13V3ZM1 15h6.5V8.5H1V15Zm5.125-1.375h-3.75v-3.75h3.75v3.75Z"
      fill={color}
    />
  </Svg>
);

export default QrCode;
