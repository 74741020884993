import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Trash = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8 9h16M8 9v18h16V9M8 9H6m18 0h2M13 5h6m-7 8v10m8-10v10m-4-10.453V23.5"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Trash;
