import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Item = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M19.758 3h-5.32a.626.626 0 0 0-.44.182L3.362 13.813a1.248 1.248 0 0 0 0 1.761l5.063 5.063a1.249 1.249 0 0 0 1.761 0L20.818 10.01A.626.626 0 0 0 21 9.568V4.246A1.24 1.24 0 0 0 19.758 3Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17 7.25a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
      stroke={color}
      strokeWidth={1.5}
    />
  </Svg>
);

export default Item;
