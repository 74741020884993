import * as React from "react";
import Svg, { Path } from "react-native-svg";

const People = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12.564 5.25C12.472 6.52 11.529 7.5 10.5 7.5c-1.028 0-1.972-.979-2.062-2.25C8.345 3.928 9.263 3 10.5 3c1.239 0 2.157.952 2.063 2.25Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.5 9.5c-2.037 0-3.995 1.012-4.486 2.982-.065.26.099.518.366.518h8.24c.267 0 .43-.258.366-.518-.49-2.002-2.45-2.982-4.486-2.982Z"
      stroke={color}
      strokeMiterlimit={10}
    />
    <Path
      d="M6.25 5.81c-.073 1.016-.835 1.815-1.656 1.815-.822 0-1.585-.8-1.657-1.814C2.863 4.755 3.604 4 4.594 4c.989 0 1.73.774 1.656 1.81Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6.438 9.56c-.564-.258-1.186-.357-1.844-.357-1.625 0-3.19.808-3.583 2.381-.051.209.08.414.293.414h3.509"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default People;
