import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const User = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15.977 6.997c-.177 2.259-1.998 3.998-3.984 3.998-1.987 0-3.811-1.739-3.985-3.997C7.827 4.647 9.6 3 11.993 3c2.392 0 4.165 1.691 3.984 3.997Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12 13.5c-3.394 0-6.659 2.042-7.476 6.018-.109.527.164 1.047.61 1.047h13.732c.447 0 .718-.52.61-1.047C18.66 15.479 15.396 13.5 12 13.5Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default User;
