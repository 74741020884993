import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Search = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m17.5 17.5-3.797-3.797m0 0a6.562 6.562 0 1 0-9.28-9.28 6.562 6.562 0 0 0 9.28 9.28Z"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default Search;
