import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Close = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M24 8 8 24m16 0L8 8l16 16Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Close;
