import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Whatsapp = ({ color }) => (
  <Svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.44 5.152A10.413 10.413 0 0 0 12.044 2.1C6.281 2.1 1.59 6.768 1.588 12.506a10.341 10.341 0 0 0 1.396 5.203L1.5 23.1l5.543-1.447a10.483 10.483 0 0 0 4.997 1.266h.004c5.762 0 10.453-4.669 10.456-10.407a10.32 10.32 0 0 0-3.06-7.36Zm-7.396 16.01h-.004a8.706 8.706 0 0 1-4.423-1.205l-.317-.188-3.29.859.879-3.192-.207-.328a8.6 8.6 0 0 1-1.329-4.602c0-4.768 3.9-8.648 8.694-8.648a8.672 8.672 0 0 1 8.688 8.655c-.002 4.769-3.9 8.65-8.69 8.65Zm4.767-6.477c-.261-.13-1.547-.76-1.785-.846-.238-.087-.414-.13-.588.13s-.675.844-.827 1.02c-.153.175-.305.194-.566.064-.261-.13-1.104-.404-2.102-1.29-.776-.69-1.3-1.541-1.453-1.801-.152-.26-.016-.402.115-.53.117-.118.26-.305.392-.457.13-.151.174-.26.26-.434.087-.173.044-.325-.02-.455-.066-.13-.589-1.41-.806-1.93-.213-.508-.428-.439-.588-.447a10.61 10.61 0 0 0-.501-.01.963.963 0 0 0-.697.326c-.24.26-.914.89-.914 2.17 0 1.278.937 2.516 1.067 2.69.129.173 1.842 2.8 4.463 3.926.486.208.984.39 1.49.547.625.198 1.195.17 1.645.103.502-.074 1.546-.629 1.764-1.236.217-.608.217-1.128.152-1.237-.065-.108-.24-.173-.501-.303Z"
      fill={color}
    />
  </Svg>
);

export default Whatsapp;
