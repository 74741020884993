import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Storage = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m3.164 4.413-.17-.47a.5.5 0 0 0-.182.115l.352.356Zm0 7.143h-.5a.5.5 0 0 0 .33.47l.17-.47Zm4.803 1.746-.17.47a.5.5 0 0 0 .335.002l-.165-.472Zm5-1.746.166.472a.5.5 0 0 0 .335-.473h-.5Zm0-7.142.415-.28a.5.5 0 0 0-.25-.193l-.164.473Zm-5-1.747.165-.472a.5.5 0 0 0-.336.002l.171.47Zm1.7 6.077-.415.28a.5.5 0 0 0 .585.19l-.17-.47Zm5-1.814.17.47a.5.5 0 0 0 .244-.75l-.414.28ZM.982 5.872a.5.5 0 1 0 .703.71l-.703-.71Zm12.486 1.675a.5.5 0 0 0-1 0h1ZM2.993 12.025l4.803 1.747.342-.94-4.803-1.746-.342.94Zm5.14 1.75 5-1.748-.33-.943-5 1.746.33.944Zm5-9.834-5-1.746-.33.944 5 1.747.33-.945ZM7.796 2.197 2.993 3.944l.342.94 4.803-1.747-.342-.94Zm5.001 1.746-5 1.815.34.94 5.001-1.814-.34-.94ZM8.144 5.76 3.34 3.946l-.353.935L7.79 6.695l.353-.935Zm-.677.468v7.074h1V6.228h-1Zm.086.28 1.699 2.516.829-.56-1.7-2.516-.828.56Zm2.284 2.706 5-1.814-.34-.94-5.001 1.814.34.94Zm5.244-2.563-1.699-2.517-.829.56 1.7 2.516.828-.56ZM2.812 4.058.982 5.872l.703.71L3.516 4.77l-.704-.71Zm-.148.356v1.235h1V4.413h-1Zm0 1.235v5.907h1V5.649h-1Zm10.804 5.907v-4.01h-1v4.01h1Z"
      fill={color}
    />
  </Svg>
);

export default Storage;
