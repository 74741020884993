import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Time = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16 4C9.375 4 4 9.375 4 16s5.375 12 12 12 12-5.375 12-12S22.625 4 16 4Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <Path
      d="M16 9v7.781L20 19"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Time;
