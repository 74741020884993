import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Comment = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3.333 8.333v-2.11c0-.613.12-1.245.357-1.896a8.422 8.422 0 0 1 .94-1.84A7.38 7.38 0 0 1 5.913 1l1.909 1.104a11.219 11.219 0 0 0-.883 1.826c-.247.652-.37 1.416-.37 2.294v2.11H3.332Zm4.845 0v-2.11c0-.613.119-1.245.357-1.896a8.422 8.422 0 0 1 .94-1.84c.39-.586.817-1.081 1.282-1.487l1.91 1.104a11.22 11.22 0 0 0-.884 1.826c-.247.652-.37 1.416-.37 2.294v2.11H8.178Z"
      fill={color}
    />
  </Svg>
);

export default Comment;
