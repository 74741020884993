import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Truck = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M29 23v1a1 1 0 0 0 1-1h-1Zm0-7h1a1 1 0 0 0-.142-.514L29 16Zm-3-5 .858-.514A1 1 0 0 0 26 10v1Zm-5 0v-1a1 1 0 0 0-1 1h1Zm0 9.136.284.959-.284-.96ZM5 8h14V6H5v2ZM4 21V9H2v12h2Zm26 2v-7h-2v7h2Zm-.142-7.514-3-5-1.716 1.028 3 5 1.716-1.028ZM26 10h-5v2h5v-2Zm-6 1v5h2v-5h-2Zm0-2v7h2V9h-2Zm9 6h-8v2h8v-2ZM6 22H5v2h1v-2Zm1 1c0-.34.11-.872.412-1.29.264-.368.714-.71 1.588-.71v-2c-1.526 0-2.576.658-3.212 1.54A4.35 4.35 0 0 0 5 23h2Zm2-2c.874 0 1.324.342 1.588.71.302.418.412.95.412 1.29h2c0-.66-.19-1.628-.789-2.46C11.576 19.659 10.526 19 9 19v2Zm0 4c-.874 0-1.324-.342-1.588-.71A2.355 2.355 0 0 1 7 23H5c0 .66.19 1.628.788 2.46C6.424 26.341 7.474 27 9 27v-2Zm2-2c0 .34-.11.872-.412 1.29-.264.368-.714.71-1.588.71v2c1.526 0 2.576-.658 3.211-1.54A4.35 4.35 0 0 0 13 23h-2Zm11-2c.874 0 1.324.342 1.588.71.302.418.412.95.412 1.29h2c0-.66-.19-1.628-.788-2.46C24.576 19.659 23.526 19 22 19v2Zm0 4c-.874 0-1.324-.342-1.588-.71A2.355 2.355 0 0 1 20 23h-2c0 .66.19 1.628.788 2.46.636.882 1.686 1.54 3.212 1.54v-2Zm2-2c0 .34-.11.872-.412 1.29-.264.368-.714.71-1.588.71v2c1.526 0 2.576-.658 3.212-1.54A4.35 4.35 0 0 0 26 23h-2Zm-4 0c0-.653.336-1.625 1.284-1.905l-.568-1.918C18.517 19.829 18 21.967 18 23h2Zm1.284-1.905c.192-.057.428-.095.716-.095v-2c-.464 0-.892.06-1.284.177l.568 1.918ZM20 16v4.136h2V16h-2Zm9 6h-4v2h4v-2Zm-10 0h-7v2h7v-2ZM2 21a3 3 0 0 0 3 3v-2a1 1 0 0 1-1-1H2ZM19 8a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2ZM5 6a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1V6Z"
      fill={color}
    />
  </Svg>
);

export default Truck;
