import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Trash = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M5 5.625h10m-10 0v11.25h10V5.625m-10 0H3.75m11.25 0h1.25m-8.125-2.5h3.75m-4.375 5v6.25m5-6.25v6.25M10 7.842v6.845"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Trash;
