import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Checklist = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M10.75 7.25h9M10.75 12.25h9M10.75 17.25h9"
    />
    <Path
      d="M4 7.143 5.286 8 7 6M4 12.143 5.286 13 7 11M4 17.143 5.286 18 7 16"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Checklist;
