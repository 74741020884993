import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Pin = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M25.333 13.037c0 5.728-10 16.296-10 16.296s-10-10.568-10-16.296c0-5.729 4.477-10.37 10-10.37s10 4.643 10 10.37Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17.667 12.667a2.333 2.333 0 1 1-4.667 0 2.333 2.333 0 0 1 4.667 0Z"
      stroke={color}
      strokeWidth={2}
    />
  </Svg>
);

export default Pin;
