import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Pressable, View } from 'react-native';
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { createStyles } from './styles';
import { useStyleguide } from '../../hooks/styleguide';
import Icon from '../Icon';
import Separator from '../Separator';
import Typography from '../Typography';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const ItemAccordion = ({
  index,
  indexOpened,
  onIndexOpenedChange,
  accordion,
  contentContainerStyle = {},
}) => {
  const { styleguide } = useStyleguide();

  const animationDuration = 200;

  const animate = useSharedValue(0);

  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const [titleHeight, setTitleHeight] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const containerAnimated = useAnimatedStyle(() => {
    const height = interpolate(
      animate.value,
      [0, 1],
      [titleHeight + 24, titleHeight + descriptionHeight + 49],
    );

    const borderColor = interpolateColor(
      animate.value,
      [0, 1],
      [styleguide.colors.border, styleguide.brand],
    );

    return {
      width: '100%',
      padding: 12,
      borderWidth: 1,
      borderColor,
      backgroundColor: styleguide.colors.pressable,
      overflow: 'hidden',
      borderRadius: 6,
      opacity: descriptionHeight === 0 ? 0 : 1,
      ...(descriptionHeight !== 0 && titleHeight !== 0 && { height }),
    };
  }, [
    styleguide.colors.pressable,
    styleguide.colors.border,
    styleguide.brand,
    titleHeight,
    descriptionHeight,
  ]);

  const iconAnimated = useAnimatedStyle(() => {
    const rotate = interpolate(animate.value, [0, 1], [0, 180]);

    return {
      transform: [{ rotate: `${rotate}deg` }],
      position: 'absolute',
      right: 0,
    };
  }, []);

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  const handlePress = useCallback(() => {
    if (indexOpened === index) {
      animate.value = withTiming(0, { duration: animationDuration });
      onIndexOpenedChange(null);
    } else {
      animate.value = withTiming(1, { duration: animationDuration });
      onIndexOpenedChange(index);
    }
  }, [onIndexOpenedChange, index, indexOpened]);

  useEffect(() => {
    if (indexOpened !== index) {
      animate.value = withTiming(0, { duration: animationDuration });
    }
  }, [indexOpened, index]);

  return (
    <AnimatedPressable
      style={[containerAnimated, contentContainerStyle]}
      onPress={handlePress}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}>
      <View style={styles.heading}>
        <Typography
          onLayout={(e) => {
            const height = e.nativeEvent.layout.height;

            if (titleHeight !== height) {
              setTitleHeight(height);
            }
          }}
          contentContainerStyle={{ position: 'absolute', right: 26, left: 0 }}
          size="small"
          name="title"
          text={`${accordion.title}`}
        />
        <Animated.View style={iconAnimated}>
          <Icon size="20" name="down" />
        </Animated.View>
      </View>

      <Separator border="dashed" contentContainerStyle={{ marginTop: titleHeight + 12 }} />

      <View style={{ flex: 1, marginTop: 12 }}>
        <Typography
          onLayout={(e) => {
            const height = e.nativeEvent.layout.height;

            if (descriptionHeight !== height) {
              setDescriptionHeight(height);
            }
          }}
          contentContainerStyle={{ position: 'absolute' }}
          color={styleguide.colors.typographySecondary}
          size="small"
          name="body"
          text={accordion.body}
        />
      </View>

      {isHovered && <View style={styles.hover} />}
    </AnimatedPressable>
  );
};

export default ItemAccordion;
