import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Cost = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12.398 18.004a3.833 3.833 0 0 0 1.216-.298c.35-.163.643-.37.88-.618.245-.249.427-.536.546-.863.126-.334.189-.7.189-1.098a1.966 1.966 0 0 0-.724-1.577 3.003 3.003 0 0 0-.786-.48c-.3-.134-.622-.258-.964-.372l-.179 2.653-.089 1.327-.089 1.326Zm-.346-12.317a3.993 3.993 0 0 0-1.184.277c-.329.142-.601.32-.818.533a2.05 2.05 0 0 0-.482.724c-.105.263-.157.54-.157.831 0 .341.06.64.178.895.119.249.283.47.493.66.21.193.454.36.733.502.28.142.584.27.912.383l.082-1.201.08-1.202.082-1.2.081-1.202Zm.829 5.178c.503.164.999.338 1.488.522.49.185.93.423 1.32.714.392.291.706.657.944 1.098.245.433.367.976.367 1.63 0 .64-.105 1.24-.314 1.8a4.318 4.318 0 0 1-.923 1.482 4.656 4.656 0 0 1-1.478 1.044c-.58.263-1.244.42-1.992.469l-.125 1.864a.526.526 0 0 1-.157.352.478.478 0 0 1-.367.16h-.692l.157-2.397a6.793 6.793 0 0 1-2.317-.64A6.39 6.39 0 0 1 7 17.642l.566-.874a.683.683 0 0 1 .21-.192.524.524 0 0 1 .272-.074c.133 0 .283.067.451.202.168.135.374.288.618.459.252.17.545.337.88.5.343.164.752.274 1.227.33l.189-2.828.189-2.83c-.49-.149-.972-.316-1.447-.5a4.8 4.8 0 0 1-1.268-.735 3.52 3.52 0 0 1-.902-1.14c-.223-.455-.335-1.027-.335-1.716A3.963 3.963 0 0 1 8.824 5.41a4.506 4.506 0 0 1 1.415-.938c.559-.249 1.198-.387 1.918-.416l.105-1.534a.592.592 0 0 1 .147-.362.49.49 0 0 1 .377-.16h.692l-.136 2.1c.726.091 1.355.283 1.886.574a5.974 5.974 0 0 1 1.426 1.066l-.45.703c-.14.213-.301.32-.483.32-.098 0-.22-.043-.367-.128-.14-.092-.31-.199-.513-.32a5.036 5.036 0 0 0-.703-.351 3.969 3.969 0 0 0-.912-.256l-.173 2.579-.173 2.578Z"
      fill={color}
    />
  </Svg>
);

export default Cost;
