import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Chart = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10.004 3.15a7.502 7.502 0 0 1 8.123 7.475 7.502 7.502 0 0 1-14.644 2.291"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <Path
      d="M10 1.875A8.125 8.125 0 0 0 1.875 10a8.097 8.097 0 0 0 .707 3.32L10 10V1.875Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Chart;
