import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Bell = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M19.997 24v1a4.002 4.002 0 0 1-3.996 4 3.995 3.995 0 0 1-3.997-4v-1m14.718-2.036C25.118 20 23.986 19 23.986 13.584c0-4.959-2.53-6.726-4.613-7.584-.277-.114-.537-.375-.622-.66C18.387 4.097 17.363 3 16.002 3c-1.362 0-2.386 1.097-2.748 2.342-.084.287-.345.544-.621.658-2.085.86-4.613 2.62-4.613 7.584C8.016 19 6.883 20 5.279 21.964 4.616 22.778 5.198 24 6.36 24h19.29c1.155 0 1.733-1.226 1.073-2.036Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Bell;
