import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Checkbox = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M5 1.25h10v-2.5H5v2.5ZM18.75 5v10h2.5V5h-2.5ZM15 18.75H5v2.5h10v-2.5ZM1.25 15V5h-2.5v10h2.5ZM5 18.75A3.75 3.75 0 0 1 1.25 15h-2.5A6.25 6.25 0 0 0 5 21.25v-2.5ZM18.75 15A3.75 3.75 0 0 1 15 18.75v2.5A6.25 6.25 0 0 0 21.25 15h-2.5ZM15 1.25A3.75 3.75 0 0 1 18.75 5h2.5A6.25 6.25 0 0 0 15-1.25v2.5ZM5-1.25A6.25 6.25 0 0 0-1.25 5h2.5A3.75 3.75 0 0 1 5 1.25v-2.5Z"
      fill={color}
    />
  </Svg>
);

export default Checkbox;
