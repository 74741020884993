import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Chart = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16.006 5.04c.33-.026.661-.04.997-.04 6.625 0 12 5.375 12 12s-5.375 12-12 12a12.005 12.005 0 0 1-11.43-8.334"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <Path
      d="M16 3C8.82 3 3 8.82 3 16a12.956 12.956 0 0 0 1.13 5.313L16 16V3Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Chart;
