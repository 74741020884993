import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Table = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M5.5 6.303V10m0-3.697h5m-5 0H3m7.5 0V10m0-3.697H13m0 0h1L11.161 3.03H4.968L2 6.303h1m10 0V13M3 6.303V13"
      stroke={color}
      strokeWidth={0.9}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
  </Svg>
);

export default Table;
