import * as React from "react";
import Svg, { Path } from "react-native-svg";

const EyeClosed = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M9.987 4.375c-3.045 0-6.168 1.762-8.626 5.286a.625.625 0 0 0-.011.694c1.89 2.957 4.97 5.27 8.637 5.27 3.626 0 6.77-2.32 8.663-5.283a.63.63 0 0 0 0-.683c-1.896-2.93-5.064-5.284-8.663-5.284Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10 13.125a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25Z"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
    />
    <Path
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      d="M3.384 3.457 16.25 16.323"
    />
  </Svg>
);

export default EyeClosed;
