import { StyleSheet } from 'react-native';

// import { responsiveMobile, responsiveNavigatorDrawer } from '../../constants/responsive';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      // paddingRight:
      //   styleguide.responsive === 'mobile' || styleguide.responsive === 'tablet'
      //     ? 0
      //     : responsiveMobile,
      // paddingLeft: styleguide.responsive === 'desktop' ? responsiveNavigatorDrawer : 0,
      // height: styleguide.windowDimensions.height,
      // top: 0,
      // bottom: 0,
      position: styleguide.platform.OS === 'web' ? 'fixed' : 'absolute',
      alignItems: 'center',
      backgroundColor: styleguide.colors.background,
    },

    modal: {
      // width: '100%',
      // height: '100%',
      // justifyContent: 'flex-end',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  });
