import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Truck = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M21.75 17.25V18a.75.75 0 0 0 .75-.75h-.75Zm0-5.25h.75a.75.75 0 0 0-.107-.386L21.75 12ZM19.5 8.25l.643-.386A.75.75 0 0 0 19.5 7.5v.75Zm-3.75 0V7.5a.75.75 0 0 0-.75.75h.75Zm0 6.852.213.719-.213-.72ZM3.75 6h10.5V4.5H3.75V6ZM3 15.75v-9H1.5v9H3Zm19.5 1.5V12H21v5.25h1.5Zm-.107-5.636-2.25-3.75-1.286.772 2.25 3.75 1.286-.772ZM19.5 7.5h-3.75V9h3.75V7.5Zm-4.5.75V12h1.5V8.25H15Zm0-1.5V12h1.5V6.75H15Zm6.75 4.5h-6v1.5h6v-1.5ZM4.5 16.5h-.75V18h.75v-1.5Zm.75.75c0-.255.083-.654.309-.968.198-.276.535-.532 1.191-.532v-1.5c-1.144 0-1.932.494-2.409 1.155a3.263 3.263 0 0 0-.591 1.845h1.5Zm1.5-1.5c.656 0 .993.256 1.191.532.226.314.309.713.309.968h1.5c0-.495-.142-1.22-.591-1.845-.477-.661-1.265-1.155-2.409-1.155v1.5Zm0 3c-.656 0-.993-.256-1.191-.532a1.766 1.766 0 0 1-.309-.968h-1.5c0 .495.142 1.22.591 1.845.477.661 1.265 1.155 2.409 1.155v-1.5Zm1.5-1.5c0 .255-.083.654-.309.968-.198.276-.535.532-1.191.532v1.5c1.144 0 1.932-.494 2.409-1.155.449-.624.591-1.35.591-1.845h-1.5Zm8.25-1.5c.656 0 .993.256 1.191.532.226.314.309.713.309.968h1.5c0-.495-.142-1.22-.591-1.845-.477-.661-1.265-1.155-2.409-1.155v1.5Zm0 3c-.656 0-.993-.256-1.191-.532A1.766 1.766 0 0 1 15 17.25h-1.5c0 .495.142 1.22.591 1.845.477.661 1.265 1.155 2.409 1.155v-1.5Zm1.5-1.5c0 .255-.083.654-.309.968-.198.276-.535.532-1.191.532v1.5c1.144 0 1.932-.494 2.409-1.155.449-.624.591-1.35.591-1.845H18Zm-3 0c0-.49.252-1.218.963-1.43l-.426-1.437c-1.649.489-2.037 2.091-2.037 2.867H15Zm.963-1.43c.144-.042.32-.07.537-.07v-1.5c-.348 0-.669.046-.963.133l.426 1.438ZM15 12v3.102h1.5V12H15Zm6.75 4.5h-3V18h3v-1.5Zm-7.5 0H9V18h5.25v-1.5ZM1.5 15.75A2.25 2.25 0 0 0 3.75 18v-1.5a.75.75 0 0 1-.75-.75H1.5ZM14.25 6a.75.75 0 0 1 .75.75h1.5a2.25 2.25 0 0 0-2.25-2.25V6ZM3.75 4.5A2.25 2.25 0 0 0 1.5 6.75H3A.75.75 0 0 1 3.75 6V4.5Z"
      fill={color}
    />
  </Svg>
);

export default Truck;
