import * as WebBrowser from 'expo-web-browser';
import React, { useMemo } from 'react';
import { Image, View } from 'react-native';
import { MaskService } from 'react-native-masked-text';

import { createStyles } from './styles';
import { useAuthentication } from '../../hooks/authentication';
import { useStyleguide } from '../../hooks/styleguide';
import ButtonText from '../ButtonText';
import Typography from '../Typography';

const ItemCover = ({ cover }) => {
  const { styleguide } = useStyleguide();
  const { authentication } = useAuthentication();

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  const uriBackground = useMemo(() => {
    return {
      mobile: cover.backgroundMobile.uri.high,
      desktop: cover.backgroundDesktop.uri.high,
    }[styleguide.responsive];
  }, [cover.backgroundMobile, cover.backgroundDesktop, styleguide.responsive]);

  return (
    <View style={styles.container}>
      <Image source={{ uri: uriBackground }} resizeMode="cover" style={styles.background} />
      <View style={styles.filter} />
      <Image source={{ uri: cover.image.uri.high }} style={styles.image} />
      <View style={styles.content}>
        <Typography color="#FFFFFF" size="large" name="title" text={cover.title} />
        <Typography
          color="#FFFFFF"
          contentContainerStyle={{ marginTop: 8 }}
          size="medium"
          name="body"
          text={cover.description}
        />
        <ButtonText
          onPress={() => {
            WebBrowser.openBrowserAsync(
              `https://wa.me/${authentication.store.whatsapp.countryCode.slice(
                1,
              )}${MaskService.toRawValue(
                'cel-phone',
                authentication.store.whatsapp.number,
              )}?text=${encodeURI(`Olá, quero tirar uma dúvida`)}`,
            );
          }}
          color="#FFFFFF"
          contentContainerStyle={{ marginTop: 16 }}
          variant="primary"
          size="medium"
          title={cover.button.title}
        />
      </View>
    </View>
  );
};

export default ItemCover;
