import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Whatsapp = ({ color }) => (
  <Svg width={32} height={33} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.92 6.869A13.884 13.884 0 0 0 16.06 2.8C8.375 2.8 2.12 9.024 2.117 16.675a13.788 13.788 0 0 0 1.861 6.938L2 30.8l7.39-1.93a13.977 13.977 0 0 0 6.663 1.688h.006c7.683 0 13.937-6.224 13.941-13.875a13.761 13.761 0 0 0-4.08-9.814ZM16.06 28.216h-.005a11.61 11.61 0 0 1-5.898-1.607l-.423-.25-4.386 1.145 1.171-4.256-.276-.437a11.466 11.466 0 0 1-1.771-6.136c0-6.358 5.2-11.531 11.592-11.531a11.562 11.562 0 0 1 11.583 11.54c-.002 6.359-5.2 11.532-11.587 11.532Zm6.355-8.637c-.348-.173-2.062-1.012-2.38-1.128-.317-.115-.551-.173-.783.174-.232.348-.9 1.125-1.104 1.36-.203.234-.406.26-.754.086-.348-.174-1.471-.54-2.802-1.72-1.035-.92-1.734-2.055-1.937-2.402-.203-.347-.022-.535.152-.708.157-.155.348-.405.523-.607.174-.203.232-.348.348-.579.116-.231.058-.434-.029-.607-.087-.173-.784-1.88-1.074-2.574-.283-.676-.57-.585-.783-.595-.204-.01-.438-.013-.668-.013a1.282 1.282 0 0 0-.93.434c-.319.348-1.219 1.188-1.219 2.893 0 1.705 1.25 3.355 1.423 3.586.172.231 2.456 3.733 5.95 5.235.65.278 1.312.521 1.987.73.834.264 1.593.227 2.194.137.669-.099 2.062-.838 2.351-1.648.29-.81.29-1.504.203-1.648-.086-.145-.319-.232-.668-.405Z"
      fill={color}
    />
  </Svg>
);

export default Whatsapp;
