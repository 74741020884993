import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
const Tiktok = ({ color }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <Path
      fill={color}
      d="M18.836 5.991a4.784 4.784 0 0 1-.413-.24 5.81 5.81 0 0 1-1.062-.903c-.792-.906-1.088-1.825-1.197-2.468h.005c-.091-.534-.054-.88-.048-.88h-3.606v13.946c0 .187 0 .372-.008.555 0 .023-.002.044-.004.068 0 .01 0 .02-.002.031v.008a3.062 3.062 0 0 1-1.54 2.43 3.01 3.01 0 0 1-1.493.395 3.052 3.052 0 0 1-3.042-3.063 3.052 3.052 0 0 1 3.042-3.062c.318 0 .634.05.937.148l.004-3.672a6.699 6.699 0 0 0-5.162 1.51A7.078 7.078 0 0 0 3.703 12.7c-.152.262-.727 1.317-.796 3.029-.044.971.248 1.978.387 2.394v.009c.087.245.426 1.08.979 1.785a7.328 7.328 0 0 0 1.562 1.474v-.008l.009.008C7.59 22.577 9.525 22.5 9.525 22.5c.336-.014 1.458 0 2.733-.604 1.414-.67 2.219-1.668 2.219-1.668a6.934 6.934 0 0 0 1.209-2.01c.326-.857.435-1.886.435-2.297V8.52c.044.026.627.412.627.412s.839.538 2.149.888c.94.25 2.205.302 2.205.302v-3.58c-.443.048-1.344-.092-2.266-.552Z"
    />
  </Svg>
);
export default Tiktok;
