import React, { createContext, useState, useContext, useRef, useCallback } from 'react';

const ToastContext = createContext({});

const ToastProvider = ({ children }) => {
  const initialToast = {
    type: '',
    icon: '',
    message: '',
  };

  const toastRef = useRef();

  const [toast, setToast] = useState(initialToast);

  const showToast = useCallback(({ type, message, icon = '' }) => {
    setToast({ type, message, icon });

    toastRef.current.show();
  }, []);

  return (
    <ToastContext.Provider
      value={{
        initialToast,
        toastRef,
        toast,
        setToast,
        showToast,
      }}>
      {children}
    </ToastContext.Provider>
  );
};

function useToast() {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within as ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
