import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Camera = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M6 7.5h.563c.404 0 .742-.224 1.005-.53l1.248-1.972C9.08 4.69 9.44 4.5 9.844 4.5h4.312c.404 0 .765.191 1.028.498l1.248 1.971c.263.307.647.531 1.052.531h2.766a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5H3.75a1.5 1.5 0 0 1-1.5-1.5V9a1.5 1.5 0 0 1 1.5-1.5h.75m1.5 0V6H4.5v1.5m1.5 0H4.5"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12 16.5A3.75 3.75 0 1 0 12 9a3.75 3.75 0 0 0 0 7.5Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Camera;
