import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const AddItem = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.838 2.667h-3.546a.417.417 0 0 0-.294.12l-7.09 7.088a.832.832 0 0 0 0 1.174l3.376 3.375a.833.833 0 0 0 1.174 0l7.087-7.084a.417.417 0 0 0 .121-.294V3.498a.827.827 0 0 0-.828-.831v0Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="M12 5.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Z" stroke={color} />
    <Path d="M3.5 1.5v2m0 2v-2m0 0h2m-2 0h-2" stroke={color} strokeLinecap="round" />
  </Svg>
);

export default AddItem;
