import React, { createContext, useState, useContext } from 'react';

const AuthenticationContext = createContext({});

const AuthenticationProvider = ({ children }) => {
  const initialAuthentication = {
    user: {},
    store: {},
    permission: {},
  };

  const [authentication, setAuthentication] = useState(initialAuthentication);

  return (
    <AuthenticationContext.Provider
      value={{
        initialAuthentication,
        authentication,
        setAuthentication,
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

function useAuthentication() {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error('useAuthentication must be used within as AuthenticationProvider');
  }

  return context;
}

export { AuthenticationProvider, useAuthentication };
