import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Cost = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8.265 12.003c.308-.029.578-.095.811-.2.233-.108.429-.245.587-.411.163-.166.284-.358.363-.575.084-.223.126-.467.126-.732a1.31 1.31 0 0 0-.482-1.052 2.002 2.002 0 0 0-.524-.32c-.2-.09-.415-.172-.643-.248l-.119 1.769-.06.884-.059.885Zm-.23-8.212c-.303.028-.566.09-.79.185-.219.094-.4.213-.545.355a1.367 1.367 0 0 0-.321.483c-.07.175-.105.36-.105.554 0 .227.04.426.119.597.079.166.188.312.328.44s.303.24.49.334c.186.095.388.18.607.256l.054-.801.054-.801.055-.801.054-.8Zm.552 3.452c.335.11.666.225.992.348.326.124.62.282.88.476.261.195.471.438.63.732.163.289.244.651.244 1.087 0 .426-.07.826-.21 1.2-.14.374-.344.704-.614.988a3.105 3.105 0 0 1-.986.696c-.386.175-.829.28-1.328.313l-.083 1.243a.35.35 0 0 1-.105.234.318.318 0 0 1-.245.107h-.461l.105-1.599a4.527 4.527 0 0 1-1.545-.426 4.26 4.26 0 0 1-1.194-.88l.377-.583a.455.455 0 0 1 .14-.128.35.35 0 0 1 .181-.05c.089 0 .189.045.3.135a4.786 4.786 0 0 0 1 .64c.228.108.5.182.818.22l.126-1.886.125-1.886c-.326-.1-.647-.211-.964-.334a3.202 3.202 0 0 1-.846-.49 2.347 2.347 0 0 1-.6-.76c-.15-.303-.224-.685-.224-1.144a2.642 2.642 0 0 1 .783-1.89c.256-.255.57-.464.943-.625a3.46 3.46 0 0 1 1.279-.277l.07-1.023a.395.395 0 0 1 .097-.241.326.326 0 0 1 .252-.107h.461l-.09 1.4c.484.061.903.19 1.257.383.359.194.676.431.95.71l-.3.47c-.093.141-.2.213-.321.213a.519.519 0 0 1-.245-.086 8.83 8.83 0 0 0-.342-.213 3.356 3.356 0 0 0-.469-.234 2.645 2.645 0 0 0-.608-.17l-.115 1.718-.115 1.72Z"
      fill={color}
    />
  </Svg>
);

export default Cost;
