import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Table = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8.25 9.454V15m0-5.546h7.5m-7.5 0H4.5m11.25 0V15m0-5.546h3.75m0 0H21l-4.258-4.909h-9.29L3 9.455h1.5m15 0V19.5M4.5 9.454V19.5"
      stroke={color}
      strokeWidth={1.35}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
  </Svg>
);

export default Table;
