import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Home = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M9.5 14H14V6.5L8 2 2 6.5V14h4.5m3 0V9.5h-3V14m3 0h-3"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Home;
