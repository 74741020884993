import * as React from "react";
import Svg, { Path } from "react-native-svg";

const LockClosed = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15.75 9h1.5a2.25 2.25 0 0 1 2.25 2.25v8.25a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5v-8.25A2.25 2.25 0 0 1 6.75 9h1.5m7.5 0V6c0-2.25-1.5-3.75-3.75-3.75s-3.75 1.5-3.75 3V9m7.5 0h-7.5"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LockClosed;
