import * as React from "react";
import Svg, { Mask, Path } from "react-native-svg";

const PaymentReceipt = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Mask id="a" fill="#fff">
      <Path d="M1 1.955C1 1.427 1.427 1 1.955 1h12.09c.528 0 .955.427.955.955v11.17a.955.955 0 0 1-1.516.773L11.5 12.454l-2.377 1.73a1.91 1.91 0 0 1-2.246 0L4.5 12.453l-1.984 1.444A.955.955 0 0 1 1 13.126V1.955Z" />
    </Mask>
    <Path
      d="m11.5 12.454.561-.771a.954.954 0 0 0-1.122 0l.561.771Zm-7 0 .561-.771a.954.954 0 0 0-1.122 0l.561.771Zm2.377 1.73-.561.771.561-.772Zm-4.361-.286-.561-.772.561.772ZM14.046.045H1.955v1.91h12.09V.045Zm0 1.91v11.17h1.909V1.956h-1.91Zm0 11.17-1.985-1.442-1.122 1.543 1.984 1.443 1.123-1.543Zm-4.362 1.83 2.377-1.729-1.122-1.543L8.56 13.41l1.123 1.544ZM3.94 13.226l2.377 1.73 1.123-1.545-2.378-1.728-1.122 1.543Zm-.862 1.443 1.984-1.443-1.122-1.543-1.984 1.443 1.122 1.543ZM.045 1.955v11.17h1.91V1.956H.045ZM8.561 13.41a.955.955 0 0 1-1.122 0l-1.123 1.544c1.004.73 2.364.73 3.368 0l-1.123-1.544Zm5.485-.285-1.123 1.543c1.261.918 3.031.017 3.031-1.543h-1.909Zm-12.091 0H.045c0 1.56 1.77 2.461 3.032 1.543l-1.122-1.543Zm12.09-11.171h1.91a1.91 1.91 0 0 0-1.91-1.91v1.91ZM1.956.045a1.91 1.91 0 0 0-1.91 1.91h1.91V.045Z"
      fill={color}
      mask="url(#a)"
    />
    <Path
      d="M8.284 9.287c.235-.034.412-.114.53-.24a.72.72 0 0 0 .177-.507.497.497 0 0 0-.045-.218.55.55 0 0 0-.121-.169.793.793 0 0 0-.184-.129 1.54 1.54 0 0 0-.232-.103l-.125 1.366ZM8 5.532a1.19 1.19 0 0 0-.32.084.7.7 0 0 0-.217.137.544.544 0 0 0-.122.18.57.57 0 0 0-.037.206.59.59 0 0 0 .04.225c.03.064.07.121.122.173a.908.908 0 0 0 .184.14c.072.039.15.075.236.106L8 5.532Zm.494 1.45c.117.035.235.073.353.114.118.04.23.085.339.137.108.051.208.111.302.18.093.069.174.15.243.243.07.09.126.196.165.317.04.12.06.257.06.412 0 .228-.04.444-.119.648-.076.201-.189.38-.338.538-.15.154-.333.28-.549.379a2.149 2.149 0 0 1-.744.177l-.044.5a.277.277 0 0 1-.08.173.252.252 0 0 1-.192.078h-.372l.07-.777c-.14-.02-.279-.05-.416-.089a3.366 3.366 0 0 1-.39-.147 3.045 3.045 0 0 1-.354-.195 2.087 2.087 0 0 1-.298-.24l.313-.46a.301.301 0 0 1 .11-.095.283.283 0 0 1 .144-.04c.056 0 .115.016.177.05.061.035.128.077.202.126.076.047.16.094.254.144.096.049.206.087.332.114l.14-1.528a3.125 3.125 0 0 1-.688-.25 1.888 1.888 0 0 1-.303-.188 1.22 1.22 0 0 1-.401-.608 1.61 1.61 0 0 1-.06-.456 1.476 1.476 0 0 1 .446-1.042 1.76 1.76 0 0 1 .542-.35c.213-.093.459-.147.736-.162l.04-.438a.263.263 0 0 1 .081-.17A.26.26 0 0 1 8.387 4h.375l-.066.722a2.157 2.157 0 0 1 1.167.585l-.25.368a.403.403 0 0 1-.11.125.259.259 0 0 1-.14.037.384.384 0 0 1-.133-.03 7.476 7.476 0 0 1-.166-.077 2.777 2.777 0 0 0-.206-.095 1.66 1.66 0 0 0-.236-.081l-.128 1.428Z"
      fill={color}
    />
  </Svg>
);

export default PaymentReceipt;
