import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Menu = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      d="M3 6.531h8.667M3 15.864h24.667M3 25.198h18"
    />
  </Svg>
);

export default Menu;
