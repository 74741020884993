import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { useEffect } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import 'react-native-gesture-handler';

import { enviroment, firebaseConfig } from './constants/firebase';
import { AlertProvider } from './hooks/alert';
import { AuthenticationProvider } from './hooks/authentication';
import { CollectionProvider } from './hooks/collection';
import { DatabaseProvider } from './hooks/database';
import { DocumentProvider } from './hooks/document';
import { NavigationProvider } from './hooks/navigation';
import { StyleguideProvider } from './hooks/styleguide';
import { ToastProvider } from './hooks/toast';
import { UrlProvider } from './hooks/url';
import Routes from './routes';

export default function App() {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const functions = getFunctions();

  useEffect(() => {
    // AsyncStorage.clear();
    // auth.signOut();
    // LogBox.ignoreLogs([
    //   "Setting a timer",
    //   "AsyncStorage has been extracted from react-native core",
    // ]);

    if (enviroment === 'dev') {
      connectAuthEmulator(auth, 'http://192.168.0.196:9099');
      connectFunctionsEmulator(functions, '192.168.0.196', 5001);
    }
  }, []);

  return (
    <SafeAreaProvider>
      <AuthenticationProvider>
        <StyleguideProvider>
          <UrlProvider>
            <DatabaseProvider>
              <CollectionProvider>
                <DocumentProvider>
                  <NavigationProvider>
                    <ToastProvider>
                      <AlertProvider>
                        <Routes />
                      </AlertProvider>
                    </ToastProvider>
                  </NavigationProvider>
                </DocumentProvider>
              </CollectionProvider>
            </DatabaseProvider>
          </UrlProvider>
        </StyleguideProvider>
      </AuthenticationProvider>
    </SafeAreaProvider>
  );
}
