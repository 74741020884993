import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Unavailable = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={10} cy={10} r={6.875} stroke={color} strokeWidth={1.25} />
    <Path
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      d="M4.167 16.616 16.616 4.167"
    />
  </Svg>
);

export default Unavailable;
