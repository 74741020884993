import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Comment = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M4.167 10.417V7.78c0-.767.148-1.557.445-2.371.297-.814.689-1.581 1.176-2.3A9.224 9.224 0 0 1 7.39 1.25l2.387 1.38a14.025 14.025 0 0 0-1.105 2.283c-.308.814-.463 1.77-.463 2.867v2.637H4.167Zm6.056 0V7.78c0-.767.148-1.557.445-2.371.297-.814.689-1.581 1.176-2.3a9.225 9.225 0 0 1 1.603-1.859l2.387 1.38a14.029 14.029 0 0 0-1.105 2.283c-.308.814-.463 1.77-.463 2.867v2.637h-4.043Z"
      fill={color}
    />
  </Svg>
);

export default Comment;
