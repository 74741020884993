import * as React from "react";
import Svg, { Mask, Path } from "react-native-svg";

const PaymentReceipt = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Mask id="a" fill="#fff">
      <Path d="M1.5 2.932c0-.791.641-1.432 1.432-1.432h18.136c.791 0 1.432.641 1.432 1.432v16.756c0 1.17-1.328 1.847-2.274 1.158l-2.976-2.164-3.566 2.593a2.864 2.864 0 0 1-3.368 0L6.75 18.682l-2.976 2.164c-.946.689-2.274.012-2.274-1.158V2.932Z" />
    </Mask>
    <Path
      d="m17.25 18.682.842-1.158a1.432 1.432 0 0 0-1.684 0l.842 1.158Zm-10.5 0 .842-1.158a1.432 1.432 0 0 0-1.684 0l.842 1.158Zm3.566 2.593-.842 1.158.842-1.158Zm9.91-.429.842-1.158-.842 1.158Zm-16.452 0-.842-1.158.842 1.158ZM21.068.068H2.932v2.864h18.136V.068Zm0 2.864v16.756h2.864V2.932h-2.864Zm0 16.756-2.976-2.164-1.684 2.316 2.976 2.164 1.684-2.316Zm-6.542 2.745 3.566-2.593-1.684-2.316-3.566 2.593 1.684 2.316ZM5.909 19.84l3.566 2.593 1.684-2.316-3.566-2.593-1.684 2.316Zm-1.292 2.164 2.976-2.164-1.684-2.316-2.976 2.164 1.684 2.316ZM.068 2.932v16.756h2.864V2.932H.068Zm12.774 17.185a1.432 1.432 0 0 1-1.684 0l-1.684 2.316a4.296 4.296 0 0 0 5.053 0l-1.685-2.316Zm8.226-.429-1.684 2.316c1.893 1.377 4.548.025 4.548-2.316h-2.864Zm-18.136 0H.068c0 2.34 2.655 3.693 4.548 2.316l-1.684-2.316ZM21.068 2.932h2.864A2.864 2.864 0 0 0 21.068.068v2.864ZM2.932.068A2.864 2.864 0 0 0 .068 2.932h2.864V.068Z"
      fill={color}
      mask="url(#a)"
    />
    <Path
      d="M12.426 13.93c.353-.05.618-.17.795-.358.177-.192.265-.446.265-.762a.745.745 0 0 0-.066-.326.828.828 0 0 0-.182-.254 1.194 1.194 0 0 0-.277-.194 2.31 2.31 0 0 0-.348-.154l-.187 2.049ZM12 8.298a1.784 1.784 0 0 0-.48.127 1.047 1.047 0 0 0-.326.205.817.817 0 0 0-.182.27.855.855 0 0 0-.055.31c0 .125.02.237.06.337a.897.897 0 0 0 .183.26c.077.076.169.146.276.209.106.059.224.112.353.16L12 8.297Zm.74 2.176c.177.052.354.109.53.172.177.059.347.127.509.204.162.077.313.168.453.27.14.104.261.225.364.365.107.136.19.295.249.475.058.18.088.387.088.619 0 .342-.059.666-.177.972-.114.301-.283.57-.508.806a2.665 2.665 0 0 1-.823.569 3.222 3.222 0 0 1-1.115.265l-.067.75a.417.417 0 0 1-.121.26.379.379 0 0 1-.287.117h-.558l.105-1.166a4.6 4.6 0 0 1-.624-.132 5.051 5.051 0 0 1-.586-.221 4.571 4.571 0 0 1-.53-.293 3.13 3.13 0 0 1-.447-.359l.47-.69a.452.452 0 0 1 .165-.144c.07-.04.142-.06.215-.06.085 0 .173.025.265.077.092.051.194.114.304.187.114.07.241.142.381.216.144.074.31.13.497.171l.21-2.292a9.71 9.71 0 0 1-.53-.16 4.687 4.687 0 0 1-.503-.216 2.83 2.83 0 0 1-.453-.281 1.831 1.831 0 0 1-.601-.911 2.414 2.414 0 0 1-.089-.685 2.215 2.215 0 0 1 .668-1.563 2.64 2.64 0 0 1 .812-.525c.32-.14.689-.22 1.105-.243l.06-.657a.394.394 0 0 1 .122-.254A.39.39 0 0 1 12.58 6h.564l-.1 1.082c.398.07.738.185 1.022.343.283.158.526.337.729.536l-.376.552a.602.602 0 0 1-.165.188.388.388 0 0 1-.21.055.576.576 0 0 1-.199-.044 11.233 11.233 0 0 1-.249-.116 4.156 4.156 0 0 0-.309-.144 2.493 2.493 0 0 0-.353-.121l-.194 2.142Z"
      fill={color}
    />
  </Svg>
);

export default PaymentReceipt;
