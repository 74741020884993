import React, { createContext, useState, useContext, useEffect, useRef } from 'react';

import { useDatabase } from './database';

const DocumentContext = createContext({});

const DocumentProvider = ({ children }) => {
  const { listenObject } = useDatabase();

  // const initialMedia = {
  //   height: '',
  //   width: '',
  //   uri: {
  //     high: '',
  //     medium: '',
  //     low: '',
  //     icon: '',
  //   },
  // };

  // const initialSection = {
  //   index: '',
  //   name: '',
  //   variant: '',
  // };

  // const initialContact = {
  //   avatar: {},
  //   name: '',
  //   phone: '',
  //   email: '',
  //   sector: '',
  // };

  // const initialPermission = {
  //   name: '',
  //   monitoring: [{ name: 'orders', permission: 'edit' }], // edit, visible, hidden
  //   catalog: [
  //     { name: 'products', permission: 'edit' },
  //     { name: 'components', permission: 'edit' },
  //   ],
  //   team: [{ name: 'squads', permission: 'edit' }],
  //   menu: [
  //     { name: 'printers', permission: 'edit' },
  //     { name: 'comercialInvoice', permission: 'edit' },
  //   ],
  //   saved: 'false',
  //   usersIds: [],
  // };

  // const initialSpecification = {
  //   componentId: '',
  //   quantity: '',
  // };

  // const initialVariation = {
  //   color: '',
  //   images: [],
  //   sizes: [{ sectionId: '', specifications: [], price: '', barcode: '' }],
  // };

  // const initialProduct = {
  //   variant: 'physical',
  //   name: '',
  //   complementId: '',
  //   unity: 'un',
  //   available: 'true',
  //   description: {
  //     variant: 'string', // string, image, html
  //     value: '',
  //   },
  //   variations: [
  //     {
  //       color: '',
  //       images: [
  //         {
  //           height: '1024',
  //           width: '1024',
  //           uri: {
  //             1: 'https://dugout.com/images/publishers/logos/gremio.png',
  //             2: 'https://dugout.com/images/publishers/logos/gremio.png',
  //             3: 'https://dugout.com/images/publishers/logos/gremio.png',
  //             4: 'https://dugout.com/images/publishers/logos/gremio.png',
  //           },
  //         },
  //       ],
  //       sizes: [{ sectionId: '', specifications: [], price: '1270', barcode: '' }],
  //     },
  //   ],
  //   sectionId: '',
  //   tagsId: [],
  //   index: '',
  //   saved: 'false',
  // };

  // const initialLanding = {
  //   productId: '',
  //   display: '',
  //   headline: '',
  //   media: {},
  //   transformationsDisplay: '',
  //   transformationsHeadline: '',
  //   transformations: [],
  //   reasonsDisplay: '',
  //   reasonsHeadline: '',
  //   reasons: [],
  //   featuresDisplay: '',
  //   featuresHeadline: '',
  //   features: [],
  //   consumersDisplay: '',
  //   consumersHeadline: '',
  //   consumers: [],
  //   feedbacksDisplay: '',
  //   feedbacksHeadline: '',
  //   feedbacks: [],
  //   offersDisplay: '',
  //   offersHeadline: '',
  //   offers: [],
  //   questionsDisplay: '',
  //   questionsHeadline: '',
  //   questions: [],

  //   // displayHeadlines: {
  //   //   data: [
  //   //     {
  //   //       display: '',
  //   //       headline: 'Passo a passo completo a um toque de distância. Rápido, prático e simples!',
  //   //     },
  //   //   ],
  //   // },
  // };

  // const initialPurchase = {
  //   quantity: '1',
  //   product: { ...initialProduct },
  //   selectedColor: { ...initialProduct.variations[0] },
  //   selectedSize: { ...initialProduct.variations[0].sizes[0] },
  //   selectedComplements: [],
  //   observation: '',
  // };

  // const initialAddress = {
  //   country: 'BR',
  //   zipCode: '',
  //   state: '',
  //   city: '',
  //   neighborhood: '',
  //   street: '',
  //   number: '',
  //   complement: '',
  //   latitude: '',
  //   longitude: '',
  // };

  // const initialCreditCard = {
  //   brand: '', // visa, mastercard, american-express, elo, hipercard
  //   maskedNumber: '',
  //   token: '',
  //   number: '',
  //   holder: '',
  //   expirationDate: '',
  //   cvv: '',
  // };

  // const initialPix = {
  //   qrCode: '',
  //   expiresAt: '',
  // };

  // const initialOrder = {
  //   variant: '', // landing, ecommerce, pdv, delivery, instore
  //   purchases: [],
  //   totalPrice: '',
  //   fullName: '',
  //   email: '',
  //   phone: '',
  //   document: '',
  //   address: {},
  //   paymentMethod: 'credit', // cash, debit, credit, pix, other
  //   creditCard: {},
  //   cardBrand: '', // visa, mastercard, amex, elo, hipercard, ''
  //   installments: '1',
  //   pix: {},
  //   totalPaid: '',
  //   saved: 'false',
  // };

  // export const initialBuyer = {
  //   cpf: '',
  //   birthDate: '',
  //   motherFullName: '',
  //   monthlyIncome: '',
  //   phone: '',
  //   address: {},
  //   status: '', // user, review, buyer
  // };

  // const initialReajustment = {
  //   document: {},
  //   variant: '',
  //   reajustments: [],
  //   saved: 'false',
  // };

  const productUnsubscribeRef = useRef(() => {});
  const landingUnsubscribeRef = useRef(() => {});
  const orderUnsubscribeRef = useRef(() => {});
  const reajustmentUnsubscribeRef = useRef(() => {});

  // const [image, setImage] = useState(initialMedia);
  // const [product, setProduct] = useState(initialProduct);
  // const [landing, setLanding] = useState(initialLanding);
  // const [purchase, setPurchase] = useState(initialPurchase);
  // const [address, setAddress] = useState(initialAddress);
  // const [creditCard, setCreditCard] = useState(initialCreditCard);
  // const [order, setOrder] = useState(initialOrder);
  // const [reajustment, setReajustment] = useState(initialReajustment);
  const [user, setUser] = useState({});

  // useEffect(() => {
  //   if (reajustment.id !== undefined) {
  //     reajustmentUnsubscribeRef.current = listenObject({
  //       collectionName: 'reajustments',
  //       objectId: reajustment.id,
  //       setHook: setReajustment,
  //     });

  //     return () => {
  //       reajustmentUnsubscribeRef.current();
  //     };
  //   }
  // }, [reajustment.id]);

  // useEffect(() => {
  //   if (order.id !== undefined) {
  //     orderUnsubscribeRef.current = listenObject({
  //       collectionName: 'orders',
  //       objectId: order.id,
  //       setHook: setOrder,
  //     });

  //     return () => {
  //       orderUnsubscribeRef.current();
  //     };
  //   }
  // }, [order.id]);

  // useEffect(() => {
  //   if (product.id !== undefined) {
  //     productUnsubscribeRef.current = listenObject({
  //       collectionName: 'products',
  //       objectId: product.id,
  //       setObject: setProduct,
  //     });
  //   } else {
  //     productUnsubscribeRef.current();
  //   }

  //   return () => {
  //     productUnsubscribeRef.current();
  //   };
  // }, [product.id]);

  // useEffect(() => {
  //   if (landing.id !== undefined) {
  //     landingUnsubscribeRef.current = listenObject({
  //       collectionName: 'landings',
  //       objectId: landing.id,
  //       setObject: setLanding,
  //     });
  //   } else {
  //     landingUnsubscribeRef.current();
  //   }

  //   return () => {
  //     landingUnsubscribeRef.current();
  //   };
  // }, [landing.id]);

  // useEffect(() => {
  //   if (order.id !== undefined) {
  //     orderUnsubscribeRef.current = listenObject({
  //       collectionName: 'orders',
  //       objectId: order.id,
  //       setObject: setLanding,
  //     });
  //   } else {
  //     orderUnsubscribeRef.current();
  //   }

  //   return () => {
  //     orderUnsubscribeRef.current();
  //   };
  // }, [order.id]);

  return (
    <DocumentContext.Provider
      value={{
        // initialMedia,
        // image,
        // setImage,
        // initialProduct,
        // product,
        // setProduct,
        // initialLanding,
        // landing,
        // setLanding,
        // initialPurchase,
        // purchase,
        // setPurchase,
        // initialAddress,
        // address,
        // setAddress,
        // initialCreditCard,
        // creditCard,
        // setCreditCard,
        // initialPix,
        // initialOrder,
        // order,
        // setOrder,
        // reajustment,
        // setReajustment,
        user,
        setUser,
      }}>
      {children}
    </DocumentContext.Provider>
  );
};

function useDocument() {
  const context = useContext(DocumentContext);

  if (!context) {
    throw new Error('useDocument must be used within as DocumentProvider');
  }

  return context;
}

export { DocumentProvider, useDocument };
