import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Store = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18.75 20.25v-9m-13.5 0v9m-1.5 0h16.5m-10.464 0V15c0-.184.075-.36.209-.49a.726.726 0 0 1 .505-.202h3c.19 0 .371.073.505.203s.21.305.21.489v5.25"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M3.75 9.375v-.33c0-.194.05-.385.145-.559L6.12 4.441c.233-.423.709-.691 1.23-.691h9.3c.521 0 .997.268 1.23.691l2.225 4.045c.095.174.145.365.145.56v.329m-16.5 0Zm0 0c0 1.036.923 1.875 2.063 1.875 1.139 0 2.062-.84 2.062-1.875 0 1.036.923 1.875 2.063 1.875 1.139 0 2.062-.84 2.062-1.875 0 1.036.923 1.875 2.063 1.875 1.139 0 2.062-.84 2.062-1.875 0 1.036.923 1.875 2.063 1.875 1.139 0 2.062-.84 2.062-1.875m0 0Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Store;
