import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Fit = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M4.9 15.795A12.264 12.264 0 0 1 4 11.05C4.035 7.759 6.647 5 9.944 5c4.058 0 6.058 4 6.058 4s2-4 6.057-4c3.298 0 5.909 2.759 5.943 6.05.068 6.834-5.422 11.693-11.438 15.777a1 1 0 0 1-1.125 0c-2.75-1.866-5.388-3.894-7.437-6.198"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M3 15a1 1 0 1 0 0 2v-2Zm5 1v1a1 1 0 0 0 .894-.553L8 16Zm3-6 .958-.287a1 1 0 0 0-1.852-.16L11 10Zm3 10-.958.287a1 1 0 0 0 1.852.16L14 20Zm3-6 .894-.447a1 1 0 0 0-1.788 0L17 14Zm2 4-.894.447A1 1 0 0 0 19 19v-1Zm3 1a1 1 0 1 0 0-2v2ZM3 17h5v-2H3v2Zm5.894-.553 3-6-1.788-.894-3 6 1.788.894Zm1.148-6.16 3 10 1.916-.574-3-10-1.916.574Zm4.852 10.16 3-6-1.788-.894-3 6 1.788.894Zm1.212-6 2 4 1.788-.894-2-4-1.788.894ZM19 19h3v-2h-3v2Z"
      fill={color}
    />
  </Svg>
);

export default Fit;
