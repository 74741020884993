import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Chair = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M22 27v-5.614M22 17v4.386m0 0H10m0 0V17m0 4.386V27"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8 15.333C8 14.597 8.597 14 9.333 14h13.334c.736 0 1.333.597 1.333 1.333V18H8v-2.667Z"
      fill={color}
    />
    <Path
      d="M21 15V5M11 5v10"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11 5s2.931.712 5 .712S21 5 21 5v5.288S18.069 11 16 11s-5-.712-5-.712V5Z"
      fill={color}
    />
  </Svg>
);

export default Chair;
