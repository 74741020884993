import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    heading: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },

    hover: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: styleguide.colors.hover,
      borderRadius: 6,
    },
  });
