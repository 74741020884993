import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Checkbox = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M6 1.5h12v-3H6v3ZM22.5 6v12h3V6h-3ZM18 22.5H6v3h12v-3ZM1.5 18V6h-3v12h3ZM6 22.5A4.5 4.5 0 0 1 1.5 18h-3A7.5 7.5 0 0 0 6 25.5v-3ZM22.5 18a4.5 4.5 0 0 1-4.5 4.5v3a7.5 7.5 0 0 0 7.5-7.5h-3ZM18 1.5A4.5 4.5 0 0 1 22.5 6h3A7.5 7.5 0 0 0 18-1.5v3Zm-12-3A7.5 7.5 0 0 0-1.5 6h3A4.5 4.5 0 0 1 6 1.5v-3Z"
      fill={color}
    />
  </Svg>
);

export default Checkbox;
