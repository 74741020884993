import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Flashlight = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16.258 9.28c-.925.743-2.092.846-2.972.911-.477.038-1.07.38-1.41.72l-6.18 6.178c-.637.638-1.533.49-2.168-.145l-.473-.473c-.637-.637-.78-1.534-.144-2.168l6.18-6.175c.339-.34.684-.934.719-1.409.064-.887.118-2.014.909-2.977m5.539 5.538c.089-.071.176-.15.26-.234l.464-.463c.51-.51.742-1.113.232-1.623L13.04 2.786c-.511-.51-1.114-.279-1.624.233l-.464.466c-.084.084-.162.17-.233.257m5.539 5.538-5.54-5.538"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Flashlight;
