import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Transaction = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14 10.995V6.487m0 0H9.534m4.466 0L6.487 14M2 5.005v4.508m0 0h4.465M2 9.513l7.5-7.5"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Transaction;
