import React, { useMemo, useState } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';

import { createStyles } from './styles';
import { useStyleguide } from '../../hooks/styleguide';
import Icon from '../Icon';
import Typography from '../Typography';

const ButtonText = ({
  variant = 'primary',
  size,
  isInactive = false,
  icon,
  title,
  loading = false,
  onPress,
  contentContainerStyle = {},
}) => {
  const { styleguide } = useStyleguide();

  const [isHovered, setIsHovered] = useState(false);

  const opacityLoading = useMemo(() => {
    const variants = {
      false: 1,
      true: 0,
    };

    return variants[loading];
    //  return loading ? 0 : 1
  }, [loading]);

  const opacityIsInactive = useMemo(() => {
    const variants = {
      false: 1,
      true: 0.5,
    };

    return variants[isInactive];
  }, [isInactive]);

  const iconSize = useMemo(() => {
    const variants = {
      medium: '20',
      small: '16',
    };

    return variants[size];
  }, [size]);

  const typographySize = useMemo(() => {
    const variants = {
      medium: 'medium',
      small: 'small',
    };

    return variants[size];
  }, [size]);

  const backgroundColor = useMemo(() => {
    const variants = {
      primary: styleguide.brand,
      secondary: 'transparent',
    };

    return variants[variant];
  }, [styleguide.brand, styleguide.colors.inBrandPrimary, variant]);

  const colorTypography = useMemo(() => {
    const variants = {
      primary: styleguide.colors.inBrandPrimary,
      secondary: styleguide.brand,
    };

    return variants[variant];
  }, [styleguide.brand, styleguide.colors.inBrandPrimary, variant]);

  const padding = useMemo(() => {
    const variants = {
      medium: 12,
      small: 8,
    };

    return variants[size];
  }, [size]);

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  return (
    <Pressable
      style={[
        styles.container,
        {
          backgroundColor,
          padding,
          borderRadius: padding / 2,
          opacity: opacityIsInactive,
        },
        contentContainerStyle,
      ]}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onPress={isInactive || loading ? () => {} : onPress}>
      {icon && (
        <Icon
          size={iconSize}
          name={icon}
          color={colorTypography}
          contentContainerStyle={{ marginRight: padding, opacity: opacityLoading }}
        />
      )}

      {title && (
        <Typography
          size={typographySize}
          contentContainerStyle={{ opacity: opacityLoading }}
          color={colorTypography}
          name="label"
          text={title}
        />
      )}

      {loading && (
        <ActivityIndicator style={{ position: 'absolute' }} color={colorTypography} size="small" />
      )}

      {isHovered && <View style={[styles.hover, { borderRadius: padding / 2 }]} />}
    </Pressable>
  );
};

export default ButtonText;
