import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      backgroundColor: styleguide.colors.surface,
      paddingVertical: 16,
      paddingHorizontal: { mobile: 16, desktop: 24 }[styleguide.responsive],
      borderRadius: { mobile: 0, desktop: 8 }[styleguide.responsive],
      flexDirection: { mobile: 'column', desktop: 'row' }[styleguide.responsive],
    },

    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: { mobile: 0, desktop: 8 }[styleguide.responsive],
    },

    filter: {
      position: 'absolute',
      backgroundColor: '#00000080',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: { mobile: 0, desktop: 8 }[styleguide.responsive],
    },

    image: {
      height: 328,
      width: 328,
    },

    content: {
      flex: 1,
      justifyContent: 'center',
      marginTop: { mobile: 16, desktop: 0 }[styleguide.responsive],
      marginLeft: { mobile: 0, desktop: 16 }[styleguide.responsive],
    },
  });
