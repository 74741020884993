import * as React from "react";
import Svg, { Circle } from "react-native-svg";

const RadioFilled = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={16} cy={16} r={15} stroke={color} strokeWidth={2} />
    <Circle cx={16} cy={16} r={10} fill={color} />
  </Svg>
);

export default RadioFilled;
