import * as React from "react";
import Svg, { Path } from "react-native-svg";

const LockOpen = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M21 6c0-1-2-3-5-3s-5 2-5 4v5m0 0H9a3 3 0 0 0-3 3v11a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V15a3 3 0 0 0-3-3H11Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LockOpen;
