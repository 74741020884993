import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Chair = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.75 16.875v-3.509m0-2.741v2.741m0 0h-7.5m0 0v-2.741m0 2.741v3.509"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5 9.583c0-.46.373-.833.833-.833h8.334c.46 0 .833.373.833.833v1.667H5V9.583Z"
      fill={color}
    />
    <Path
      d="M13.125 9.375v-6.25m-6.25 0v6.25"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6.875 3.125S8.707 3.57 10 3.57s3.125-.445 3.125-.445V6.43s-1.832.445-3.125.445-3.125-.445-3.125-.445V3.125Z"
      fill={color}
    />
  </Svg>
);

export default Chair;
