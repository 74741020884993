export const typography = {
  large: {
    title: {
      fontFamily: 'Medium',
      fontSize: 22,
      lineHeight: 32,
      letterSpacing: 0,
    },
  },
  medium: {
    title: {
      fontFamily: 'Medium',
      fontSize: 18,
      lineHeight: 24,
      letterSpacing: 0,
    },
    body: {
      fontFamily: 'Regular',
      fontSize: 16,
      lineHeight: 20,
      letterSpacing: '0.25%',
    },
    label: {
      fontFamily: 'Medium',
      fontSize: 16,
      lineHeight: 20,
      letterSpacing: '2.5%',
    },
    caption: {
      fontFamily: 'Regular',
      fontSize: 14,
      lineHeight: 16,
      letterSpacing: '2.5%',
    },
  },
  small: {
    title: {
      fontFamily: 'Medium',
      fontSize: 16,
      lineHeight: 20,
      letterSpacing: 0,
    },
    body: {
      fontFamily: 'Regular',
      fontSize: 14,
      lineHeight: 16,
      letterSpacing: '0%',
    },
    label: {
      fontFamily: 'Medium',
      fontSize: 14,
      lineHeight: 16,
      letterSpacing: '2.5%',
    },
    caption: {
      fontFamily: 'Regular',
      fontSize: 12,
      lineHeight: 12,
      letterSpacing: '0%',
    },
  },
};
