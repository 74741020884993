import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Print = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12 11.5h.75A1.254 1.254 0 0 0 14 10.25v-5A1.254 1.254 0 0 0 12.75 4h-9.5A1.254 1.254 0 0 0 2 5.25v5a1.254 1.254 0 0 0 1.25 1.25H4"
      stroke={color}
      strokeLinejoin="round"
    />
    <Path
      d="M11.24 7.5H4.76a.76.76 0 0 0-.76.76v4.98c0 .42.34.76.76.76h6.48c.42 0 .76-.34.76-.76V8.26a.76.76 0 0 0-.76-.76ZM12 4v-.75A1.254 1.254 0 0 0 10.75 2h-5.5A1.254 1.254 0 0 0 4 3.25V4"
      stroke={color}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Print;
