import { useFonts, Roboto_400Regular, Roboto_500Medium } from '@expo-google-fonts/roboto';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking';
import * as NavigationBar from 'expo-navigation-bar';
import { StatusBar } from 'expo-status-bar';
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useMemo, useState } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import Pages from './Pages';
import { createStyles } from './styles';
import Alert from '../components/Alert';
import Toast from '../components/Toast';
import { useAlert } from '../hooks/alert';
import { useAuthentication } from '../hooks/authentication';
import { useCollection } from '../hooks/collection';
import { useDatabase } from '../hooks/database';
import { useNavigation } from '../hooks/navigation';
import { useStyleguide } from '../hooks/styleguide';
import { useToast } from '../hooks/toast';
import { useUrl } from '../hooks/url';

const Routes = () => {
  const [fontsLoaded] = useFonts({
    Regular: Roboto_400Regular,
    Medium: Roboto_500Medium,
  });
  const auth = getAuth();

  const { routesRef, navigate, setNavigation } = useNavigation();
  const { styleguide } = useStyleguide();
  const { loadingCollections } = useCollection();
  const { listenObject, getObject, createObject, listenArray } = useDatabase();
  const { addParams } = useUrl();
  const { setAuthentication } = useAuthentication();
  const { toastRef } = useToast();
  const { alertRef } = useAlert();
  const { url } = useUrl();

  // const [routes, setRoutes ] = useState([]);
  // const [firstAccess, setFirstAccess] = useState(true);
  // const [loading, setLoading] = useState(true);

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  const statusBarStyle = useMemo(
    () => (styleguide.theme === 'dark' ? 'light' : 'dark'),
    [styleguide.theme],
  );

  // const keyboardAvoidingViewBehavior = useMemo(
  //   () => (styleguide.platform.OS === 'ios' ? 'padding' : 'height'),
  //   [styleguide.platform.OS]
  // );

  useEffect(() => {
    (async () => {
      console.warn(
        ` ^^^^ react-native-gesture-handler import warned "transform" style array value is deprecated.`,
      );

      // const firstAccessStorage = await AsyncStorage.getItem('firstAccess');

      // if (String(firstAccessStorage) === 'false') {
      //   setFirstAccess(false);
      // }

      onAuthStateChanged(auth, async (userLogged) => {
        if (userLogged) {
          const user = await getObject({ collectionName: 'users', objectId: userLogged.uid });

          if (user.id === undefined) {
            const initialUser = {
              avatar: {},
              fullName: userLogged.displayName || (await AsyncStorage.getItem('name')),
              email: userLogged.email,
              id: userLogged.uid,
            };

            await AsyncStorage.removeItem('name');

            await createObject({ collectionName: 'users', object: initialUser });
          }

          listenObject({
            collectionName: 'users',
            objectId: userLogged.uid,
            setObject: (user) => {
              setAuthentication((prevState) => ({
                ...prevState,
                user,
              }));
            },
          });
        } else {
          setAuthentication((prevState) => ({
            ...prevState,
            user: {},
          }));
        }
      });
    })();
  }, []);

  useEffect(() => {
    if (url.domain !== '') {
      listenArray({
        collectionName: 'stores',
        foreignKeyValueType: 'array',
        foreignKeyName: 'domains',
        foreignKeyValue: {
          url: url.domain === 'localhost' ? 'yub.app' : url.domain,
        },
        setHooks: (array) => {
          if (array.length > 0) {
            const store = array[0];
            setAuthentication((prevState) => ({
              ...prevState,
              store,
            }));

            if (styleguide.platform.OS === 'web') {
              // eslint-disable-next-line no-undef
              document.title = store.name;

              const logoUri = store.avatar.uri.icon;

              // eslint-disable-next-line no-undef
              const link1 = document.querySelector('link[rel="icon"][href="/favicon-16.png"]');
              // eslint-disable-next-line no-undef
              const link2 = document.querySelector('link[rel="icon"][href="/favicon-32.png"]');
              // eslint-disable-next-line no-undef
              const link3 = document.querySelector(
                'link[rel="shortcut icon"][href="/favicon.ico"]',
              );
              link1.href = logoUri;
              link2.href = logoUri;
              link3.href = logoUri;
            }
          } else {
            setAuthentication((prevState) => ({
              ...prevState,
              store: {},
            }));

            if (styleguide.platform.OS === 'web') {
              // eslint-disable-next-line no-undef
              document.title = '';
            }
          }
        },
      });
    }
  }, [url.domain, styleguide.theme, styleguide.platform.OS]);

  useEffect(() => {
    if (!loadingCollections && fontsLoaded) {
      let routes;

      if (styleguide.application === 'app') {
        routes = [{ route: 'Home', icon: 'home', title: 'Início' }];

        setNavigation((prevState) => ({
          ...prevState,
          routes,
        }));
      } else if (styleguide.application === 'dash') {
        routes = [
          { route: 'Home', icon: 'home', title: 'Início' },
          { route: 'Dashboard', icon: 'chart', title: 'Negócio' },
          { route: 'Monitoring', icon: 'live', title: 'Monitor' },
          { route: 'Catalog', icon: 'bookOpened', title: 'Catálogo' },
          { route: 'Squad', icon: 'people', title: 'Time' },
          { route: 'Menu', icon: 'menu', title: 'Menu' },
        ];

        setNavigation((prevState) => ({
          ...prevState,
          routes,
        }));
      }

      navigate({ variant: 'page', screen: routes[0].route });
    }
  }, [loadingCollections, fontsLoaded, styleguide.application]);

  useEffect(() => {
    (async () => {
      if (styleguide.platform.OS === 'web') {
        // eslint-disable-next-line no-undef
        document.body.style.background = styleguide.colors.background;
        // window.scrollTo(0, 0);
      }

      if (styleguide.platform.OS === 'android') {
        await NavigationBar.setBackgroundColorAsync(styleguide.colors.background);
      }
    })();
  }, [styleguide.platform.OS, styleguide.colors.background]);

  return (
    <GestureHandlerRootView style={styles.container}>
      {/* <KeyboardAvoidingView
        behavior={keyboardAvoidingViewBehavior}
        style={{ flex: 1 }}> */}
      {/* <Pressable
        style={styles.keyboardDismiss}
        onPress={
          styleguide.keyboardIsOpened
          ? Keyboard.dismiss
          : () => {
            console.log('PRESSED');
          }
        }
      /> */}
      <StatusBar style={statusBarStyle} backgroundColor="transparent" />

      <Pages ref={routesRef} />
      <Alert ref={alertRef} />
      <Toast ref={toastRef} />
      {/* <ImageFull ref={imageFullRef}/> */}
      {/* </KeyboardAvoidingView> */}
    </GestureHandlerRootView>
  );
};

export default Routes;
