import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Close = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15 5 5 15m10 0L5 5l10 10Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Close;
