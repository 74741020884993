import React, { createContext, useState, useContext, useRef, useCallback } from 'react';

const AlertContext = createContext({});

const AlertProvider = ({ children }) => {
  const initialAlert = {
    type: '',
    title: '',
    description: '',
    onPress: null,
  };

  const alertRef = useRef();

  const [alert, setAlert] = useState(initialAlert);

  const showAlert = useCallback(({ type, title, description = '', onPress = null }) => {
    setAlert({ type, title, description, onPress });

    alertRef.current.show();
  }, []);

  return (
    <AlertContext.Provider
      value={{
        initialAlert,
        alertRef,
        alert,
        setAlert,
        showAlert,
      }}>
      {children}
    </AlertContext.Provider>
  );
};

function useAlert() {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error('useAlert must be used within as AlertProvider');
  }

  return context;
}

export { AlertProvider, useAlert };
