import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Item = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M26.344 4H19.25a.834.834 0 0 0-.588.242L4.485 18.417a1.664 1.664 0 0 0 0 2.348l6.75 6.75a1.665 1.665 0 0 0 2.348 0l14.175-14.169a.835.835 0 0 0 .242-.588V5.662A1.655 1.655 0 0 0 26.344 4Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M22.667 9.666a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
      stroke={color}
      strokeWidth={2}
    />
  </Svg>
);

export default Item;
