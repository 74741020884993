import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const Save = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M5 8a3 3 0 0 1 3-3h13.586l2.374 2.374 2.161 2.162a3 3 0 0 1 .879 2.12V24a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V8Z"
      stroke={color}
      strokeWidth={2}
    />
    <Circle cx={16} cy={18.667} r={3} stroke={color} strokeWidth={2} />
  </Svg>
);

export default Save;
