import * as React from "react";
import Svg, { Circle } from "react-native-svg";

const Radio = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={8} cy={8} r={7.5} stroke={color} />
  </Svg>
);

export default Radio;
