import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Creditcard = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14.53 6V4.754c0-.969-.785-1.754-1.754-1.754H3.254C2.285 3 1.5 3.785 1.5 4.754V6m13.03 0v5.269c0 .969-.785 1.754-1.754 1.754H3.254A1.754 1.754 0 0 1 1.5 11.269V6m13.03 0H1.5M4 10v-.688h1.5V10H4Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Creditcard;
