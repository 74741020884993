import * as React from "react";
import Svg, { Mask, Path } from "react-native-svg";

const PaymentReceipt = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Mask id="a" fill="#fff">
      <Path d="M1.25 2.443c0-.659.534-1.193 1.193-1.193h15.114c.659 0 1.193.534 1.193 1.193v13.964a1.193 1.193 0 0 1-1.895.965l-2.48-1.804-2.971 2.161a2.386 2.386 0 0 1-2.808 0l-2.971-2.16-2.48 1.803a1.193 1.193 0 0 1-1.895-.965V2.443Z" />
    </Mask>
    <Path
      d="m14.375 15.568.702-.965a1.193 1.193 0 0 0-1.404 0l.702.965Zm-8.75 0 .702-.965a1.193 1.193 0 0 0-1.404 0l.702.965Zm2.971 2.161-.701.965.701-.965Zm8.259-.357-.702.965.702-.965ZM17.557.057H2.443v2.386h15.114V.057Zm0 2.386v13.964h2.386V2.443h-2.386Zm0 13.964-2.48-1.804-1.404 1.93 2.48 1.804 1.404-1.93Zm-5.452 2.287 2.972-2.16-1.404-1.93-2.971 2.16 1.403 1.93Zm-7.182-2.16 2.972 2.16 1.403-1.93-2.971-2.16-1.404 1.93Zm-1.076 1.803 2.48-1.804-1.404-1.93-2.48 1.804 1.404 1.93ZM.057 2.443v13.964h2.386V2.443H.057Zm10.645 14.321a1.193 1.193 0 0 1-1.404 0l-1.403 1.93a3.58 3.58 0 0 0 4.21 0l-1.403-1.93Zm6.855-.357-1.404 1.93c1.578 1.147 3.79.02 3.79-1.93h-2.386Zm-15.114 0H.057c0 1.95 2.212 3.077 3.79 1.93l-1.404-1.93ZM17.557 2.443h2.386A2.386 2.386 0 0 0 17.557.057v2.386ZM2.443.057A2.386 2.386 0 0 0 .057 2.443h2.386V.057Z"
      fill={color}
      mask="url(#a)"
    />
    <Path
      d="M10.354 11.609c.295-.043.516-.143.663-.3.147-.159.221-.37.221-.634a.62.62 0 0 0-.055-.272.687.687 0 0 0-.152-.212.992.992 0 0 0-.23-.16 1.927 1.927 0 0 0-.29-.13l-.157 1.708ZM10 6.915c-.156.021-.29.056-.4.105a.874.874 0 0 0-.272.17.68.68 0 0 0-.152.226.712.712 0 0 0-.046.258c0 .104.017.198.05.28.038.08.088.153.153.217.064.064.14.123.23.175.089.049.187.093.294.133L10 6.915Zm.617 1.813c.147.043.294.09.442.143.147.049.288.105.423.17.135.064.26.14.377.225.117.086.218.187.304.304.09.114.158.246.207.396.05.15.074.322.074.515 0 .286-.05.556-.147.81a1.98 1.98 0 0 1-.424.672 2.223 2.223 0 0 1-.685.474c-.27.123-.58.197-.93.221l-.055.626a.347.347 0 0 1-.102.216.315.315 0 0 1-.239.097h-.465l.088-.971a3.834 3.834 0 0 1-.52-.11 4.192 4.192 0 0 1-.488-.185 3.797 3.797 0 0 1-.442-.243 2.611 2.611 0 0 1-.373-.3l.391-.575a.377.377 0 0 1 .138-.12.355.355 0 0 1 .18-.05c.07 0 .144.021.22.064.077.043.162.095.254.157.095.058.2.118.317.18.12.06.258.108.415.142l.175-1.91a8.067 8.067 0 0 1-.442-.134 3.908 3.908 0 0 1-.42-.179 2.36 2.36 0 0 1-.376-.235 1.524 1.524 0 0 1-.502-.76 2.012 2.012 0 0 1-.074-.57 1.845 1.845 0 0 1 .557-1.302 2.2 2.2 0 0 1 .677-.437c.267-.117.573-.185.92-.203l.05-.548c.007-.08.04-.15.102-.211a.325.325 0 0 1 .24-.097h.469l-.083.902a2.696 2.696 0 0 1 1.459.732l-.313.46a.504.504 0 0 1-.138.157.323.323 0 0 1-.175.046.48.48 0 0 1-.166-.037 9.512 9.512 0 0 1-.207-.097 3.467 3.467 0 0 0-.258-.12 2.074 2.074 0 0 0-.294-.1l-.161 1.785Z"
      fill={color}
    />
  </Svg>
);

export default PaymentReceipt;
