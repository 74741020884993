import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

const Color = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Rect
      x={3.125}
      y={1.875}
      width={12.5}
      height={5}
      rx={1.875}
      stroke={color}
      strokeWidth={1.25}
    />
    <Rect
      x={8.125}
      y={11.875}
      width={2.5}
      height={6.25}
      rx={0.625}
      stroke={color}
      strokeWidth={1.25}
    />
    <Path
      d="M9.375 11.875c0-.672.072-1.36 1.875-1.875 4.375-1.25 6.25-.625 6.25-3.125V6.25c0-1.777-.625-1.875-1.875-1.875"
      stroke={color}
      strokeWidth={1.25}
    />
  </Svg>
);

export default Color;
