import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Mail = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3.217 5.081a.905.905 0 0 0 .097 1.274l8.1 6.967c.337.29.835.29 1.172 0l8.1-6.967a.905.905 0 0 0 .097-1.274M3.9 4.766c-.497 0-.9.404-.9.903v12.193c0 .5.403.904.9.904h16.2c.497 0 .9-.405.9-.904V5.67a.902.902 0 0 0-.9-.903H3.9Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Mail;
