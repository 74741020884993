import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Shuffle = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M4 22h5.324a5 5 0 0 0 4.16-2.226L16 16m9 3 3 3-3 3v-6Zm0-12 3 3-3 3V7Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M4 10h5.324a5 5 0 0 1 4.16 2.226l5.032 7.548A5 5 0 0 0 22.676 22H26m0-12h-3.324a5 5 0 0 0-4.16 2.226L18 13"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Shuffle;
