import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Camera = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M5 6.25h.469c.336 0 .619-.186.838-.442l1.04-1.643c.219-.256.52-.415.856-.415h3.594c.337 0 .637.16.856.415l1.04 1.643c.22.256.54.442.877.442h2.305a1.25 1.25 0 0 1 1.25 1.25V15a1.25 1.25 0 0 1-1.25 1.25H3.125A1.25 1.25 0 0 1 1.875 15V7.5a1.25 1.25 0 0 1 1.25-1.25h.625m1.25 0V5H3.75v1.25m1.25 0H3.75"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10 13.75a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25Z"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Camera;
