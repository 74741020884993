import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Hammer = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8.67 8.004a.771.771 0 0 0-.128-.17l-.573-.566a.654.654 0 0 0-.933-.01c-.198.196-.562.553-1.218 1.202-1.255 1.241-3.586 3.322-4.64 4.21a.5.5 0 0 0-.033.735l1.218 1.232a.502.502 0 0 0 .74-.027c.914-1.074 3.01-3.406 4.25-4.632.637-.627.994-.987 1.197-1.186a.68.68 0 0 0 .12-.788v0Zm6.281-1.437L13.88 5.504a.17.17 0 0 0-.125-.05.175.175 0 0 0-.125.05.357.357 0 0 1-.298.103c-.14-.016-.29-.06-.386-.152-.219-.215.034-.637-.158-.907a7.59 7.59 0 0 0-.722-.835c-.22-.218-1.087-1.045-2.548-1.641a3.869 3.869 0 0 0-1.469-.289c-.823 0-1.456.368-1.687.579-.184.166-.375.43-.375.43a2.486 2.486 0 0 1 1.065-.147c.486.04 1.072.269 1.387.516.506.406.724.95.758 1.65.025.522-.476 1.18-.951 1.717a.245.245 0 0 0 .012.339l.664.663a.25.25 0 0 0 .348.003c.436-.422.972-.89 1.276-1.076.304-.188.55-.24.667-.253.232-.023.464.028.666.144.035.02.068.045.096.075.202.205.19.54-.016.741l-.062.06a.172.172 0 0 0 0 .244l1.072 1.063a.172.172 0 0 0 .125.05.176.176 0 0 0 .125-.05l1.733-1.714a.182.182 0 0 0 0-.25v0Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Hammer;
