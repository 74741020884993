import * as React from "react";
import Svg, { Path } from "react-native-svg";

const History = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3.333 5.926A5.335 5.335 0 0 1 13.581 8a5.333 5.333 0 0 1-10.248 2.074m0-4.148H5m-1.667 0-.667-1.593"
      stroke={color}
      strokeLinecap="round"
    />
    <Path
      d="M8.5 5.333a.5.5 0 0 0-1 0h1Zm-.5 3h-.5v.36l.342.115L8 8.333Zm1.842 1.141a.5.5 0 1 0 .316-.948l-.316.948ZM7.5 5.334v3h1v-3h-1Zm.342 3.474 2 .666.316-.948-2-.667-.316.949Z"
      fill={color}
    />
  </Svg>
);

export default History;
