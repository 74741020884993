import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Phone = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M17.625 3H6.375v18h11.25V3ZM11.994 17.625h.011"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Phone;
