import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Filter = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11.459 13.646V10l4.375-5.833H4.167L8.542 10v5.833"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Filter;
