import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Checkbox = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M4 1h8v-2H4v2Zm11 3v8h2V4h-2Zm-3 11H4v2h8v-2ZM1 12V4h-2v8h2Zm3 3a3 3 0 0 1-3-3h-2a5 5 0 0 0 5 5v-2Zm11-3a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5h-2ZM12 1a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2ZM4-1a5 5 0 0 0-5 5h2a3 3 0 0 1 3-3v-2Z"
      fill={color}
    />
  </Svg>
);

export default Checkbox;
