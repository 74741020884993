import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Login = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16.5 12 13 15.5m3.5-3.5L13 8.5m3.5 3.5H4"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.25 8a.75.75 0 0 0 1.5 0h-1.5Zm1.5 8a.75.75 0 0 0-1.5 0h1.5ZM12 4.75h5v-1.5h-5v1.5ZM19.25 7v10h1.5V7h-1.5ZM17 19.25h-5v1.5h5v-1.5ZM9.75 8V7h-1.5v1h1.5Zm0 9v-1h-1.5v1h1.5ZM12 19.25A2.25 2.25 0 0 1 9.75 17h-1.5A3.75 3.75 0 0 0 12 20.75v-1.5ZM19.25 17A2.25 2.25 0 0 1 17 19.25v1.5A3.75 3.75 0 0 0 20.75 17h-1.5ZM17 4.75A2.25 2.25 0 0 1 19.25 7h1.5A3.75 3.75 0 0 0 17 3.25v1.5Zm-5-1.5A3.75 3.75 0 0 0 8.25 7h1.5A2.25 2.25 0 0 1 12 4.75v-1.5Z"
      fill={color}
    />
  </Svg>
);

export default Login;
