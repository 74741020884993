import React, { forwardRef } from 'react';
import { View } from 'react-native';

// import { Container } from './styles';

const Alert = () => {
  return <View />;
};

export default forwardRef(Alert);
