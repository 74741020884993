import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Telephone = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M17.03 14.254c-.572-.577-1.959-1.419-2.631-1.758-.876-.441-.949-.478-1.637.034-.46.341-.765.647-1.303.532-.537-.115-1.705-.761-2.728-1.78-1.023-1.02-1.707-2.223-1.822-2.759-.115-.535.195-.837.533-1.298.477-.649.44-.757.033-1.633-.317-.681-1.184-2.055-1.763-2.625-.62-.612-.62-.503-1.018-.338a5.774 5.774 0 0 0-.932.497c-.577.383-.897.702-1.12 1.18-.225.478-.325 1.6.831 3.7 1.156 2.101 1.968 3.175 3.647 4.85 1.679 1.674 2.97 2.575 4.858 3.633 2.335 1.308 3.23 1.053 3.71.83.48-.224.8-.541 1.184-1.118a5.73 5.73 0 0 0 .497-.93c.167-.398.275-.398-.338-1.017Z"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Telephone;
