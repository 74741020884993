import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Chair = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16.5 20.25v-4.21m0-3.29v3.29m0 0h-9m0 0v-3.29m0 3.29v4.21"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="M6 11.5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2H6v-2Z" fill={color} />
    <Path
      d="M15.75 11.25v-7.5m-7.5 0v7.5"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.25 3.75s2.198.534 3.75.534 3.75-.534 3.75-.534v3.966s-2.198.534-3.75.534-3.75-.534-3.75-.534V3.75Z"
      fill={color}
    />
  </Svg>
);

export default Chair;
