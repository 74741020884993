import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Star = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18.75 8.125h-6.719L10 1.875l-2.031 6.25H1.25l5.469 3.75-2.11 6.25L10 14.219l5.39 3.906-2.109-6.25 5.469-3.75Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinejoin="round"
    />
  </Svg>
);

export default Star;
