import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Flashlight = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M19.51 11.137c-1.11.89-2.51 1.014-3.567 1.093-.573.045-1.284.455-1.69.863l-7.417 7.414c-.765.765-1.84.588-2.602-.174l-.567-.567c-.765-.765-.936-1.841-.174-2.602l7.416-7.41c.407-.407.821-1.12.863-1.69.076-1.066.141-2.418 1.09-3.574m6.647 6.647c.107-.087.212-.18.313-.281l.556-.556c.613-.613.891-1.336.279-1.949l-5.01-5.008c-.612-.612-1.336-.334-1.948.28l-.557.559c-.1.1-.194.203-.28.308m6.647 6.647L12.863 4.49"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Flashlight;
