import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Transaction = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M17.5 13.743V8.11m0 0h-5.582m5.582 0L8.108 17.5M2.5 6.257v5.634m0 0h5.581m-5.581 0 9.375-9.375"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Transaction;
