import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Unavailable = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={16} cy={16} r={11} stroke={color} strokeWidth={2} />
    <Path
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      d="M6.667 26.586 26.586 6.667"
    />
  </Svg>
);

export default Unavailable;
