import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Help = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={16} cy={16} r={13} stroke={color} strokeWidth={2} />
    <Circle cx={16} cy={16} r={4.333} stroke={color} strokeWidth={2} />
    <Path
      d="M20 12.8v6.4l8 .8v-8l-8 .8ZM12 4l.8 8h6.4l.8-8h-8ZM12.8 20l-.8 8h8l-.8-8h-6.4ZM4 12v8l8-.8v-6.4L4 12Z"
      fill={color}
    />
  </Svg>
);

export default Help;
