import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Sun = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M17 3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2Zm2 20a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM25.9 7.515A1 1 0 0 0 24.485 6.1L25.9 7.515Zm-3.536.707a1 1 0 0 0 1.414 1.414l-1.414-1.414ZM9.636 23.778a1 1 0 0 0-1.414-1.414l1.414 1.414Zm-3.536.707A1 1 0 1 0 7.515 25.9L6.1 24.485ZM29 17a1 1 0 1 0 0-2v2Zm-3-2a1 1 0 1 0 0 2v-2ZM6 17a1 1 0 1 0 0-2v2Zm-3-2a1 1 0 1 0 0 2v-2Zm21.485 10.9a1 1 0 0 0 1.415-1.415L24.485 25.9Zm-.707-3.536a1 1 0 0 0-1.414 1.414l1.414-1.414ZM8.222 9.636a1 1 0 1 0 1.414-1.414L8.222 9.636ZM7.515 6.1A1 1 0 0 0 6.1 7.515L7.515 6.1ZM15 3v3h2V3h-2Zm0 23v3h2v-3h-2Zm9.485-19.9-2.12 2.122 1.413 1.414L25.9 7.515 24.485 6.1ZM8.222 22.364 6.1 24.485 7.515 25.9l2.12-2.122-1.413-1.414ZM29 15h-3v2h3v-2ZM6 15H3v2h3v-2Zm19.9 9.485-2.122-2.12-1.414 1.413 2.121 2.122 1.415-1.415ZM9.636 8.222 7.515 6.1 6.1 7.515l2.122 2.12 1.414-1.413Z"
      fill={color}
    />
    <Path
      d="M16 21a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default Sun;
