import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Facebook = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 16.083c0-7.73-6.269-14-14-14s-14 6.27-14 14c0 6.988 5.119 12.78 11.813 13.831v-9.783h-3.556v-4.048h3.556V13c0-3.508 2.09-5.448 5.288-5.448 1.532 0 3.134.274 3.134.274v3.446h-1.766c-1.738 0-2.282 1.079-2.282 2.187v2.625h3.882l-.62 4.049h-3.262v9.783C24.881 28.865 30 23.073 30 16.083Z"
      fill={color}
    />
  </Svg>
);

export default Facebook;
