import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Helpball = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={8} cy={12} r={0.5} stroke={color} />
    <Path
      d="M8.5 9.5c-2 0-.71-1.29 1-3 .839-.839 1-3-1.5-3-1 0-2 .75-2 1.5"
      stroke={color}
      strokeLinecap="round"
    />
    <Circle cx={8} cy={8} r={6.5} stroke={color} />
  </Svg>
);

export default Helpball;
