import * as React from "react";
import Svg, { Path } from "react-native-svg";

const EyeClosed = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11.984 5.25c-3.653 0-7.401 2.115-10.351 6.344a.75.75 0 0 0-.013.833c2.267 3.548 5.964 6.323 10.364 6.323 4.352 0 8.125-2.783 10.397-6.34a.757.757 0 0 0 0-.819C20.104 8.076 16.303 5.25 11.984 5.25Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <Path
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M4.061 4.148 19.5 19.588"
    />
  </Svg>
);

export default EyeClosed;
