import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Truck = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18.125 14.375V15c.345 0 .625-.28.625-.625h-.625Zm0-4.375h.625a.625.625 0 0 0-.09-.322l-.535.322ZM16.25 6.875l.536-.322a.625.625 0 0 0-.536-.303v.625Zm-3.125 0V6.25a.625.625 0 0 0-.625.625h.625Zm0 5.71.178.599-.178-.6ZM3.125 5h8.75V3.75h-8.75V5ZM2.5 13.125v-7.5H1.25v7.5H2.5Zm16.25 1.25V10H17.5v4.375h1.25Zm-.09-4.697-1.874-3.125-1.072.644 1.875 3.125 1.072-.644ZM16.25 6.25h-3.125V7.5h3.125V6.25Zm-3.75.625V10h1.25V6.875H12.5Zm0-1.25V10h1.25V5.625H12.5Zm5.625 3.75h-5v1.25h5v-1.25ZM3.75 13.75h-.625V15h.625v-1.25Zm.625.625c0-.213.069-.545.257-.807.166-.23.447-.443.993-.443v-1.25c-.954 0-1.61.411-2.007.963a2.719 2.719 0 0 0-.493 1.537h1.25Zm1.25-1.25c.546 0 .827.214.993.443.188.262.257.595.257.807h1.25c0-.412-.119-1.017-.493-1.537-.397-.552-1.053-.963-2.007-.963v1.25Zm0 2.5c-.546 0-.827-.214-.993-.443a1.472 1.472 0 0 1-.257-.807h-1.25c0 .412.119 1.017.493 1.537.397.552 1.053.963 2.007.963v-1.25Zm1.25-1.25c0 .213-.069.545-.257.807-.166.23-.447.443-.993.443v1.25c.954 0 1.61-.411 2.007-.963.374-.52.493-1.125.493-1.537h-1.25Zm6.875-1.25c.546 0 .827.214.993.443.188.262.257.595.257.807h1.25c0-.412-.119-1.017-.493-1.537-.397-.552-1.053-.963-2.007-.963v1.25Zm0 2.5c-.546 0-.827-.214-.993-.443a1.472 1.472 0 0 1-.257-.807h-1.25c0 .412.119 1.017.493 1.537.397.552 1.053.963 2.007.963v-1.25Zm1.25-1.25c0 .213-.069.545-.257.807-.166.23-.447.443-.993.443v1.25c.954 0 1.61-.411 2.007-.963.374-.52.493-1.125.493-1.537H15Zm-2.5 0c0-.408.21-1.015.803-1.191l-.356-1.198c-1.374.407-1.697 1.743-1.697 2.389h1.25Zm.803-1.191c.12-.035.267-.059.447-.059v-1.25c-.29 0-.557.038-.803.11l.356 1.199ZM12.5 10v2.585h1.25V10H12.5Zm5.625 3.75h-2.5V15h2.5v-1.25Zm-6.25 0H7.5V15h4.375v-1.25ZM1.25 13.125c0 1.036.84 1.875 1.875 1.875v-1.25a.625.625 0 0 1-.625-.625H1.25ZM11.875 5c.345 0 .625.28.625.625h1.25c0-1.036-.84-1.875-1.875-1.875V5Zm-8.75-1.25c-1.036 0-1.875.84-1.875 1.875H2.5c0-.345.28-.625.625-.625V3.75Z"
      fill={color}
    />
  </Svg>
);

export default Truck;
