import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const Help = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={10} cy={10} r={8.125} stroke={color} strokeWidth={1.25} />
    <Circle cx={10} cy={10} r={2.708} stroke={color} strokeWidth={1.25} />
    <Path
      d="M12.5 8v4l5 .5v-5l-5 .5ZM7.5 2.5l.5 5h4l.5-5h-5ZM8 12.5l-.5 5h5l-.5-5H8ZM2.5 7.5v5l5-.5V8l-5-.5Z"
      fill={color}
    />
  </Svg>
);

export default Help;
