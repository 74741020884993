import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Sun = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10.625 1.875a.625.625 0 1 0-1.25 0h1.25ZM9.375 3.75a.625.625 0 1 0 1.25 0h-1.25Zm1.25 12.5a.625.625 0 1 0-1.25 0h1.25Zm-1.25 1.875a.625.625 0 1 0 1.25 0h-1.25Zm6.812-13.428a.625.625 0 0 0-.884-.884l.884.884Zm-2.21.442a.625.625 0 1 0 .884.883l-.883-.883Zm-7.955 9.723a.625.625 0 0 0-.883-.884l.883.883Zm-2.21.441a.625.625 0 0 0 .885.884l-.884-.884Zm14.313-4.678a.625.625 0 1 0 0-1.25v1.25Zm-1.875-1.25a.625.625 0 1 0 0 1.25v-1.25Zm-12.5 1.25a.625.625 0 1 0 0-1.25v1.25Zm-1.875-1.25a.625.625 0 1 0 0 1.25v-1.25Zm13.428 6.812a.625.625 0 0 0 .884-.884l-.884.884Zm-.442-2.21a.625.625 0 1 0-.883.884l.883-.883ZM5.14 6.023a.625.625 0 1 0 .883-.883l-.883.883Zm-.442-2.21a.625.625 0 1 0-.884.885l.884-.884Zm4.678-1.937V3.75h1.25V1.875h-1.25Zm0 14.375v1.875h1.25V16.25h-1.25Zm5.928-12.437-1.325 1.326.883.883 1.326-1.325-.884-.884ZM5.14 13.978l-1.326 1.325.884.884 1.325-1.326-.883-.883Zm12.986-4.603H16.25v1.25h1.875v-1.25Zm-14.375 0H1.875v1.25H3.75v-1.25Zm12.437 5.928-1.326-1.325-.883.883 1.325 1.326.884-.884ZM6.022 5.14 4.697 3.813l-.884.884 1.326 1.325.883-.883Z"
      fill={color}
    />
    <Path
      d="M10 13.125a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25Z"
      stroke={color}
      strokeWidth={1.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default Sun;
