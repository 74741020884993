import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Bell = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14.998 18v.75a3.001 3.001 0 0 1-2.997 3 2.996 2.996 0 0 1-2.998-3V18m11.038-1.527C18.838 15 17.99 14.25 17.99 10.188c0-3.72-1.897-5.044-3.46-5.688a.834.834 0 0 1-.465-.495C13.79 3.072 13.022 2.25 12 2.25c-1.021 0-1.79.823-2.061 1.756a.827.827 0 0 1-.466.494c-1.564.645-3.46 1.965-3.46 5.688C6.012 14.25 5.163 15 3.96 16.473c-.498.61-.062 1.527.81 1.527h14.466c.867 0 1.3-.92.805-1.527Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Bell;
