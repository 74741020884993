import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Pin = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M19 9.777C19 14.075 11.5 22 11.5 22S4 14.074 4 9.777C4 5.481 7.357 2 11.5 2S19 5.482 19 9.777Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M13.25 9.5a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0Z"
      stroke={color}
      strokeWidth={1.5}
    />
  </Svg>
);

export default Pin;
