import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Menu = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      stroke={color}
      strokeLinecap="round"
      d="M1.5 3.266h4.333M1.5 7.932h12.333M1.5 12.599h9"
    />
  </Svg>
);

export default Menu;
