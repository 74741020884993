import React, { useMemo } from 'react';
import { Image, View } from 'react-native';

import { createStyles } from './styles';
import { useStyleguide } from '../../hooks/styleguide';
import ButtonText from '../ButtonText';
import Icon from '../Icon';
import Typography from '../Typography';

const ItemSolution = ({ solution, variant, contentContainerStyle = {} }) => {
  const { styleguide } = useStyleguide();

  const flexDirection = useMemo(() => {
    const variants = {
      mobile: {
        default: 'column',
        reverse: 'column',
      },
      desktop: {
        default: 'row-reverse',
        reverse: 'row',
      },
    };

    return variants[styleguide.responsive][variant];
  }, [variant, styleguide.responsive]);

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  return (
    <View style={[styles.container, { flexDirection }, contentContainerStyle]}>
      <Image source={{ uri: solution.image.uri.high }} resizeMode="cover" style={styles.image} />
      <View style={styles.spacing} />
      <View style={styles.content}>
        <Typography size="medium" name="title" text={solution.title} />
        <Typography
          contentContainerStyle={{ marginTop: 16 }}
          color={styleguide.colors.typographySecondary}
          size="medium"
          name="body"
          text={solution.description}
        />
        {solution.checks.map((check, index) => (
          <View style={styles.check} key={index}>
            <Icon size="20" name="checkboxFilled" />
            <Typography
              contentContainerStyle={{ marginLeft: 8 }}
              size="medium"
              name="label"
              text={check}
            />
          </View>
        ))}
        {/* <ButtonText /> */}
      </View>
    </View>
  );
};

export default ItemSolution;
