import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Username = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12 21a9 9 0 1 1 9-9c0 3.375-2.25 5.625-4.5 5.625-4.5 0-3.604-7.228-1.125-9"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M13.484 12s-.792 3.375-3.042 3.375c-3.645 0-1.667-6.75 1.163-6.75 2.108 0 1.879 3.375 1.879 3.375Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Username;
