import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Phone = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14.688 2.5H5.312v15h9.375v-15ZM9.995 14.688h.01"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Phone;
