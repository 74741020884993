import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Heart = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M7.457 4C10.5 4 12 6.91 12 6.91S13.5 4 16.543 4A4.461 4.461 0 0 1 21 8.4c.051 4.97-4.066 8.504-8.578 11.474a.767.767 0 0 1-.844 0C7.066 16.904 2.95 13.37 3 8.401A4.461 4.461 0 0 1 7.457 4v0Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Heart;
