import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Whatsapp = ({ color }) => (
  <Svg width={16} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.96 3.32a6.942 6.942 0 0 0-4.93-2.034c-3.842 0-6.97 3.111-6.972 6.937a6.894 6.894 0 0 0 .931 3.469L1 15.286l3.695-.965a6.988 6.988 0 0 0 3.332.844h.002c3.842 0 6.97-3.112 6.971-6.938a6.88 6.88 0 0 0-2.04-4.907ZM8.03 13.994h-.003a5.805 5.805 0 0 1-2.949-.804l-.211-.125-2.193.572.585-2.127-.138-.22a5.733 5.733 0 0 1-.886-3.067c0-3.179 2.6-5.766 5.797-5.766a5.781 5.781 0 0 1 5.791 5.77c-.001 3.18-2.6 5.767-5.794 5.767Zm3.177-4.319c-.174-.087-1.031-.506-1.19-.564-.159-.058-.276-.087-.392.087-.116.174-.45.563-.551.68-.102.117-.203.13-.377.043-.174-.087-.736-.27-1.401-.86a5.227 5.227 0 0 1-.97-1.201c-.1-.173-.01-.268.077-.354.079-.078.174-.202.261-.304.088-.1.117-.173.174-.289.058-.116.03-.217-.014-.303-.043-.087-.392-.94-.537-1.288-.141-.338-.285-.292-.392-.297a7.074 7.074 0 0 0-.334-.006.642.642 0 0 0-.464.217c-.16.173-.61.593-.61 1.446 0 .852.625 1.677.711 1.793.087.116 1.229 1.867 2.976 2.617.324.14.656.261.993.365.417.132.797.114 1.097.07.334-.05 1.031-.42 1.176-.825.144-.405.145-.752.101-.824-.043-.072-.16-.116-.334-.203Z"
      fill={color}
    />
  </Svg>
);

export default Whatsapp;
