import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Checklist = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      stroke={color}
      strokeLinecap="round"
      d="M7.167 4.833h6M7.167 8.167h6M7.167 11.5h6"
    />
    <Path
      d="m2.667 4.762.857.571L4.667 4M2.667 8.095l.857.572 1.143-1.334M2.667 11.429l.857.571 1.143-1.333"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Checklist;
