import * as React from "react";
import Svg, { Mask, Path } from "react-native-svg";

const PaymentReceipt = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Mask id="a" fill="#fff">
      <Path d="M2 3.91A1.91 1.91 0 0 1 3.91 2h24.18A1.91 1.91 0 0 1 30 3.91V26.25c0 1.56-1.77 2.462-3.032 1.544L23 24.909l-4.754 3.458a3.818 3.818 0 0 1-4.492 0L9 24.909l-3.968 2.886C3.77 28.713 2 27.81 2 26.25V3.909Z" />
    </Mask>
    <Path
      d="m23 24.91 1.123-1.545a1.91 1.91 0 0 0-2.246 0L23 24.91Zm-14 0 1.123-1.545a1.91 1.91 0 0 0-2.246 0L9 24.91Zm4.754 3.457-1.123 1.544 1.123-1.544Zm-8.722-.572L3.909 26.25l1.123 1.544ZM28.091.09H3.909v3.818h24.182V.091Zm0 3.818v22.342h3.818V3.909h-3.818Zm0 22.342-3.968-2.886-2.246 3.088 3.968 2.886 2.246-3.088Zm-8.722 3.66 4.754-3.458-2.246-3.088-4.754 3.458 2.246 3.088ZM7.877 26.453l4.754 3.458 2.246-3.088-4.754-3.458-2.246 3.088Zm-1.722 2.886 3.968-2.886-2.246-3.088-3.968 2.886 2.246 3.088ZM.09 3.909v22.342h3.818V3.909H.091Zm17.032 22.914a1.91 1.91 0 0 1-2.246 0l-2.246 3.088a5.727 5.727 0 0 0 6.738 0l-2.246-3.088Zm10.968-.572-2.246 3.088c2.524 1.835 6.064.033 6.064-3.088h-3.818Zm-24.182 0H.091c0 3.12 3.54 4.923 6.064 3.088L3.909 26.25ZM28.091 3.909h3.818A3.818 3.818 0 0 0 28.091.091v3.818ZM3.909.091A3.818 3.818 0 0 0 .091 3.909h3.818V.091Z"
      fill={color}
      mask="url(#a)"
    />
    <Path
      d="M16.567 18.574c.472-.069.825-.228 1.06-.479.236-.255.354-.593.354-1.016a.993.993 0 0 0-.088-.434 1.102 1.102 0 0 0-.243-.339 1.583 1.583 0 0 0-.368-.258 3.077 3.077 0 0 0-.464-.206l-.25 2.732ZM16 11.064c-.25.034-.463.09-.64.169a1.4 1.4 0 0 0-.435.272 1.09 1.09 0 0 0-.243.36c-.049.128-.073.266-.073.413 0 .167.027.317.08.45.06.127.14.242.244.345.103.104.226.197.368.28.142.079.3.15.471.214L16 11.063Zm.987 2.9c.236.07.472.145.707.229.236.078.462.17.678.272a3.7 3.7 0 0 1 .603.361c.187.137.349.3.486.486.143.182.253.393.332.633.078.24.118.516.118.825 0 .457-.079.889-.236 1.296-.152.403-.378.761-.678 1.075-.299.31-.665.562-1.097.759a4.3 4.3 0 0 1-1.487.353l-.088 1.002a.554.554 0 0 1-.162.346.505.505 0 0 1-.383.154h-.744l.14-1.553a6.102 6.102 0 0 1-.832-.177 6.728 6.728 0 0 1-.78-.295 6.086 6.086 0 0 1-.708-.39 4.175 4.175 0 0 1-.596-.479l.626-.92a.601.601 0 0 1 .22-.191.567.567 0 0 1 .288-.081c.113 0 .23.034.353.102.123.07.258.153.405.25.152.094.322.19.508.288.192.098.413.174.663.228l.28-3.055a12.924 12.924 0 0 1-.707-.214 6.242 6.242 0 0 1-.67-.287 3.783 3.783 0 0 1-.604-.376 2.442 2.442 0 0 1-.803-1.215 3.221 3.221 0 0 1-.117-.913 2.95 2.95 0 0 1 .891-2.084 3.518 3.518 0 0 1 1.082-.7 4.217 4.217 0 0 1 1.473-.323l.08-.877a.525.525 0 0 1 .163-.338.52.52 0 0 1 .383-.155h.75l-.132 1.443c.53.094.985.246 1.363.457.378.21.701.449.972.714l-.501.736a.806.806 0 0 1-.221.25.518.518 0 0 1-.28.075.767.767 0 0 1-.265-.06 14.757 14.757 0 0 1-.331-.154 5.54 5.54 0 0 0-.413-.191 3.298 3.298 0 0 0-.471-.162l-.258 2.856Z"
      fill={color}
    />
  </Svg>
);

export default PaymentReceipt;
