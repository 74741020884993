import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Copy = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15.898 5H7.227C5.997 5 5 5.997 5 7.227v8.671c0 1.23.997 2.227 2.227 2.227h8.671c1.23 0 2.227-.997 2.227-2.227V7.227c0-1.23-.997-2.227-2.227-2.227Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinejoin="round"
    />
    <Path
      d="m14.98 5 .02-.938a2.193 2.193 0 0 0-2.188-2.187H4.376a2.507 2.507 0 0 0-2.5 2.5v8.438A2.194 2.194 0 0 0 4.063 15H5"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Copy;
