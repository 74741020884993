import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      backgroundColor: styleguide.colors.red,
      position: 'absolute',
      right: 0,
      top: 3,
      // right: 4,
      // top: 4,
      height: 16,
      width: 16,
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
