import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Fit = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M2.45 7.897A6.132 6.132 0 0 1 2 5.525C2.017 3.88 3.323 2.5 4.972 2.5 7 2.5 8 4.5 8 4.5s1-2 3.03-2c1.648 0 2.953 1.38 2.97 3.025.034 3.417-2.71 5.847-5.718 7.888a.5.5 0 0 1-.563 0C6.344 12.48 5.025 11.466 4 10.315"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M1.5 7.5a.5.5 0 0 0 0 1v-1ZM4 8v.5a.5.5 0 0 0 .447-.276L4 8Zm1.5-3 .479-.144a.5.5 0 0 0-.926-.08L5.5 5ZM7 10l-.479.144a.5.5 0 0 0 .926.08L7 10Zm1.5-3 .447-.224a.5.5 0 0 0-.894 0L8.5 7Zm1 2-.447.224A.5.5 0 0 0 9.5 9.5V9Zm1.5.5a.5.5 0 0 0 0-1v1Zm-9.5-1H4v-1H1.5v1Zm2.947-.276 1.5-3-.894-.448-1.5 3 .894.448Zm.574-3.08 1.5 5 .958-.288-1.5-5-.958.288Zm2.426 5.08 1.5-3-.894-.448-1.5 3 .894.448Zm.606-3 1 2 .894-.448-1-2-.894.448ZM9.5 9.5H11v-1H9.5v1Z"
      fill={color}
    />
  </Svg>
);

export default Fit;
