import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Table = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11 12.606V20m0-7.394h10m-10 0H6m15 0V20m0-7.394h5m0 0h2l-5.677-6.545H9.935L4 12.606h2m20 0V26M6 12.606V26"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
  </Svg>
);

export default Table;
