import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Copy = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M25.438 8H11.563A3.563 3.563 0 0 0 8 11.563v13.874A3.563 3.563 0 0 0 11.563 29h13.874A3.563 3.563 0 0 0 29 25.437V11.563A3.563 3.563 0 0 0 25.437 8Z"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <Path
      d="M23.969 8 24 6.5A3.51 3.51 0 0 0 20.5 3H7a4.012 4.012 0 0 0-4 4v13.5A3.51 3.51 0 0 0 6.5 24H8"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Copy;
