import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

const Color = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Rect
      x={5}
      y={3}
      width={20}
      height={8}
      rx={3}
      stroke={color}
      strokeWidth={2}
    />
    <Rect
      x={13}
      y={19}
      width={4}
      height={10}
      rx={1}
      stroke={color}
      strokeWidth={2}
    />
    <Path
      d="M15 19c0-1.076.115-2.176 3-3 7-2 10-1 10-5v-1c0-2.843-1-3-3-3"
      stroke={color}
      strokeWidth={2}
    />
  </Svg>
);

export default Color;
