import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Apple = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M21.822 8.554c-2.52 0-3.585 1.202-5.34 1.202-1.8 0-3.172-1.194-5.356-1.194-2.137 0-4.417 1.306-5.864 3.529-2.033 3.135-1.688 9.039 1.604 14.069 1.178 1.8 2.75 3.82 4.813 3.842h.037c1.793 0 2.325-1.174 4.792-1.188h.038c2.43 0 2.917 1.181 4.702 1.181h.038c2.062-.022 3.719-2.26 4.896-4.053.848-1.29 1.163-1.938 1.813-3.397-4.762-1.808-5.527-8.558-.818-11.146-1.437-1.8-3.457-2.843-5.361-2.843l.006-.002Z"
      fill={color}
    />
    <Path
      d="M21.267 2c-1.5.102-3.25 1.057-4.275 2.304-.93 1.13-1.695 2.806-1.395 4.432h.12c1.597 0 3.232-.962 4.187-2.195.92-1.173 1.618-2.835 1.363-4.541Z"
      fill={color}
    />
  </Svg>
);

export default Apple;
