import * as React from "react";
import Svg, { Path } from "react-native-svg";

const BarCode = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d="M2.5 2.5v7M2.5 11v2.5" stroke={color} />
    <Path d="M4.5 2.5v7M4.5 11v2.5" stroke={color} strokeWidth={2} />
    <Path d="M7 2.5v7M7 11v2.5" stroke={color} />
    <Path d="M9.5 2.5v7M9.5 11v2.5" stroke={color} strokeWidth={1.5} />
    <Path d="M11.5 2.5v7M11.5 11v2.5" stroke={color} strokeWidth={0.75} />
    <Path d="M13.25 2.5v7M13.25 11v2.5" stroke={color} strokeWidth={1.5} />
    <Path stroke="#E65C5C" strokeLinecap="round" d="M1.5 11h13" />
  </Svg>
);

export default BarCode;
