import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Filter = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18.334 21.833V16l7-9.334H6.667l7 9.334v9.333"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Filter;
