import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Search = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m28 28-6.075-6.075m0 0c1.9-1.9 3.075-4.526 3.075-7.425C25 8.701 20.299 4 14.5 4S4 8.701 4 14.5 8.701 25 14.5 25c2.9 0 5.524-1.175 7.425-3.075Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default Search;
