import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Cash = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M1.964 12h12.072c.256 0 .464-.224.464-.5v-7c0-.276-.208-.5-.464-.5H1.964c-.256 0-.464.224-.464.5v7c0 .276.208.5.464.5Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <Path
      d="M8 10.5c1.316 0 2.382-1.12 2.382-2.5S9.316 5.5 8 5.5C6.686 5.5 5.62 6.62 5.62 8S6.686 10.5 8 10.5ZM14.5 6.5a2.24 2.24 0 0 1-1.642-.732A2.601 2.601 0 0 1 12.178 4M1.5 6.5c.305 0 .607-.065.888-.19.282-.126.538-.31.754-.542.215-.232.386-.508.503-.811.116-.304.176-.629.176-.957M14.5 9.5a2.24 2.24 0 0 0-1.642.732 2.601 2.601 0 0 0-.68 1.768M1.5 9.5c.305 0 .607.065.888.19.282.126.538.31.754.542.215.232.386.508.503.811.116.304.176.629.176.957"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Cash;
