import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Storage = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m6.328 8.827-.341-.94a1 1 0 0 0-.362.23l.703.71Zm0 14.284h-1a1 1 0 0 0 .659.94l.341-.94Zm9.607 3.494-.342.94a1 1 0 0 0 .671.004l-.33-.944Zm10-3.494.33.944a1 1 0 0 0 .67-.944h-1Zm0-14.284.83-.56a1 1 0 0 0-.5-.384l-.33.944Zm-10-3.494.33-.944a1 1 0 0 0-.672.004l.341.94Zm3.398 12.156-.829.56a1 1 0 0 0 1.17.38l-.341-.94Zm10-3.628.342.94a1 1 0 0 0 .488-1.5l-.83.56Zm-27.37-2.116a1 1 0 1 0 1.408 1.42l-1.408-1.42Zm24.972 3.349a1 1 0 0 0-2 0h2ZM5.987 24.05l9.606 3.494.683-1.88-9.606-3.494-.683 1.88Zm10.277 3.498 10.001-3.494-.66-1.888-10 3.494.66 1.888ZM26.265 7.883l-10-3.494-.66 1.888 10 3.494.66-1.888Zm-10.672-3.49L5.987 7.887l.683 1.88 9.606-3.494-.683-1.88Zm10.001 3.494-10 3.628.682 1.88 10-3.628-.682-1.88Zm-9.306 3.633L6.682 7.892l-.707 1.87 9.606 3.629.707-1.871Zm-1.354.935v14.15h2v-14.15h-2Zm.172.56 3.398 5.033 1.658-1.119-3.399-5.033-1.657 1.119Zm4.568 5.414 10-3.628-.681-1.88-10.001 3.628.682 1.88ZM30.163 13.3l-3.399-5.034-1.657 1.12 3.398 5.033 1.658-1.119ZM5.625 8.117l-3.662 3.628 1.408 1.42 3.661-3.628-1.407-1.42Zm-.297.71v2.471h2V8.827h-2Zm0 2.471v11.813h2V11.298h-2Zm21.607 11.813v-8.017h-2v8.017h2Z"
      fill={color}
    />
  </Svg>
);

export default Storage;
