import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Logout = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M9.5 10.5v1.25A1.25 1.25 0 0 1 8.25 13h-5A1.25 1.25 0 0 1 2 11.75v-7.5A1.25 1.25 0 0 1 3.25 3H8c.69 0 1.5.56 1.5 1.25V5.5m2 5L14 8l-2.5-2.5M5.5 8h8"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Logout;
