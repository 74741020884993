import * as React from "react";
import Svg, { Circle } from "react-native-svg";

const Radio = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx={10} cy={10} r={9.375} stroke={color} strokeWidth={1.25} />
  </Svg>
);

export default Radio;
