import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Time = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12 3c-4.969 0-9 4.031-9 9s4.031 9 9 9 9-4.031 9-9-4.031-9-9-9Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <Path
      d="M12 6.75v5.836l3 1.664"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Time;
