import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
    },

    separator: {
      // width: '100%',
      flex: 1,
      // height: 1,
      // backgroundColor: styleguide.colors.border,
      borderColor: styleguide.colors.border,
    },
  });
