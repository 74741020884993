import * as React from "react";
import Svg, { Path } from "react-native-svg";

const History = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M5 8.889A8.002 8.002 0 0 1 20.372 12 8 8 0 0 1 5 15.111M5 8.89h2.5m-2.5 0L4 6.5"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <Path
      d="M12.75 8a.75.75 0 0 0-1.5 0h1.5ZM12 12.5h-.75v.54l.513.171L12 12.5Zm2.763 1.711a.75.75 0 0 0 .474-1.422l-.474 1.422ZM11.25 8v4.5h1.5V8h-1.5Zm.513 5.211 3 1 .474-1.422-3-1-.474 1.422Z"
      fill={color}
    />
  </Svg>
);

export default History;
