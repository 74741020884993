import * as React from "react";
import Svg, { Path } from "react-native-svg";

const StorageAdd = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m4.746 8.62-.256-.705a.75.75 0 0 0-.272.173l.528.532Zm0 10.713h-.75a.75.75 0 0 0 .494.705l.256-.705Zm7.205 2.62-.257.706c.163.059.34.06.504.003l-.247-.708Zm7.5-2.62.248.708a.75.75 0 0 0 .502-.708h-.75Zm0-10.713.622-.42a.75.75 0 0 0-.374-.288l-.248.708ZM11.951 6l.247-.708a.75.75 0 0 0-.504.003l.257.705Zm2.548 9.117-.621.42a.75.75 0 0 0 .877.285l-.256-.705ZM22 12.396l.256.705a.75.75 0 0 0 .366-1.125l-.622.42ZM1.472 10.809a.75.75 0 0 0 1.056 1.065L1.472 10.81Zm18.73 2.511a.75.75 0 1 0-1.5 0h1.5ZM4.49 20.038l7.204 2.62.513-1.41-7.205-2.62-.512 1.41Zm7.708 2.624 7.5-2.62-.494-1.417-7.5 2.62.494 1.417Zm7.5-14.75-7.5-2.62-.495 1.416 7.5 2.62.496-1.416Zm-8.004-2.617L4.49 7.915l.512 1.41 7.205-2.62-.513-1.41Zm7.501 2.62-7.5 2.721.511 1.41 7.501-2.72-.512-1.41Zm-6.98 2.725L5.012 7.919l-.53 1.403 7.205 2.721.53-1.403Zm-1.014.701v10.613h1.5V11.34h-1.5Zm.128.42 2.549 3.775 1.243-.839-2.549-3.775-1.243.839Zm3.426 4.06 7.5-2.72-.51-1.41-7.501 2.72.511 1.41Zm7.867-3.845-2.55-3.775-1.242.839 2.548 3.775 1.244-.839ZM4.218 8.088l-2.746 2.72 1.056 1.066 2.746-2.72-1.056-1.066Zm-.222.532v1.854h1.5V8.62h-1.5Zm0 1.854v8.86h1.5v-8.86h-1.5Zm16.205 8.86V13.32h-1.5v6.013h1.5Z"
      fill={color}
    />
    <Path
      d="M18 4.119h2m2 0h-2m0 0v2.136m0-2.136V2.25"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default StorageAdd;
