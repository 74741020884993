import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Receipt = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M28 4H4v24h24V4ZM11.5 10h9M11.5 16h9M11.5 22h9"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Receipt;
