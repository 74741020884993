import * as React from "react";
import Svg, { Path } from "react-native-svg";

const EyeClosed = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15.979 7C11.108 7 6.11 9.82 2.177 15.458a1 1 0 0 0-.017 1.11C5.183 21.3 10.113 25 15.98 25c5.802 0 10.834-3.711 13.862-8.453a1.009 1.009 0 0 0 0-1.092C26.806 10.767 21.737 7 15.979 7Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16 21a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <Path
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      d="M5.414 5.531 26 26.117"
    />
  </Svg>
);

export default EyeClosed;
