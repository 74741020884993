import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

const Color = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Rect
      x={3.75}
      y={2.25}
      width={15}
      height={6}
      rx={2.25}
      stroke={color}
      strokeWidth={1.5}
    />
    <Rect
      x={9.75}
      y={14.25}
      width={3}
      height={7.5}
      rx={0.75}
      stroke={color}
      strokeWidth={1.5}
    />
    <Path
      d="M11.25 14.25c0-.807.087-1.632 2.25-2.25 5.25-1.5 7.5-.75 7.5-3.75V7.5c0-2.132-.75-2.25-2.25-2.25"
      stroke={color}
      strokeWidth={1.5}
    />
  </Svg>
);

export default Color;
