import * as React from "react";
import Svg, { Path } from "react-native-svg";

const More = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8 8.167a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0ZM8 3.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0ZM8 12.833a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default More;
