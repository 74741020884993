import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Login = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m11 8-2.333 2.333M11 8 8.667 5.667M11 8H2.667"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5.5 5.333a.5.5 0 1 0 1 0h-1Zm1 5.334a.5.5 0 0 0-1 0h1Zm1.5-7.5h3.333v-1H8v1Zm4.833 1.5v6.666h1V4.667h-1Zm-1.5 8.166H8v1h3.333v-1ZM6.5 5.333v-.666h-1v.666h1Zm0 6v-.666h-1v.666h1Zm1.5 1.5a1.5 1.5 0 0 1-1.5-1.5h-1a2.5 2.5 0 0 0 2.5 2.5v-1Zm4.833-1.5a1.5 1.5 0 0 1-1.5 1.5v1a2.5 2.5 0 0 0 2.5-2.5h-1Zm-1.5-8.166a1.5 1.5 0 0 1 1.5 1.5h1a2.5 2.5 0 0 0-2.5-2.5v1ZM8 2.167a2.5 2.5 0 0 0-2.5 2.5h1a1.5 1.5 0 0 1 1.5-1.5v-1Z"
      fill={color}
    />
  </Svg>
);

export default Login;
