import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Receipt = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14 2H2v12h12V2ZM5.75 5h4.5M5.75 8h4.5M5.75 11h4.5"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Receipt;
