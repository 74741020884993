import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Sort = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M11.5 2v11.5m0 0 2-2m-2 2-2-2M2.5 3.5h6M2.5 8h4m-4 4.5H5"
      stroke={color}
      strokeLinecap="round"
    />
  </Svg>
);

export default Sort;
