import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Search = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m14 14-3.038-3.038m0 0a5.25 5.25 0 1 0-7.425-7.425 5.25 5.25 0 0 0 7.425 7.425Z"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Svg>
);

export default Search;
