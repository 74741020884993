import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Edit = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M8.07 12h4.492M10.219 3.884a1.044 1.044 0 0 1 1.476 1.476l-6.149 6.148L3.58 12l.492-1.967 6.148-6.149Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Edit;
