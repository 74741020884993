import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Table = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M6.875 7.879V12.5m0-4.621h6.25m-6.25 0H3.75m9.375 0V12.5m0-4.621h3.125m0 0h1.25l-3.548-4.091H6.21L2.5 7.878h1.25m12.5 0v8.372M3.75 7.879v8.371"
      stroke={color}
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
  </Svg>
);

export default Table;
