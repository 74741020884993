import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Storage = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m3.955 5.517-.213-.588a.625.625 0 0 0-.226.144l.44.444Zm0 8.927H3.33c0 .263.165.498.412.588l.213-.588Zm6.004 2.184-.213.587c.135.05.283.05.42.003l-.207-.59Zm6.25-2.184.207.59a.625.625 0 0 0 .419-.59h-.625Zm0-8.927.519-.35a.625.625 0 0 0-.312-.24l-.206.59ZM9.96 3.333l.206-.59a.625.625 0 0 0-.42.003l.214.587Zm2.124 7.598-.518.35c.16.237.462.335.731.237l-.213-.588Zm6.25-2.268.214.587a.625.625 0 0 0 .305-.937l-.518.35ZM1.228 7.34a.625.625 0 1 0 .88.888l-.88-.888Zm15.608 2.093a.625.625 0 1 0-1.25 0h1.25ZM3.742 15.032l6.004 2.183.427-1.174-6.004-2.184-.427 1.175Zm6.423 2.186 6.25-2.184-.411-1.18-6.25 2.184.411 1.18Zm6.25-12.291-6.25-2.184-.412 1.18 6.25 2.184.413-1.18Zm-6.67-2.181L3.743 4.929l.427 1.175 6.004-2.183-.427-1.175Zm6.252 2.183-6.25 2.268.425 1.175 6.25-2.268-.425-1.175ZM10.18 7.2 4.176 4.933l-.441 1.17 6.003 2.267.442-1.17Zm-.846.585v8.844h1.25V7.784h-1.25Zm.107.35 2.124 3.146 1.036-.7-2.124-3.145-1.036.7Zm2.855 3.384 6.25-2.268-.425-1.175-6.251 2.268.426 1.175Zm6.556-3.205-2.124-3.146-1.036.7 2.124 3.146 1.036-.7ZM3.516 5.073 1.227 7.34l.88.888 2.288-2.267-.88-.888Zm-.186.444V7.06h1.25V5.517H3.33Zm0 1.544v7.383h1.25V7.061H3.33Zm13.505 7.383v-5.01h-1.25v5.01h1.25Z"
      fill={color}
    />
  </Svg>
);

export default Storage;
