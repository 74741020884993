import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Pin = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M12.666 6.518c0 2.865-5 8.149-5 8.149s-5-5.284-5-8.149c0-2.864 2.239-5.185 5-5.185 2.762 0 5 2.322 5 5.185Z"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.833 6.333a1.167 1.167 0 1 1-2.333 0 1.167 1.167 0 0 1 2.333 0Z"
      stroke={color}
    />
  </Svg>
);

export default Pin;
