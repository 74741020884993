import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Google = () => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.999 13.054h13.685c.204.952.317 1.936.317 2.948 0 4.086-1.76 7.751-4.552 10.31l-3.955-3.39a8.877 8.877 0 0 0 2.76-3.788c.113-.3.217-.606.298-.921H16v-5.159Z"
      fill="#4285F4"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.478 22.236 4.288-3.027c1.286 3.294 4.483 5.632 8.233 5.632 2.08 0 3.988-.72 5.495-1.922l3.955 3.39c-2.492 2.285-5.803 3.69-9.45 3.69-5.488 0-10.226-3.165-12.521-7.763Z"
      fill="#34A853"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.65 9.402C6.01 4.998 10.653 2 15.999 2c3.679 0 7.016 1.43 9.515 3.75l-3.62 3.62c-1.561-1.39-3.64-2.212-5.895-2.212a8.844 8.844 0 0 0-8.16 5.434l-4.187-3.19Z"
      fill="#EA4335"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 15.998c0-2.388.6-4.633 1.654-6.6l4.188 3.19a8.812 8.812 0 0 0-.062 6.651l-4.274 3.052A13.924 13.924 0 0 1 2 15.998Z"
      fill="#FBBC05"
    />
  </Svg>
);

export default Google;
