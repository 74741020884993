import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { createStyles } from './styles';
import { responsiveMobile, responsiveTablet } from '../../constants/responsive';
import { useStyleguide } from '../../hooks/styleguide';
import Home from '../../screens/pages/Home';
// import OrderAdd from '../../../screens/sidePages/OrderAdd/v1';
// import AddressForm from '../../../screens/modals/AddressForm/v1';
// import CreditCardForm from '../../../screens/modals/CreditCardForm/v1';
// import InstallmentsSelect from '../../../screens/modals/InstallmentsSelect/v1';

const Pages = ({ _ }, ref) => {
  const { styleguide } = useStyleguide();

  const animationDurationPage = 150;
  const animationDurationSidePage = 500;
  const animationDurationModal = 150;

  const translateYPage = useSharedValue(0);
  const translateXSidePage = useSharedValue(responsiveMobile);
  const translateYModal = useSharedValue(-styleguide.frame.height * 0.8);

  const animatedPage = useAnimatedStyle(() => {
    return {
      maxWidth: styleguide.responsive === 'mobile' ? responsiveMobile * 1.5 : responsiveTablet,
      width: '100%',
      height: '100%',
      transform: [{ translateY: translateYPage.value }],
    };
  }, [styleguide.responsive]);

  const animatedSidePages = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      height: '100%',
      width: styleguide.windowDimensions.width,
      maxWidth: responsiveMobile,
      alignSelf: 'flex-end',
      transform: [{ translateX: translateXSidePage.value }],
      backgroundColor: styleguide.colors.surface,
      borderLeftWidth: 1,
      borderColor: styleguide.colors.border,
      paddingTop: styleguide.insets.top,
    };
  }, [
    styleguide.windowDimensions.width,
    styleguide.responsive,
    styleguide.colors.surface,
    styleguide.colors.border,
    styleguide.insets.top,
  ]);

  const animatedModal = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      height: '80%',
      width: '100%',
      // transform: [{ translateY: translateYModal.value }],
      bottom: translateYModal.value,
      backgroundColor: styleguide.colors.surface,
      borderTopWidth: 1,
      borderColor: styleguide.colors.border,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      overflow: 'hidden',
    };
  }, [styleguide.colors]);

  const [variantPage, setVariantPage] = useState(
    <View
      style={{
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <ActivityIndicator size="large" color={styleguide.colors.typographyPrimary} />
    </View>,
  );
  const [variantSidePage, setVariantSidePage] = useState(null);
  const [variantModal, setVariantModal] = useState(null);

  // const [variantPage, setVariantPage] = useState(
  //   <Splash/>
  // );

  const backgroundColorModal = useMemo(
    () => (variantModal !== null ? '#000000cc' : 'transparent'),
    [variantModal],
  );

  const pointerEvents = useMemo(() => (variantModal !== null ? 'auto' : 'none'), [variantModal]);

  const animateToCenter = useCallback((screen, props) => {
    const Variant = {
      Home,
    }[screen];

    setVariantPage(<Variant {...props} />);

    setTimeout(() => {
      translateYPage.value = withTiming(
        0,
        {
          duration: animationDurationPage,
        },
        (isFinished) => {
          if (isFinished) {
          }
        },
      );
    }, 10);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      showPage({ screen, props }) {
        translateYPage.value = withTiming(
          styleguide.platform.OS === 'android'
            ? styleguide.frame.height
            : styleguide.windowDimensions.height,
          {
            duration: animationDurationPage,
          },
          (isFinished) => {
            if (isFinished) {
              translateYPage.value =
                styleguide.platform.OS === 'android'
                  ? -styleguide.frame.height
                  : -styleguide.windowDimensions.height;

              runOnJS(animateToCenter)(screen, props);
            }
          },
        );
      },
      showSidePage({ screen, props }) {
        if (screen !== '') {
          const Variant = {
            // OrderAdd,
          }[screen];

          setVariantSidePage(<Variant {...props} />);

          setTimeout(() => {
            translateXSidePage.value = withTiming(0, {
              duration: animationDurationSidePage,
            });
          }, 10);
        } else {
          translateXSidePage.value = withTiming(
            responsiveMobile,
            {
              duration: animationDurationSidePage,
            },
            (isFinished) => {
              if (isFinished) {
                runOnJS(setVariantSidePage)(null);
              }
            },
          );
        }
      },
      showModal({ screen, props }) {
        if (screen !== '') {
          const Variant = {
            // AddressForm,
            // CreditCardForm,
            // InstallmentsSelect,
          }[screen];

          setVariantModal(<Variant {...props} />);


          setTimeout(() => {
            translateYModal.value = withTiming(0, { duration: animationDurationModal });
          }, 10);
        } else {
          translateYModal.value = withTiming(
            -styleguide.frame.height * 0.8,
            { duration: animationDurationModal },
            (isFinished) => {
              if (isFinished) {
                runOnJS(setVariantModal)(null);
              }
            },
          );
        }
      },
    }),
    [
      styleguide.frame.height,
      styleguide.windowDimensions.height,
      styleguide.platform.OS,
      styleguide.responsive,
      variantPage,
    ],
  );

  const styles = useMemo(() => createStyles(styleguide), [styleguide]);

  return (
    <View style={styles.container}>
      <Animated.View style={animatedPage}>{variantPage}</Animated.View>
      {/* <Animated.View style={animatedSidePages}>
        <View
          // onLayout={(e) => {
          //   const width = e.nativeEvent.layout.width;

          //   setStyleguide((prevState) => ({
          //     ...prevState,
          //     sidePage: {
          //       ...prevState.sidePage,
          //       width,
          //     },
          //   }));
          // }}
          style={{ flex: 1 }}>
          {variantSidePage}
        </View>

        <View
          style={[
            styles.modal,
            {
              backgroundColor: backgroundColorModal,
              pointerEvents,
            },
          ]}>
          <Animated.View style={animatedModal}>{variantModal}</Animated.View>
        </View>
      </Animated.View> */}
    </View>
  );
};

export default forwardRef(Pages);
