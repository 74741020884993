import * as React from 'react';

const Facebook = ({ color }) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 12.063c0-5.798-4.702-10.5-10.5-10.5S1.5 6.265 1.5 12.063c0 5.241 3.84 9.585 8.86 10.373V15.1H7.692v-3.037h2.666V9.75c0-2.63 1.568-4.085 3.966-4.085 1.15 0 2.351.205 2.351.205v2.584h-1.324c-1.304 0-1.712.81-1.712 1.64v1.97h2.912l-.465 3.036h-2.446v7.337c5.02-.788 8.859-5.132 8.859-10.374Z"
      fill={color}
    />
  </svg>
);

export default Facebook;
