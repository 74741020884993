import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Moon = ({ color }) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M6.25 5.313c0-1.197.176-2.407.625-3.438-2.986 1.3-5 4.348-5 7.813a8.438 8.438 0 0 0 8.438 8.437c3.464 0 6.512-2.015 7.812-5-1.03.449-2.241.625-3.438.625A8.438 8.438 0 0 1 6.25 5.312Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Moon;
