import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      padding: 8,
      borderRadius: 24,
      backgroundColor: styleguide.brand,
      position: 'absolute',
      right: { mobile: 16, desktop: 24 }[styleguide.responsive],
      bottom: 16,
    },
  });
