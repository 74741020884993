import * as React from "react";
import Svg, { Path } from "react-native-svg";

const LockClosed = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10.5 6h1A1.5 1.5 0 0 1 13 7.5V13a1.5 1.5 0 0 1-1.5 1.5h-7A1.5 1.5 0 0 1 3 13V7.5A1.5 1.5 0 0 1 4.5 6h1m5 0V4c0-1.5-1-2.5-2.5-2.5s-2.5 1-2.5 2V6m5 0h-5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LockClosed;
