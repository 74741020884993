import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Telephone = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M27.25 22.806c-.917-.923-3.136-2.27-4.212-2.813-1.402-.706-1.517-.764-2.62.055-.734.546-1.223 1.034-2.083.851-.86-.184-2.73-1.218-4.366-2.85-1.636-1.63-2.73-3.554-2.915-4.411-.184-.858.312-1.34.853-2.077.763-1.039.705-1.212.053-2.614-.508-1.09-1.894-3.288-2.82-4.2-.992-.978-.992-.805-1.63-.54a9.239 9.239 0 0 0-1.49.794c-.924.614-1.436 1.123-1.794 1.889-.358.765-.52 2.56 1.33 5.92 1.85 3.362 3.149 5.08 5.836 7.76 2.686 2.679 4.752 4.12 7.772 5.813 3.736 2.092 5.17 1.684 5.937 1.327.768-.358 1.28-.866 1.894-1.788.31-.471.577-.97.796-1.489.266-.636.439-.636-.542-1.627Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default Telephone;
