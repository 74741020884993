import * as React from "react";
import Svg, { Rect } from "react-native-svg";

const Organize = ({ color }) => (
  <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Rect x={6.333} y={4} width={1} height={1} rx={0.5} fill={color} />
    <Rect x={6.333} y={7.112} width={1} height={1} rx={0.5} fill={color} />
    <Rect x={6.333} y={10.221} width={1} height={1} rx={0.5} fill={color} />
    <Rect x={9.442} y={4} width={1} height={1} rx={0.5} fill={color} />
    <Rect x={9.442} y={7.112} width={1} height={1} rx={0.5} fill={color} />
    <Rect x={9.442} y={10.221} width={1} height={1} rx={0.5} fill={color} />
  </Svg>
);

export default Organize;
