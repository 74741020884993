import { StyleSheet } from 'react-native';

export const createStyles = (styleguide) =>
  StyleSheet.create({
    container: {
      backgroundColor: styleguide.colors.surface,
      borderRadius: { mobile: 0, desktop: 8 }[styleguide.responsive],
      padding: 12,
    },

    responsive: {
      flexDirection: { mobile: 'column', desktop: 'row' }[styleguide.responsive],
      justifyContent: { mobile: 'flex-start', desktop: 'space-between' }[styleguide.responsive],
    },

    content: {},

    row: {
      flexDirection: 'row',
      marginTop: 12,
    },
  });
