import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Cost = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16.531 24.006c.615-.057 1.156-.19 1.622-.398a3.867 3.867 0 0 0 1.174-.824c.326-.332.568-.715.726-1.15a4.12 4.12 0 0 0 .252-1.464c0-.474-.089-.881-.266-1.222-.167-.341-.4-.635-.699-.88a4.007 4.007 0 0 0-1.048-.64c-.4-.18-.829-.346-1.286-.498l-.237 3.538-.119 1.769-.119 1.769ZM16.07 7.582c-.606.057-1.132.18-1.58.37-.437.19-.8.426-1.09.71a2.732 2.732 0 0 0-.642.966c-.14.35-.21.72-.21 1.108 0 .455.08.853.238 1.194.158.331.377.625.657.88.279.256.605.479.978.668.373.19.778.36 1.216.512l.108-1.602.108-1.602.109-1.602.108-1.602Zm1.104 6.905c.671.218 1.333.45 1.985.696.652.246 1.239.564 1.76.952.523.388.942.876 1.259 1.463.326.578.489 1.303.489 2.174 0 .852-.14 1.653-.42 2.4a5.757 5.757 0 0 1-1.23 1.976c-.53.568-1.187 1.032-1.97 1.392-.773.35-1.659.559-2.655.625l-.168 2.486a.7.7 0 0 1-.21.47.636.636 0 0 1-.489.212h-.923l.21-3.196c-1.127-.114-2.157-.398-3.089-.853a8.52 8.52 0 0 1-2.39-1.761l.755-1.165a.911.911 0 0 1 .28-.256.7.7 0 0 1 .363-.1c.177 0 .377.09.601.27.224.18.499.384.825.611.335.228.726.45 1.174.668.456.218 1.001.365 1.635.44l.252-3.771.251-3.772a27.288 27.288 0 0 1-1.929-.668 6.406 6.406 0 0 1-1.69-.98 4.693 4.693 0 0 1-1.203-1.52c-.298-.607-.447-1.37-.447-2.288a5.283 5.283 0 0 1 1.565-3.78c.513-.51 1.142-.927 1.887-1.25.746-.33 1.598-.516 2.558-.553l.14-2.046a.79.79 0 0 1 .195-.483.652.652 0 0 1 .503-.213h.923l-.182 2.798c.97.124 1.808.38 2.516.768a7.963 7.963 0 0 1 1.9 1.42l-.6.938c-.187.284-.401.426-.643.426-.13 0-.294-.057-.49-.17a17.78 17.78 0 0 0-.684-.427 6.709 6.709 0 0 0-.937-.468 5.29 5.29 0 0 0-1.216-.341l-.23 3.438-.23 3.438Z"
      fill={color}
    />
  </Svg>
);

export default Cost;
