import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Login = ({ color }) => (
  <Svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m22 16-4.667 4.667M22 16l-4.667-4.667M22 16H5.333"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11 10.667a1 1 0 1 0 2 0h-2Zm2 10.666a1 1 0 1 0-2 0h2Zm3-15h6.667v-2H16v2Zm9.667 3v13.334h2V9.333h-2Zm-3 16.334H16v2h6.667v-2Zm-9.667-15V9.333h-2v1.334h2Zm0 12v-1.334h-2v1.334h2Zm3 3a3 3 0 0 1-3-3h-2a5 5 0 0 0 5 5v-2Zm9.667-3a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5h-2Zm-3-16.334a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2Zm-6.667-2a5 5 0 0 0-5 5h2a3 3 0 0 1 3-3v-2Z"
      fill={color}
    />
  </Svg>
);

export default Login;
