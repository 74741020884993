import * as React from "react";
import Svg, { Path } from "react-native-svg";

const BookOpened = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="m11.762 6.5 9.86-2.961a.1.1 0 0 1 .128.095V17.93a.1.1 0 0 1-.067.095l-9.92 3.476m0-15L2.384 3.05a.1.1 0 0 0-.135.093v14.29a.1.1 0 0 0 .061.093l9.451 3.974m0-15v15"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.25 16.794 19 15.132M14.25 13.5 19 11.838m-4.75-1.632L19 8.544"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

export default BookOpened;
