import * as React from "react";
import Svg, { Path } from "react-native-svg";

const LockOpen = ({ color }) => (
  <Svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M15.75 4.5c0-.75-1.5-2.25-3.75-2.25s-3.75 1.5-3.75 3V9m0 0h-1.5a2.25 2.25 0 0 0-2.25 2.25v8.25a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-8.25A2.25 2.25 0 0 0 17.25 9h-9Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LockOpen;
